import React, { useState, useCallback } from 'react';
import { Row, Col, Radio, Space, Checkbox, Input, Switch, Select, Button, message } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import { debounce } from "lodash";
import Score from './Score'

const Option = Select
const Counting = () => {
    const score_list = [
        "score_of_landlords",
        "score_of_individual_houses",
        "score_of_tax_households",
        "poverty_score",
        "density_per_km2",
        "score_median_income",
        "csp",
        "score_activity_global",
        "score_activity_tag_bricolage_diy",
        "score_activity_tag_finance",
        "score_activity_tag_food",
        "score_activity_tag_gaming",
        "score_activity_tag_garden",
        "score_activity_tag_health",
        "score_activity_tag_high_tech",
        "score_activity_tag_home_assistance",
        "score_activity_tag_ink",
        "score_activity_tag_insurance_funeral",
        "score_activity_tag_cars",
        "score_activity_tag_insurance_health",
        "score_activity_tag_insurance_home",
        "score_activity_tag_job",
        "score_activity_tag_kids",
        "score_activity_tag_lingerie",
        "score_activity_tag_loan",
        "score_activity_tag_mode",
        "score_activity_tag_obseques_funeral",
        "score_activity_tag_optic",
        "score_activity_tag_pets",
        "score_activity_tag_cosmetics",
        "score_activity_tag_real_estate_immo",
        "score_activity_tag_sex_shop",
        "score_activity_tag_sport",
        "score_activity_tag_sweeptakes",
        "score_activity_tag_swimming_pool",
        "score_activity_tag_telecom",
        "score_activity_tag_training",
        "score_activity_tag_travel",
        "score_activity_tag_trading",
        "score_activity_tag_dating",
        "score_activity_tag_voyance_clairvoyance",
        "score_activity_tag_alcohol_and_wine",
        "score_activity_tag_beauty",
        "score_activity_tag_forex",
        "score_activity_tag_coffee",
        "score_activity_tag_water",
        "score_activity_tag_dentist",
        "score_activity_tag_decoration_furniture_and_design",
        "score_activity_tag_renovation",
        "score_activity_tag_studies",
        "score_activity_tag_ong_and_charity",
        "score_activity_tag_alarms",
        "score_activity_tag_books",
        "score_activity_tag_cooking",
        "score_activity_tag_coupon_discount",
        "score_activity_tag_gambling",
        "score_activity_tag_jewellery",
        "score_activity_tag_defisc_tax",
        "score_activity_tag_survey",
        "score_activity_tag_leisure",
        "score_activity_tag_supermarket",
        "score_activity_tag_credit",
        "score_activity_tag_credit_redemption_rac",
        "score_activity_tag_diet",
        "score_activity_tag_ecommerce",
        "score_activity_tag_energy"

    ];
    const fields = [
        "id", "database_id", "database_name", "ownership", "owner", "vendor", "country", "router", "email", "email_md5", "email_sha256", "isp_domain_family",
        "isp_domain", "optin_email", "optin_partner_email", "status_email", "deliverability_status_email", "reoptin_date_email", "reoptin_status_email",
        "optin_sms", "optin_sms_dc", "optin_tmkg_fixe", "optin_tmkg_mobile", "optin_postal", "mobile_verified", "mobile_verified_at", "lastname",
        "firstname", "civility", "gender", "birthdate", "age_class", "collect_date", "collect_ip", "collect_url", "subscription_date", "date_of_freshness", "telephone_fixe",
        "telephone_fixe_md5", "telephone_fixe_sha256", "mobile", "mobile_md5", "mobile_sha256", "address_1", "address_1_1", "address_1_2", "address_1_3", "address_1_4", "address_2", "address_3", "address_4", "zipcode",
        "city", "router_ip", "address_postal",
        "router_country", "router_city", "router_zipcode", "urban_commune", "department",
        "score_of_landlords",
        "score_of_individual_houses", "score_of_tax_households",
        "poverty_score", "density_per_km2",
        "residents", "score_median_income",
        "share_of_landlords_in_main_residences_2016", "share_of_tenants_in_main_residences_2016",
        "share_of_tenants_in_hlm_2016", "share_of_main_residences_built_before_1946_2016",
        "main_residences_hlm_rented_without_furnitures_2016", "share_of_houses_in_total_housing_2016",
        "share_of_appartment_in_total_housing_2016", "share_of_secondary_residences_in_total_housing_2016",
        "main_residences_2016", "houses_2016", "geographic_code", "median_standard_of_living_euro", "share_of_tax_households", "poverty_rate",
        "csp", "date_last_sent", "date_last_open", "date_last_click", "date_last_sms_click", "score_activity_global",
        "score_activity_tag_bricolage_diy", "score_activity_tag_finance", "score_activity_tag_food",
        "score_activity_tag_gaming", "score_activity_tag_garden", "score_activity_tag_health", "score_activity_tag_high_tech",
        "score_activity_tag_home_assistance", "score_activity_tag_ink", "score_activity_tag_insurance_funeral",
        "score_activity_tag_cars", "score_activity_tag_insurance_health", "score_activity_tag_insurance_home", "score_activity_tag_job", "score_activity_tag_kids", "score_activity_tag_lingerie", "score_activity_tag_loan", "score_activity_tag_mode",
        "score_activity_tag_obseques_funeral", "score_activity_tag_optic", "score_activity_tag_pets", "score_activity_tag_cosmetics", "score_activity_tag_real_estate_immo",
        "score_activity_tag_sex_shop", "score_activity_tag_sport", "score_activity_tag_sweeptakes", "score_activity_tag_swimming_pool", "score_activity_tag_telecom", "score_activity_tag_training",
        "score_activity_tag_travel", "score_activity_tag_trading", "score_activity_tag_dating", "score_activity_tag_voyance_clairvoyance",
        "score_activity_tag_alcohol_and_wine", "score_activity_tag_beauty", "score_activity_tag_forex", "score_activity_tag_coffee", "score_activity_tag_water", "score_activity_tag_dentist",
        "score_activity_tag_decoration_furniture_and_design", "score_activity_tag_renovation", "score_activity_tag_studies",
        "score_activity_tag_ong_and_charity", "score_activity_tag_alarms", "score_activity_tag_books", "score_activity_tag_cooking",
        "score_activity_tag_coupon_discount", "score_activity_tag_gambling", "score_activity_tag_jewellery", "score_activity_tag_defisc_tax",
        "score_activity_tag_survey", "score_activity_tag_leisure", "score_activity_tag_supermarket", "score_activity_tag_credit", "score_activity_tag_credit_redemption_rac", "score_activity_tag_diet", "score_activity_tag_ecommerce",
        "score_activity_tag_energy", "recency", "recency_2", "last_update", "data_innovation_segment", "data_innovation_status", "row_num", "prt_database"
    ]
    const [scores, setScores] = useState([])
    const [counting_info, setcounting_info] = useState({
        "country": "FR",
        "channels": "email",
        "advertiser": null,
        "client": null,
        "affiliate": null,
        "civility": "all",
        "birthdate_min": "18",
        "birthdate_max": null,
        "geolocation_status": false,
        "geolocation_liste": null,
        "filename": null,
        "separator": "|",
        "mobile_format": "33",
        "fields": [
            "id", "database_name", "database_id", "civility", "lastname", "firstname", "address_postal", "zipcode", "city", "birthdate",
            "email", "optin_email", "mobile", "mobile_md5", "mobile_sha256", "optin_sms", "mobile_verified", "recency_2", "subscription_date",
            "vendor", "csp", "score_of_landlords", "score_of_individual_houses", "poverty_score", "density_per_km2"
        ],
        "mandatory": []

    })
    const [loadingSubmit, setloadingSubmit] = useState(false)
    const debouncedOnChange = useCallback(
        debounce((nextValue) => {
            console.log("cebouncing  :", nextValue);
        }, 500),
        []
    );
    const handleScoreChange = (scores) => {
        setScores(scores);
    }
    const handleCountingInfoChange = (key, value, shouldDebounce = false) => {
        setcounting_info((prev) => ({ ...prev, [key]: value }));
        if (shouldDebounce) {
            debouncedOnChange(value);
        }
    };
    const handleCountingSubmit = () => {

    }

    return (
        <div>
            <Row>
                <Col span={16} style={{ border: '1px solid #003366', overflow: 'scroll', overflowX: 'hidden', overflowY: 'auto', height: '99.8vh' }}>
                    <div style={{ textAlign: "left", backgroundColor: "#003366", color: "lightgrey", fontFamily: 'Poppins' }}>
                        <span style={{ fontSize: 18 }}>Country</span>
                    </div>
                    <Row>
                        <Col span={24} style={{ padding: 0, textAlign: "left" }}>
                            <Radio.Group
                                block
                                buttonStyle="solid"
                                optionType="button"
                                style={{ padding: 8 }}
                                value={counting_info["country"]}
                                onChange={(e) => handleCountingInfoChange("country", e.target.value,)}
                            >
                                <Space>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="AU">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Australia.svg"
                                            alt="Australia flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />AU
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="BE">BE</Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="BEFR">BEFR</Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="DE">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg"
                                            alt="DE flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />DE
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="ES">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg"
                                            alt="ES flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />ES
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="FR">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
                                            alt="FR flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />FR
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="IT">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg"
                                            alt="IT flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />IT
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="NZ">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
                                            alt="NZ flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />NZ
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="NL">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/255px-Flag_of_the_Netherlands.svg.png"
                                            alt="NL flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />NL
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="UK">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                                            alt="UK flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />UK
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="PL">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg"
                                            alt="PL flag"
                                            style={{ width: 20, height: 15, marginRight: 8 }}
                                        />PL
                                    </Radio.Button>
                                    <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="SE"><img
                                        src="https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg"
                                        alt="SE flag"
                                        style={{ width: 20, height: 15, marginRight: 8 }}
                                    />SE
                                    </Radio.Button>
                                </Space>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "left", backgroundColor: "#003366", color: "lightgrey", fontFamily: 'Poppins', }}>
                        <span style={{ fontSize: 18 }}>Channels</span>
                    </div>
                    <Row>
                        <Col span={24} style={{ padding: 0, textAlign: "left" }}>
                            <div style={{}}>
                                <Radio.Group
                                    block
                                    buttonStyle="solid"
                                    optionType="button"
                                    style={{ padding: 8, width: '100%' }}
                                    value={counting_info['channels']}
                                    onChange={(e) => handleCountingInfoChange("channels", e.target.value)}
                                >
                                    <Space>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="email">Email</Radio.Button>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="sms">SMS</Radio.Button>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="email_or_sms">Email_or_SMS</Radio.Button>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="email_and_sms">Email_and_SMS</Radio.Button>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="tmkg_mobile">Tkmg</Radio.Button>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="tmkg_mobile_and_email">Email_and_Tkmg</Radio.Button>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="tmkg_mobile_or_email">Email_or_Tkmg</Radio.Button>
                                        <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="tmkg_mobile_or_sms">SMS_or_Tkmg</Radio.Button>
                                    </Space>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "left", backgroundColor: "#003366", color: "lightgrey", fontFamily: 'Poppins', }}>
                        <span style={{ fontSize: 18 }}>Identification</span>
                    </div>
                    <Row>
                        <Col span={24} style={{ padding: 2, textAlign: "left" }}>
                            <Row>
                                <Col span={3} style={{ padding: 5 }}>
                                    <span>Advertiser</span>
                                </Col>
                                <Col span={21}>
                                    <Input
                                        value={counting_info["advertiser"]}
                                        onChange={(e) => handleCountingInfoChange("advertiser", e.target.value, true)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ padding: 2, textAlign: "left" }}>
                            <Row>
                                <Col span={3} style={{ padding: 5 }}>
                                    <span>Clients</span>
                                </Col>
                                <Col span={21}>
                                    <Input
                                        value={counting_info["client"]}
                                        onChange={(e) => handleCountingInfoChange("client", e.target.value, true)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ padding: 2, textAlign: "left" }}>
                            <Row>
                                <Col span={3} style={{ padding: 5 }}>
                                    <span>Affiliate</span>
                                </Col>
                                <Col span={21}>
                                    <Input
                                        value={counting_info["affiliate"]}
                                        onChange={(e) => handleCountingInfoChange("affiliate", e.target.value, true)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <div style={{ textAlign: "left", backgroundColor: "#003366", color: "lightgrey", fontFamily: 'Poppins', }}>
                        <span style={{ fontSize: 18 }}>Segmentation</span>
                    </div>
                    <Row>
                        <Col span={24} style={{ padding: 2, textAlign: "center" }}>
                            <Row>
                                <Col span={8}>
                                    <Row>
                                        <Col span={9} style={{ textAlign: "left", padding: 5 }}>
                                            <span>Civility:</span>
                                        </Col>
                                        <Col span={10} style={{ textAlign: "left", padding: 5 }}>
                                            <Checkbox.Group
                                                value={counting_info['civility']}
                                                onChange={(e) => handleCountingInfoChange("civility", e.target.value,)}
                                            >
                                                <Space>
                                                    <Checkbox value="all">All</Checkbox>
                                                    <Checkbox value="M">Male</Checkbox>
                                                    <Checkbox value="F">Female</Checkbox>
                                                </Space>
                                            </Checkbox.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={4} style={{ textAlign: "right", padding: 5 }}>
                                            <span>Age:</span>
                                        </Col>
                                        <Col span={4}>
                                            <Input
                                                placeholder="Age min"
                                                style={{ textAlign: 'center' }}
                                                value={counting_info["birthdate_min"]}
                                                onChange={(e) => handleCountingInfoChange("birthdate_min", e.target.value,)}
                                            />
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={4}>
                                            <Input
                                                placeholder="Age max"
                                                style={{ textAlign: 'center' }}
                                                value={counting_info["birthdate_max"]}
                                                onChange={(e) => handleCountingInfoChange("birthdate_max", e.target.value,)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                            {/* <br />
                            <Row>
                                <Col span={3} style={{ textAlign: "left", padding: 5 }}>
                                    <span>Geolocation:</span>
                                </Col>
                                <Col span={20} style={{ padding: 5 }}>
                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={3}> <label>Include</label></Col>
                                                <Col span={2}>
                                                    <Switch
                                                        checked={counting_info["geolocation_status"]}
                                                        onChange={(checked) =>
                                                            handleCountingInfoChange("geolocation_status", checked)
                                                        }
                                                    />
                                                </Col>
                                                <Col span={4}> <label>Exclude</label></Col>
                                            </Row>
                                        </Col>

                                        <Col span={24}>
                                            <br />
                                            <TextArea
                                                resize={false}
                                                draggable={false}
                                                placeholder='Zipcode or department liste separated by commas'
                                                value={counting_info["geolocation_liste"]}
                                                onChange={(e) => handleCountingInfoChange("geolocation_liste", e.target.value, true)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
                            <br />
                            <Row>
                                <Col span={3} style={{ textAlign: "left", padding: 5 }}>
                                    <span>Scores:</span>
                                </Col>
                                <Col span={21}>
                                    <Score scoreList={score_list} onChange={handleScoreChange} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <div style={{ textAlign: "left", backgroundColor: "#003366", color: "lightgrey", fontFamily: 'Poppins', }}>
                        <span style={{ fontSize: 18 }}>Blacklist</span>
                    </div> */}
                    <div style={{ textAlign: "left", backgroundColor: "#003366", color: "lightgrey", fontFamily: 'Poppins', }}>
                        <span style={{ fontSize: 18 }}>Output</span>
                    </div>
                    <Row>
                        <Col span={24} style={{ padding: 2, textAlign: "center" }}>
                            <Row>
                                <Col span={3} style={{ textAlign: "left", padding: 8 }}>
                                    <span>Filename:</span>
                                </Col>
                                <Col span={5} style={{ textAlign: "left", padding: 5 }}>
                                    <Input
                                        placeholder='Filename prefix'
                                        value={counting_info["filename"]}
                                        onChange={(e) => handleCountingInfoChange("filename", e.target.value, true)}
                                    />
                                </Col>
                                <Col span={3} style={{ textAlign: "right", padding: 8 }}>
                                    <span>Separator:</span>
                                </Col>
                                <Col span={5} style={{ textAlign: "left", padding: 5 }}>
                                    <Select
                                        style={{ width: "100%", textAlign: "center" }}
                                        value={counting_info["separator"]}
                                        onChange={(value) => handleCountingInfoChange("separator", value, true)}
                                    >
                                        <Option value=",">virgule</Option>
                                        <Option value=";">point-virgule</Option>
                                        <Option value="|">pipe</Option>
                                    </Select>
                                </Col>
                                <Col span={3} style={{ textAlign: "right", padding: 8 }}>
                                    <span>Mobile Format</span>
                                </Col>
                                <Col span={5} style={{ textAlign: "left", padding: 5 }}>
                                    <Select
                                        style={{ width: "100%", textAlign: "center" }}
                                        value={counting_info["mobile_format"]}
                                        onChange={(value) => handleCountingInfoChange("mobile_format", value, true)}
                                    >
                                        <Option value="33">33XXXXXXXXX</Option>
                                        <Option value="0">06/07XXXXXXXX</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ padding: 2, textAlign: "center" }}>
                            <Row>
                                <Col span={3} style={{ textAlign: "left", padding: 8 }}>
                                    <span>Fields:</span>
                                </Col>
                                <Col span={21} style={{ textAlign: "left", padding: 5 }}>
                                    <Select
                                        style={{ width: "100%" }}
                                        mode='multiple'
                                        value={counting_info["fields"]}
                                        onChange={(value) => handleCountingInfoChange("fields", value,)}
                                    >
                                        {
                                            fields.map(item => {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ padding: 2, textAlign: "center" }}>
                            <Row>
                                <Col span={3} style={{ textAlign: "left", padding: 8 }}>
                                    <span>Mandatory:</span>
                                </Col>
                                <Col span={21} style={{ textAlign: "left", padding: 5 }}>
                                    <Select
                                        style={{ width: "100%" }}
                                        mode='multiple'
                                        value={counting_info["mandatory"]}
                                        onChange={(value) => handleCountingInfoChange("mandatory", value,)}
                                    >
                                        {
                                            counting_info["fields"].map(item => {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ padding: 4, textAlign: "center" }}>
                            <Button
                                className='btn btn-success'
                                type="solid"
                                onClick={handleCountingSubmit}
                                style={{
                                    width: "50%",
                                    height: '40px',
                                    fontSize: 20
                                }}
                                loading={loadingSubmit}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Counting;
