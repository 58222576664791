import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'antd/dist/antd.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './assets/js/Home/Home';
import NewCreativities from './assets/js/Creativities/NewCreativities';
import DetailCreativities from './assets/js/Creativities/DetailCreativities';
import Utility from './assets/js/Utility/Utitlity';
import Dashboard from './assets/js/DashBoard/Dashboard';
import Minisite from './assets/js/Minisite/Minisite';
import Templates from './assets/js/Templates/Templates';
import Overview from './assets/js/Overview/Overview';
import Cache from './assets/js/Utilities/Cache';
import Container from './assets/js/Creativities/Container';
import ErrorBoundary from './assets/js/ErrorBoundary/ErrorBoundary';
// import NewCreativitiesv2 from './assets/js/Creativities/NewCreativitiesv2';
import Details from './assets/js/Creativities/Details';
import Counting from './assets/js/Counting/Counting';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
  <ErrorBoundary>
      <Switch>
        <Route  exact path="/" component={App} />
        <Route  path="/app" component={Home} />
        <Route  path="/app/creativities/cache/clear" component={Cache} />
        <Route  path="/app/creativities/delivery" component={Overview} />
        <Route  path="/app/creativities/task" component={Container} />
        <Route  path="/app/creativities/details/:crea_id" component={Details} />
        <Route  path="/app/creativities/new" component={NewCreativities} />
        <Route  path="/app/creativities/utilities" component={Utility} />
        <Route  path="/app/creativities/dashboard" component={Dashboard} />
        <Route  path="/app/minisite/dashboard" component={Minisite} />
        <Route  path="/app/creativities/templates" component={Templates} />
        <Route  path="/app/counting/emailing" component={Counting} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </ErrorBoundary>
    </HashRouter>
  );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
    // "ace-builds": "^1.8.1",
    //"react-ace": "^10.1.0",
