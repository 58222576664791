import React from 'react';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Mettre à jour l'état pour afficher l'interface de secours
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Erreur capturée par ErrorBoundary:", error, errorInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && !prevState.hasError) {
      // Rediriger vers la page de login
      window.location.replace('/app')
    }
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez également afficher une interface de secours ici
      return null;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
