
export const base64_decode = (str)=>{
    try {
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    } catch (error) {
        return 'error decoding data'
    }
    
}

export const base64_encode = (str)=>{
    try {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    } catch (error) {
        return 'error decoding data'
    }
 
}

export const base64EncodeUnicode = (str) => {
    try {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));  
    } catch (error) {
        return 'error decoding data'
    }
    
}

export const generateKey = () => {
    try {
        var length = 10
        var result = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    } catch (error) {
        return 'xyz'
    }
    
}


export const generateColors = (count = 1, usePredefined = true) => {
    const predefinedColors = ['#f0d78c', '#64c4ed', '#64c4ed', '#64c4ed', '#64c4ed', '#64c4ed', '#64c4ed', '#64c4ed', '#64c4ed','#64c4ed','#64c4ed'];
    const colors = [];

    for (let i = 0; i < count; i++) {
        let color;
        if (usePredefined) {
            // Choisir une couleur aléatoire dans la palette prédéfinie
            const randomIndex = Math.floor(Math.random() * predefinedColors.length);
            color = predefinedColors[randomIndex];
        } else {
            // Générer une couleur hexadécimale aléatoire
            color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
        }
        colors.push(color);
    }

    return colors;
};