const REACT_APP_ENDPOINT = "https://dev-back-konticreav2.kontikimedia.fr"
// const REACT_APP_ENDPOINT = "https://konticreav2.kontikimedia.fr:5009"
// const REACT_APP_ENDPOINT = "http://127.0.0.1:5009"
// user
export const REACT_APP_ENDPOINT_AUTHENTIFICATION = REACT_APP_ENDPOINT + "/api/signin"
export const REACT_APP_ENDPOINT_CHECK = REACT_APP_ENDPOINT + "/api/user/check"
export const REACT_APP_ENDPOINT_DATABASE_VIGNETTE = REACT_APP_ENDPOINT + "/api/database/image_liste"
export const REACT_APP_ENDPOINT_USER_LIST = REACT_APP_ENDPOINT + "/api/user/all"
export const REACT_APP_ENDPOINT_USER_BASE = REACT_APP_ENDPOINT + "/api/base/liste"
export const REACT_APP_ENDPOINT_USER_ADMIN_ADD = REACT_APP_ENDPOINT + "/api/admin/user/add"
export const REACT_APP_ENDPOINT_USER_INFO_UPDATE = REACT_APP_ENDPOINT + "/api/user/update"
export const REACT_APP_ENDPOINT_USER_ADMIN_UPDATE = REACT_APP_ENDPOINT + "/api/admin/user/update"
export const REACT_APP_ENDPOINT_USER_CREA = REACT_APP_ENDPOINT + "/api/creativities/list"
export const REACT_APP_ENDPOINT_USER_BASE_PREFERENCE = REACT_APP_ENDPOINT + "/api/user/preference/update"
export const REACT_APP_ENDPOINT_USER_ADMIN_DISABLE = REACT_APP_ENDPOINT + "/api/admin/user/disable"
export const REACT_APP_ENDPOINT_USER_ADMIN_CACHE_CLEAR = REACT_APP_ENDPOINT + "/api/admin/user/clear_cache"
export const REACT_APP_ENDPOINT_USER_ADMIN_ENABLE = REACT_APP_ENDPOINT + "/api/admin/user/enable"
export const REACT_APP_ENDPOINT_BASE_ALL = REACT_APP_ENDPOINT + "/api/base/all"
export const REACT_APP_ENDPOINT_BASE_ADMIN_USER_MANAGEMENT = REACT_APP_ENDPOINT + "/api/admin/base/user/liste"
export const REACT_APP_ENDPOINT_BASE_ASSIGN = REACT_APP_ENDPOINT + "/api/base/assign"
export const REACT_APP_ENDPOINT_BASE_DETAIL = REACT_APP_ENDPOINT + "/api/base/details  "
export const REACT_APP_ENDPOINT_BASE_UPDATE = REACT_APP_ENDPOINT + "/api/base/update"
export const REACT_APP_ENDPOINT_ES_SEEDLISTS_CREATE = REACT_APP_ENDPOINT + "/api/es/seedlist/new"
export const REACT_APP_ENDPOINT_ES_SEEDLISTS_ADD = REACT_APP_ENDPOINT + "/api/es/seedlist/add"
export const REACT_APP_ENDPOINT_ES_SEEDLISTS_LIST_CREATED = REACT_APP_ENDPOINT + "/api/es/seedlist/liste_created"
export const REACT_APP_ENDPOINT_ES_EXPORT_LIST_CREATED = REACT_APP_ENDPOINT + "/api/es/segment/liste_exported"
export const REACT_APP_ENDPOINT_ES_SUBSCRIBERLISTS_CREATE = REACT_APP_ENDPOINT + "/api/es/subscriber/new"
export const REACT_APP_ENDPOINT_ES_SUBSCRIBERLISTS_ADD = REACT_APP_ENDPOINT + "/api/es/subscriber/add"
export const REACT_APP_ENDPOINT_ES_SEGMENTS_EXPORT = REACT_APP_ENDPOINT + "/api/es/segment/export"
export const REACT_APP_ENDPOINT_ES_SEGMENTS_EXPORT_LIST = REACT_APP_ENDPOINT + "/api/es/segment/liste_exported"
export const REACT_APP_ENDPOINT_ES_INPROGRESS_LIST = REACT_APP_ENDPOINT + "/api/router/inprogress/list"
export const REACT_APP_ENDPOINT_ES_SENT_LIST = REACT_APP_ENDPOINT + "/api/router/sent/list"
export const REACT_APP_ENDPOINT_ES_TEST_LIST = REACT_APP_ENDPOINT + "/api/router/test/list"
export const REACT_APP_ENDPOINT_ES_SCHEDULED_LIST = REACT_APP_ENDPOINT + "/api/router/scheduled/list"
export const REACT_APP_ENDPOINT_ES_SCHEDULED_DETAIL = REACT_APP_ENDPOINT + "/api/router/scheduled/detail"
export const REACT_APP_ENDPOINT_ES_DRAFT_LIST = REACT_APP_ENDPOINT + "/api/router/draft/list "
export const REACT_APP_ENDPOINT_ES_DRAFT_DETAIL = REACT_APP_ENDPOINT + "/api/router/draft/detail"
export const REACT_APP_ENDPOINT_ES_DRAFT_PREVIEW = REACT_APP_ENDPOINT + "/api/router/draft/preview"
export const REACT_APP_ENDPOINT_ES_DRAFT_CREATE = REACT_APP_ENDPOINT + "/api/router/draft/create"
export const REACT_APP_ENDPOINT_ES_DRAFT_SAVE = REACT_APP_ENDPOINT + "/api/router/draft/save"
export const REACT_APP_ENDPOINT_ES_MESSAGE_SCHEDULED_VALIDATE = REACT_APP_ENDPOINT + "/api/router/scheduled/validate"
export const REACT_APP_ENDPOINT_ES_MESSAGE_SCHEDULED_CANCEL = REACT_APP_ENDPOINT + "/api/router/scheduled/canceled"
export const REACT_APP_ENDPOINT_ES_BAT_VALIDATION = REACT_APP_ENDPOINT + "/api/router/bat/validation"
export const REACT_APP_ENDPOINT_ES_BAT_LISTE = REACT_APP_ENDPOINT + "/api/router/bat/liste"
export const REACT_APP_ENDPOINT_ES_MESSAGE_FULL_PREVIEW = REACT_APP_ENDPOINT + "/api/router/draft/preview"
export const REACT_APP_ENDPOINT_ES_MESSAGE_SCHEDULED_UPDATE = REACT_APP_ENDPOINT + "/api/router/scheduled/update"
export const REACT_APP_ENDPOINT_TOOLSO_CREATE_CONTACT = REACT_APP_ENDPOINT + "/api/sftp/kontiki/deliv/toolso/upload"
export const REACT_APP_ENDPOINT_TOOLSO_LISTE_SFTP = REACT_APP_ENDPOINT + "/api/sftp/kontiki/deliv/toolso/liste"
export const REACT_APP_ENDPOINT_TOOLSO_LISTE_UPLOADED = REACT_APP_ENDPOINT + "/api/sftp/kontiki/deliv/toolso/liste_uploaded"
export const REACT_APP_ENDPOINT_STATS_DATA = REACT_APP_ENDPOINT + "/api/stats/getStatsData"
export const REACT_APP_ENDPOINT_STATS_TAGS = REACT_APP_ENDPOINT + "/api/stats/liste_tags"
export const REACT_APP_ENDPOINT_STATS_ADVERTISER = REACT_APP_ENDPOINT + "/api/stats/liste_advertisers"
export const REACT_APP_ENDPOINT_STATS_CLIENTS = REACT_APP_ENDPOINT + "/api/stats/liste_clients"
export const REACT_APP_ENDPOINT_STATS_COUNTRY_CURRENCY = REACT_APP_ENDPOINT + "/api/stats/getcountriesandcurrencies"
export const REACT_APP_ENDPOINT_STATS_HEADER = REACT_APP_ENDPOINT + "/api/stats/getHeader"
export const REACT_APP_ENDPOINT_STATS_FOOTER = REACT_APP_ENDPOINT + "/api/stats/getFooter"
export const REACT_APP_ENDPOINT_STATS_SEEDLIST = REACT_APP_ENDPOINT + "/api/stats/getSeedlist"
export const REACT_APP_ENDPOINT_STATS_SUBSCRIBER = REACT_APP_ENDPOINT + "/api/stats/getSubscriber"
export const REACT_APP_ENDPOINT_STATS_SEGMENT = REACT_APP_ENDPOINT + "/api/stats/getSegments"
export const REACT_APP_ENDPOINT_STATS_SUPPRESSION = REACT_APP_ENDPOINT + "/api/stats/getSuppressionLists"
export const REACT_APP_ENDPOINT_STATS_MODELS = REACT_APP_ENDPOINT + "/api/stats/getModel"
export const REACT_APP_ENDPOINT_STATS_SCHEDULE_EMAILING = REACT_APP_ENDPOINT + "/api/stats/newschedule"
export const REACT_APP_ENDPOINT_STATS_UPDATE_EMAILING = REACT_APP_ENDPOINT + "/api/stats/update"
export const REACT_APP_ENDPOINT_SMS_LISTE_ALL_BASE = REACT_APP_ENDPOINT + "/api/sms/stats/base/liste"
export const REACT_APP_ENDPOINT_SMS_LISTE_ALL_CAMPAGN = REACT_APP_ENDPOINT + "/api/sms/internal/liste"
export const REACT_APP_ENDPOINT_SMS_CONTACT_UPLOAD = REACT_APP_ENDPOINT + "/api/sms/internal/contact/import"
export const REACT_APP_ENDPOINT_SMS_LISTE_ALL_GROUP = REACT_APP_ENDPOINT + "/api/sms/group/all"
export const REACT_APP_ENDPOINT_SMS_LISTE_SENDER = REACT_APP_ENDPOINT + "/api/sms/internal/sender/liste"
export const REACT_APP_ENDPOINT_SMS_ADD_GROUP = REACT_APP_ENDPOINT + "/api/sms/group/add"
export const REACT_APP_ENDPOINT_SMS_UPDATE_GROUP = REACT_APP_ENDPOINT + "/api/sms/group/update"
export const REACT_APP_ENDPOINT_SMS_DELETE_GROUP = REACT_APP_ENDPOINT + "/api/sms/group/delete"
export const REACT_APP_ENDPOINT_SMS_EMPTY_GROUP = REACT_APP_ENDPOINT + "/api/sms/group/empty"
export const REACT_APP_ENDPOINT_SMS_DUPLICATE = REACT_APP_ENDPOINT + "/api/sms/duplicate"
export const REACT_APP_ENDPOINT_SMS_DUPLICATE_SENT = REACT_APP_ENDPOINT + "/api/sms/duplicate/sent"
export const REACT_APP_ENDPOINT_SMS_DELETE = REACT_APP_ENDPOINT + "/api/sms/dropDraft"
export const REACT_APP_ENDPOINT_SMS_CREATE = REACT_APP_ENDPOINT + "/api/sms/create"
export const REACT_APP_ENDPOINT_SMS_SCHEDULE = REACT_APP_ENDPOINT + "/api/sms/scheduled_sms"
export const REACT_APP_ENDPOINT_SMS_CANCEL_SCHEDULED = REACT_APP_ENDPOINT + "/api/sms/scheduled/cancel"
export const REACT_APP_ENDPOINT_SMS_STATS_SENT = REACT_APP_ENDPOINT + "/api/sms/sent/stats"
export const REACT_APP_ENDPOINT_SMS_STATS_SENT_UPDATE = REACT_APP_ENDPOINT + "/api/sms/sent/update"
export const REACT_APP_ENDPOINT_SMS_ERROR_CODE = REACT_APP_ENDPOINT + "/api/sms/error/liste"
export const REACT_APP_ENDPOINT_SMS_TEXTE_LISTE = REACT_APP_ENDPOINT + "/api/sms/texte/liste"
export const REACT_APP_ENDPOINT_SMS_TEXTE_CREATE = REACT_APP_ENDPOINT + "/api/sms/texte/create"
export const REACT_APP_ENDPOINT_SMS_TEXTE_UPDATE = REACT_APP_ENDPOINT + "/api/sms/texte/update"
export const REACT_APP_ENDPOINT_SMS_TEXTE_DELETE = REACT_APP_ENDPOINT + "/api/sms/texte/delete"
export const REACT_APP_ENDPOINT_CREA_PREVIEW = REACT_APP_ENDPOINT + "/api/crea/preview?crea"
export const REACT_APP_ENDPOINT_CREA_DETAILS = REACT_APP_ENDPOINT + "/api/creativities/details"
export const REACT_APP_ENDPOINT_CREA_UPDATE = REACT_APP_ENDPOINT + "/api/creativities/update"
export const REACT_APP_ENDPOINT_CREA_LISTE = REACT_APP_ENDPOINT + "/api/creativities/list"
export const REACT_APP_ENDPOINT_CREA_REOPTIMIZE = REACT_APP_ENDPOINT + "/api/reoptimize_html"
export const REACT_APP_ENDPOINT_KONTICREA_BAT_REUSE = REACT_APP_ENDPOINT + "/api/reuse_html"
export const REACT_APP_ENDPOINT_CREA_FILTER = REACT_APP_ENDPOINT + "/api/creativities/filter"
export const REACT_APP_ENDPOINT_CREA_FILTER_DETAILS = REACT_APP_ENDPOINT + "/api/creativities/minisite/details"
export const REACT_APP_ENDPOINT_LINK_SHORTER = REACT_APP_ENDPOINT + "/api/linkshorter/shorten"
export const REACT_APP_ENDPOINT_IMAGE_UPLOAD = REACT_APP_ENDPOINT + "/api/linkshorter/upload"
export const REACT_APP_ENDPOINT_DYNAMIC_UPLOAD = REACT_APP_ENDPOINT + "/api/linkshorter/dynamic"
export const REACT_APP_ENDPOINT_HTML_FORMATTER = REACT_APP_ENDPOINT + "/api/linkshorter/utilities"
export const REACT_APP_ENDPOINT_HTML_PROMOTION_PREVIEW = REACT_APP_ENDPOINT + "/api/crea/preview/promotion"
export const REACT_APP_ENDPOINT_SCHEDULED_OVERVIEW = REACT_APP_ENDPOINT + "/api/router/scheduled/overview"
export const REACT_APP_ENDPOINT_IN_PROGRESS_OVERVIEW = REACT_APP_ENDPOINT + "/api/router/inprogress/overview"
export const REACT_APP_ENDPOINT_TOP_SUBJECT = REACT_APP_ENDPOINT + "/api/creativities/object/top"
export const REACT_APP_ENDPOINT_HASH_FILES = REACT_APP_ENDPOINT + "/api/utility/hash"
export const REACT_APP_ENDPOINT_HASH_FILES_LISTE = REACT_APP_ENDPOINT + "/api/utility/hash/liste"
export const REACT_APP_ENDPOINT_KONTICREA_HEADER = REACT_APP_ENDPOINT + "/api/creativities/template/header/list"
export const REACT_APP_ENDPOINT_KONTICREA_FOOTER = REACT_APP_ENDPOINT + "/api/creativities/template/footer/list"
export const REACT_APP_ENDPOINT_KONTICREA_LIST_KEYWORD = REACT_APP_ENDPOINT + "/api/tags/keywords"
export const REACT_APP_ENDPOINT_KONTICREA_VALIDATE_HTML = REACT_APP_ENDPOINT + "/api/html/alt/check"
export const REACT_APP_ENDPOINT_KONTICREA_LINKS_REQUEST = REACT_APP_ENDPOINT + "/api/linkshorter/utilities"
export const REACT_APP_ENDPOINT_KONTICREA_BAT_OPTIMIZE = REACT_APP_ENDPOINT + "/api/optimize_html"
export const REACT_APP_ENDPOINT_KONTICREA_BAT_OPTIMIZE_MULTIPLE = REACT_APP_ENDPOINT + "/api/optimize_html_multiple"
export const REACT_APP_ENDPOINT_ROUTER_LIST = REACT_APP_ENDPOINT + "/api/router/list"
export const REACT_APP_ENDPOINT_ROUTER_UPDATE = REACT_APP_ENDPOINT + "/api/router/update"
export const REACT_APP_ENDPOINT_ROUTER_DISABLE = REACT_APP_ENDPOINT + "/api/router/disable"
export const REACT_APP_ENDPOINT_ROUTER_CREATE = REACT_APP_ENDPOINT + "/api/router/create"
export const REACT_APP_ENDPOINT_SMS_DRAFT_LIST = REACT_APP_ENDPOINT + "/api/sms/internal/liste"
export const REACT_APP_ENDPOINT_SMS_SENT_LIST = REACT_APP_ENDPOINT + "/api/sms/sent"
export const REACT_APP_ENDPOINT_SMS_SENT_LIST_ROUTER = REACT_APP_ENDPOINT + "/api/sms/sent/router"
export const REACT_APP_ENDPOINT_SMS_SCHEDULED_LIST = REACT_APP_ENDPOINT + "/api/sms/scheduled/list"
export const REACT_APP_ENDPOINT_SMS_SENDER_LIST = REACT_APP_ENDPOINT + "/api/sms/sender_liste"
export const REACT_APP_ENDPOINT_SMS_TEST_MESSAGE = REACT_APP_ENDPOINT + "/api/sms/test"
export const REACT_APP_ENDPOINT_SMS_UPDATE_STATS = REACT_APP_ENDPOINT + "/api/sms/stats/update"
export const REACT_APP_ENDPOINT_SMS_STATS_LIST = REACT_APP_ENDPOINT + "/api/sms/stats/liste"
export const REACT_APP_ENDPOINT_SMS_STATS_LIST_FILTER = REACT_APP_ENDPOINT + "/api/sms/stats/filter"
export const REACT_APP_ENDPOINT_DWH_SMS_REQUEST = REACT_APP_ENDPOINT + "/api/sms/dwh/create"
export const REACT_APP_ENDPOINT_DWH_SMS_GET_SEGMENT = REACT_APP_ENDPOINT + "/api/sms/dwh/get"
export const REACT_APP_ENDPOINT_BIMI_CHECK = REACT_APP_ENDPOINT + "/api/minisite/bimi_check"
export const REACT_APP_ENDPOINT_MINISITE_LISTE = REACT_APP_ENDPOINT + "/api/minisite/liste"
export const REACT_APP_ENDPOINT_MINISITE_TRAFFIC = REACT_APP_ENDPOINT + "/api/minisite/database/traffic"
export const REACT_APP_ENDPOINT_MINISITE_CREATE = REACT_APP_ENDPOINT + "/api/minisite/create"
export const REACT_APP_ENDPOINT_MINISITE_UPDATE = REACT_APP_ENDPOINT + "/api/minisite/update"
export const REACT_APP_ENDPOINT_MINISITE_CLONE = REACT_APP_ENDPOINT + "/api/minisite/clone"
export const REACT_APP_ENDPOINT_MINISITE_DELETE = REACT_APP_ENDPOINT + "/api/minisite/delete"
export const REACT_APP_ENDPOINT_MINISITE_UPDATE_IMAGES = REACT_APP_ENDPOINT + "/api/minisite/images/update"
export const REACT_APP_ENDPOINT_MINISITE_UPDATE_RGPD = REACT_APP_ENDPOINT + "/api/minisite/rgpd/update"
export const REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_GENERATE = REACT_APP_ENDPOINT + "/api/creativities/template/konticrea/generate"
export const REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_HEADER_UPDATE = REACT_APP_ENDPOINT + "/api/creativities/template/header/update"
export const REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_DELETE = REACT_APP_ENDPOINT + "/api/creativities/template/delete"
export const REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_CLONE = REACT_APP_ENDPOINT + "/api/creativities/template/clone"
export const REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_ASSIGN = REACT_APP_ENDPOINT + "/api/creativities/template/assign"
export const REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_PARAMS_LIST = REACT_APP_ENDPOINT + "/api/creativities/template/konticrea/params"
export const REACT_APP_ENDPOINT_IMPACT_LIST = REACT_APP_ENDPOINT + "/api/delivery/impact/list"
export const REACT_APP_ENDPOINT_IMPACT_CALCULATE = REACT_APP_ENDPOINT + "/api/delivery/impact/calculate"
export const REACT_APP_ENDPOINT_IMPACT_EMERGENCY_UPDATE = REACT_APP_ENDPOINT + "/api/delivery/impact/emergency"
export const REACT_APP_ENDPOINT_IMPACT_UPDATE = REACT_APP_ENDPOINT + "/api/delivery/impact/update"
export const REACT_APP_ENDPOINT_EMAILING_GET_SEGMENT = REACT_APP_ENDPOINT + "/api/dwh/getSegment"
export const REACT_APP_ENDPOINT_EMAILING_INVALID_SEGMENT = REACT_APP_ENDPOINT + "/api/dwh/invalidateSegment"
export const REACT_APP_ENDPOINT_EMAILING_VALID_SEGMENT = REACT_APP_ENDPOINT + "/api/dwh/validateSegment"
export const REACT_APP_ENDPOINT_EMAILING_REQUEST_SEGMENT = REACT_APP_ENDPOINT + "/api/dwh/create"
