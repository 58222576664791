import React, { Component } from 'react'
import { Dropdown, Row, Col, Button, Select, Input, DatePicker, message, Tag, Tooltip, Checkbox, Switch, Alert, Menu } from 'antd'
import { getMainData } from '../Utilities/GetMainData'
import { generateKey, base64_encode, base64_decode } from '../Utilities/Utilities'
import axios from 'axios'
import loading from '../../images/loading3.gif'
import Status from './Status'
import { EditOutlined, SyncOutlined, CopyOutlined, EyeOutlined, RedoOutlined, PlusCircleOutlined, MinusCircleOutlined, InfoCircleOutlined, BuildOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router'
import Modal from 'react-modal'
import '../../css/TaskCreativities.css'
import moment from 'moment';
import { list_segment } from '../Utilities/Stats'
import { reuse, upload_multiple } from '../Utilities/Crea'
import Editor from '@monaco-editor/react';
import { base_user } from '../Utilities/User'
import SegmentDWH from './SegmentDWH'
import { get_segments_dwh, invalidate_segments_dwh, request_segment, validate_segments_dwh } from '../Utilities/Datawarehouse'
import { Chart } from "react-google-charts"
import TextArea from 'antd/lib/input/TextArea';
import dots from '../../images/3dots-1.png'
import ReactPaginate from 'react-paginate';
import * as config from '../config/config'

const { Option } = Select
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const timeFormat = "HH:mm:ss";
const data_chart = [
	["Task", "Hours per Day"],
	["Work", 11],
	["Eat", 2],
	["Commute", 2],
	["Watch TV", 2],
	["Sleep", 7],
];

const options_chart = {
	title: "My Daily Activities",
	is3D: true,
};

class Task extends Component {
	constructor(props) {
		super(props)
		this.state = {
			user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
			apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
			stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
			isError: false,
			displayAdmin: 'none',
			liste_all_user: [],
			liste_all_base: [],
			liste_all_tags: [],
			liste_base_user: [],
			liste_all_country: [],
			liste_segments: [],
			liste_suppression: [],
			liste_creativities: [],
			loadingRefresh: false,
			FilterValue: null,
			FilterTags: null,
			FilterStartDate: null,
			FilterEndDate: null,
			FilterUser: [],
			FilterBase: [],
			Filter_limit: 50,
			loadingLoadMore: false,
			DisplayFieldsId: 'none',
			DisplayFieldsCreativities: 'none',
			DisplayFieldsDate: 'none',
			DisplayFieldsTags: 'none',
			DisplayFieldsUser: 'none',
			DisplayFieldsDatabase: 'none',
			displayTask: 'none',
			displayLoading: 'block',
			show_detail: false,
			show_detail_id: null,
			show_reoptimize: false,
			crea_id: null,
			user_id_creativity: null,
			crea_base_id: null,
			crea_tags_id: null,
			crea_username: null,
			crea_reoptimize_pixels: true,
			crea_reoptimize_compress: true,
			crea_reoptimize_cutting: false,
			crea_reoptimize_link: true,
			crea_reoptimize_trigger: false,
			crea_reoptimize_comment: false,
			crea_reoptimize_img: true,
			crea_reoptimize_jumping: true,
			isClone: false,
			//multiple optimization
			show_modal_multiple: false,
			show_simple_multiple_optimization: 'none',
			show_client_multiple_optimization: 'none',
			loading_multiple: false,
			loading_clients: false,
			multiple_list: [{ "database_id": null, "schedulede_date": moment().add(1, 'days'), "segment": [], "suppresssion": [], "available_from": moment().add(1, 'days'), "available_until": moment().add(31, 'days'), "sender": null, "subject": null, "type": "Newsletters", "segments_liste": [], "suppressions_liste": [], "preheader": null, "html_code": null, "pix": false, "img": false, "compress": false, "link": false, "jumping": false, "comment": false, "stats": false, "router": false, "trigger": false }],
			clientid: null,
			showHtmlModal: false,
			indexHtml: 0,
			crea_temp_code: null,
			modal_title: 'MULTIPLE CREATIVITIES OPTIMIZATION',
			displayHtmlButton: 'block',
			onlyme: false,
			show_reuse: false,
			crea_segment: [],
			loadingReuse: false,

			show_seginfo: false,
			segdwhinfo: {},
			segdwhstatus: null,
			segdwhtagstatuscolor: null,
			segdwhvolume: null,
			segdwhtimeleft: null,
			segdwhid: null,
			segdwhbasename: null,
			segdwhcr: null,
			segdwhor: null,
			segdwhrevr: null,
			segdwhage_data: [],
			segdwhageoptions: {},
			segdwhgender_data: [],
			segdwhgenderoptions: {},

			segdwhactif_data: [],
			segdwhactif_options: {},

			displayLoadingSegSwh: 'block',
			displaySegdwhinfo: 'none',
			loadingInvalidate: false,
			loadingRequestNew: false,
			loadingValidate: false,
			displayValidateButton: 'block',
			displayInvalidateButton: 'block',
			displayRequestNewButton: 'block',

			show_new_seg_req: false,
			dwh_scheduled_on: null,
			dwh_model: [],
			dwh_volume: null,
			dwh_domain_family: [],
			dwh_sexe: [],
			dwh_age: [],
			dwh_csp: [],
			dwh_score_of_landlords: [],
			dwh_score_of_individual_houses: [],
			dwh_score_of_tax_households: [],
			dwh_poverty_score: [],
			dwh_score_median_income: [],
			dwh_zipcode: null,
			dwh_optin_telemarketing: false,
			dwh_optin_sms: false,
			dwh_stats_base_id: null,
			dwh_stats_sendoutid: null,
			dwh_tags_id: null,
			dwh_country_id: null,
			dwh_fed_now: true,
			// pagination
			currentPage: 0,
			itemsPerPage: 10, //

		}
	}
	componentDidMount() {
		localStorage.setItem('defaulttabcrea', '3')
		if (base64_decode(localStorage.getItem(base64_encode('roles'))) === 'Admin') {
			this.setState({ displayAdmin: 'block' })
		}
		this.getData()
	}

	getData() {
		Promise.resolve(getMainData(this.state.user_id, this.state.apikey, this.state.stats_apikey, this.state.Filter_limit, true, true, true, true, true, false, true, true, true, this.state.onlyme)).then((value) => {
			var liste_base = value['base_user']
			console.log(value)
			if (base64_decode(localStorage.getItem(base64_encode('roles'))) === 'Admin') {
				liste_base = value['all_base']
			}
			this.setState({
				liste_all_user: value['all_user'],
				liste_all_base: value['all_base'],
				liste_all_tags: value['all_tags'],
				liste_all_country: value['all_country'],
				liste_base_user: liste_base,
				liste_creativities: value['all_crea'],
				displayLoading: 'none',
				displayTask: 'block',
				shownew: false,
				loadingRefresh: false
			})
		});
	}

	onRefreshPage() {
		this.setState({
			loadingRefresh: true,
			loadingLoadMore: false,
			DisplayFieldsId: 'none',
			DisplayFieldsCreativities: 'none',
			DisplayFieldsDate: 'none',
			DisplayFieldsTags: 'none',
			DisplayFieldsUser: 'none',
			DisplayFieldsDatabase: 'none',
			FilterValue: null,
			FilterTags: null,
			FilterStartDate: null,
			FilterEndDate: null,
			FilterUser: [],
			FilterBase: [],
			displayTask: 'none',
			displayLoading: 'block',

		})
		this.getData()
	}

	OnCreateNewCreativities() {
		this.setState({ shownew: true })
	}

	onFilterSearch(value) {
		this.setState({ FilterValue: value })
		switch (value) {
			case 'id':
				this.setState({
					DisplayFieldsId: 'block',
					DisplayFieldsDate: 'none',
					DisplayFieldsCreativities: 'none',
					DisplayFieldsTags: 'none',
					DisplayFieldsUser: 'none',
					DisplayFieldsDatabase: 'none',
				})
				break;

			case 'database':
				this.setState({
					DisplayFieldsId: 'none',
					DisplayFieldsDate: 'none',
					DisplayFieldsCreativities: 'none',
					DisplayFieldsTags: 'none',
					DisplayFieldsUser: 'none',
					DisplayFieldsDatabase: 'block',
				})
				break;
			case 'user':
				this.setState({
					DisplayFieldsId: 'none',
					DisplayFieldsDate: 'none',
					DisplayFieldsCreativities: 'none',
					DisplayFieldsTags: 'none',
					DisplayFieldsUser: 'block',
					DisplayFieldsDatabase: 'none',
				})
				break;
			case 'creativity':
				this.setState({
					DisplayFieldsId: 'none',
					DisplayFieldsDate: 'none',
					DisplayFieldsCreativities: 'block',
					DisplayFieldsTags: 'none',
					DisplayFieldsUser: 'none',
					DisplayFieldsDatabase: 'none',
				})
				break;
			case 'tags':
				this.setState({
					DisplayFieldsId: 'none',
					DisplayFieldsDate: 'none',
					DisplayFieldsCreativities: 'none',
					DisplayFieldsTags: 'block',
					DisplayFieldsUser: 'none',
					DisplayFieldsDatabase: 'none',
				})
				break;
			case 'date':
				this.setState({
					DisplayFieldsId: 'none',
					DisplayFieldsDate: 'block',
					DisplayFieldsCreativities: 'none',
					DisplayFieldsTags: 'none',
					DisplayFieldsUser: 'none',
					DisplayFieldsDatabase: 'none',
				})
				break;
			default:
				this.setState({
					DisplayFieldsId: 'none',
					DisplayFieldsDate: 'none',
					DisplayFieldsCreativities: 'none',
					DisplayFieldsTags: 'none',
					DisplayFieldsUser: 'none',
					DisplayFieldsDatabase: 'none',
				})
				break;
		}
	}

	onSearch() {
		this.setState({ loadingLoadMore: true })
		if (this.state.FilterValue === null) {
			if (this.state.Filter_limit === 50) {
				this.setState({ loadingLoadMore: false })
			}
		}

		if (this.state.DisplayFieldsId === 'block' && (this.state.FilterId === null || (this.state.FilterId).length === 0)) {
			message.warning('Missing id value', 1)
			this.setState({ loadingLoadMore: false })
			return ''
		}
		if (this.state.DisplayFieldsCreativities === 'block' && (this.state.FilterCrea === null || (this.state.FilterCrea).length === 0)) {
			message.warning('Missing creativities name value', 1)
			this.setState({ loadingLoadMore: false })
			return ''
		}
		if (this.state.DisplayFieldsDatabase === 'block' && this.state.FilterBase.length === 0) {
			message.warning('Missing database value', 1)
			this.setState({ loadingLoadMore: false })
			return ''
		}
		if (this.state.DisplayFieldsDate === 'block' && (this.state.FilterStartDate === null || this.state.FilterEndDate === null)) {
			message.warning('Missing date range value', 1)
			this.setState({ loadingLoadMore: false })
			return ''
		}
		if (this.state.DisplayFieldsUser === 'block' && this.state.FilterUser.length === 0) {
			message.warning('Missing username value', 1)
			this.setState({ loadingLoadMore: false })
			return ''
		}
		if (this.state.DisplayFieldsTags === 'block' && this.state.FilterTags === null) {
			message.warning('Missing tag value', 1)
			this.setState({ loadingLoadMore: false })
			return ''
		}
		var fd = new FormData()
		fd.append('user_id', this.state.user_id)
		fd.append('apikey', this.state.apikey)
		fd.append('filterby', this.state.FilterValue)
		fd.append('limit', this.state.Filter_limit)
		switch (this.state.FilterValue) {
			case 'database':
				fd.append('filter', JSON.stringify(this.state.FilterBase))
				break;
			case 'id':
				fd.append('filter', this.state.FilterId)
				break;
			case 'user':
				fd.append('filter', JSON.stringify(this.state.FilterUser))
				break;
			case 'tags':
				fd.append('filter', this.state.FilterTags)
				break;
			case 'date':
				fd.append('filterStart', this.state.FilterStartDate)
				fd.append('filterEnd', this.state.FilterEndDate)
				break;
			case 'creativity':
				fd.append('filter', this.state.FilterCrea)
				break;
			default:
				fd.append('filter', 'null')
				break;
		}

		axios.post(config.REACT_APP_ENDPOINT_CREA_FILTER, fd)
			.then(res => {
				console.log(res.data)
				this.setState({
					liste_creativities: res.data,
					loadingSearch: false,
					loadingLoadMore: false
				})
			})
	}

	OnPreviewCreaDetails(path) {
		this.setState({
			show_detail: true,
			show_detail_id: path
		})
	}

	onReoptimizingRequests() {
		this.setState({ loadingReoptimize: true })
		var tagsname = null
		this.state.liste_all_tags.map(item => {
			if (parseInt(this.state.crea_tags_id) === parseInt(item['id'])) {
				tagsname = item['tag']
			}
		})
		var fd = new FormData()
		fd.append('user_id', this.state.user_id)
		fd.append('apikey', this.state.apikey)
		fd.append('creapixeloption', this.state.crea_reoptimize_pixels)
		fd.append('creaimgoption', this.state.crea_reoptimize_img)
		fd.append('creacutoption', this.state.crea_reoptimize_cutting)
		fd.append('crealinkoption', this.state.crea_reoptimize_link)
		fd.append('creajumpingoption', this.state.crea_reoptimize_jumping)
		fd.append('creacommentoption', this.state.crea_reoptimize_comment)
		fd.append('creacompressoption', this.state.crea_reoptimize_compress)
		fd.append('creatriggeroption', this.state.crea_reoptimize_trigger)
		fd.append('crea_id', this.state.crea_id)
		fd.append('creabaseid', this.state.crea_base_id)
		fd.append('user_id_creativity', this.state.user_id_creativity)
		fd.append('user_id_creativity_name', this.state.crea_username)
		fd.append('tagsname', tagsname)
		axios.post(config.REACT_APP_ENDPOINT_CREA_REOPTIMIZE, fd)
			.then(res => {
				setTimeout(() => {
					window.location.reload()
				}, 1000)
			})
	}

	async getListSegments(database_id) {
		var response = []
		var fd = new FormData()
		fd.append('user_id', this.state.user_id)
		fd.append('apikey', this.state.apikey)
		fd.append('stats_apikey', this.state.stats_apikey)
		fd.append('base_id', database_id)
		await axios.post(config.REACT_APP_ENDPOINT_STATS_SEGMENT, fd)
			.then(res => {
				response = res.data
			})
		return response
	}

	async getListSuppression(database_id) {
		var response = []
		var fd = new FormData()
		fd.append('user_id', this.state.user_id)
		fd.append('apikey', this.state.apikey)
		fd.append('stats_apikey', this.state.stats_apikey)
		fd.append('base_id', database_id)
		await axios.post(config.REACT_APP_ENDPOINT_STATS_SUPPRESSION, fd)
			.then(res => {
				response = res.data
			})
		return response
	}

	async onMultipleDatabaseChanged(value, index) {
		var seg = await this.getListSegments(value)
		var suppr = await this.getListSuppression(value)
		var old = this.state.multiple_list
		old[index]['database_id'] = value.toString()
		old[index]['segments_liste'] = seg
		old[index]['suppressions_liste'] = suppr
		this.state.liste_base_user.map(item => {
			if (parseInt(item['id']) === parseInt(value)) {
				old[index]['sender'] = item['defaultSender']
				old[index]['pix'] = parseInt(item['defaultpix']) === 1 ? true : false
				old[index]['img'] = parseInt(item['defaultimg']) === 1 ? true : false
				old[index]['compress'] = true
				old[index]['link'] = parseInt(item['defaultlink']) === 1 ? true : false
				old[index]['jumping'] = true
				old[index]['comment'] = parseInt(item['defaultcomment']) === 1 ? true : false
				old[index]['stats'] = parseInt(item['stats_checked']) === 1 ? true : false
				old[index]['router'] = parseInt(item['router_checked']) === 1 ? true : false
			}
		})
		this.setState({
			multiple_list: old
		})


	}

	onMultipleTypeChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['type'] = value.toString()
		this.setState({
			multiple_list: old
		})
	}

	onMultipleScheduledChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['schedulede_date'] = moment(value)
		this.setState({
			multiple_list: old
		})
	}

	onMultipleSegmentChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['segment'] = value
		this.setState({
			multiple_list: old
		})
	}

	onMultipleAvailableFromChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['available_from'] = moment(value)
		this.setState({
			multiple_list: old
		})
	}

	onMultipleAvailableUntilChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['available_until'] = moment(value)
		this.setState({
			multiple_list: old
		})
	}

	onMultipleSenderChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['sender'] = value
		this.setState({
			multiple_list: old
		})
	}

	onMultipleObjectChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['subject'] = value
		this.setState({
			multiple_list: old
		})
	}

	onMultiplePreheaderChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['preheader'] = value
		this.setState({
			multiple_list: old
		})
	}

	onMultipleSuppressionChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['suppresssion'] = value
		this.setState({
			multiple_list: old
		})
	}

	onMultipleHtmlChanged(value, index) {

	}

	onAddDatabase() {
		var old = this.state.multiple_list
		old.push({ "database_id": null, "schedulede_date": moment().add(1, 'days'), "segment": [], "suppresssion": [], "available_from": moment().add(1, 'days'), "available_until": moment().add(31, 'days'), "sender": null, "subject": null, "type": "Newsletters", "segments_liste": [], "suppressions_liste": [], "preheader": null, "html_code": null, "pix": false, "img": false, "compress": false, "link": false, "jumping": true, "comment": false, "stats": false, "router": false, "trigger": false })
		this.setState({
			multiple_list: old
		})
	}

	onRemoveDatabase(index) {
		var old = this.state.multiple_list
		old.splice(index, 1)
		this.setState({
			multiple_list: old
		})
	}

	onCloseModalMultiple() {
		this.setState({
			multiple_list: [{ "database_id": null, "schedulede_date": moment().add(1, 'days'), "segment": [], "suppresssion": [], "available_from": moment().add(1, 'days'), "available_until": moment().add(31, 'days'), "sender": null, "subject": null, "type": "Newsletters", "segments_liste": [], "suppressions_liste": [], "preheader": null, "html_code": null, "pix": false, "img": false, "compress": false, "link": false, "jumping": false, "comment": false, "stats": false, "router": false, "trigger": false }],
			show_modal_multiple: false,
			modal_title: 'MULTIPLE CREATIVITIES OPTIMIZATION',
			crea_reoptimize_pixels: true,
			crea_reoptimize_compress: true,
			crea_reoptimize_cutting: false,
			crea_reoptimize_link: true,
			crea_reoptimize_trigger: false,
			crea_reoptimize_comment: true,
			crea_reoptimize_img: true,
			crea_reoptimize_jumping: true,
		})
	}

	onCloseModalHtml() {
		this.setState({
			showHtmlModal: false
		})
	}

	onHtmlButtonClicked(index) {
		this.setState({
			indexHtml: index,
			showHtmlModal: true
		})
	}

	onValidateHtml() {
		var index = this.state.indexHtml
		var old = this.state.multiple_list
		old[index]['html_code'] = base64_encode(this.state.crea_temp_code)
		this.setState({
			multiple_list: old,
			indexHtml: null,
			crea_temp_code: null,
			showHtmlModal: false
		})
	}

	onPixChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['pix'] = value
		this.setState({
			multiple_list: old
		})
	}

	onImageChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['img'] = value
		this.setState({
			multiple_list: old
		})
	}

	onCompressChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['compress'] = value
		this.setState({
			multiple_list: old
		})
	}

	onLinkChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['link'] = value
		this.setState({
			multiple_list: old
		})
	}

	onJumpingChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['jumping'] = value
		this.setState({
			multiple_list: old
		})
	}

	onCommentChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['comment'] = value
		this.setState({
			multiple_list: old
		})
	}

	onStatsChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['stats'] = value
		this.setState({
			multiple_list: old
		})
	}

	onRouterChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['router'] = value
		this.setState({
			multiple_list: old
		})
	}

	onTriggerChanged(value, index) {
		var old = this.state.multiple_list
		old[index]['trigger'] = value
		this.setState({
			multiple_list: old
		})
	}

	renderMultipleDatabase() {
		return this.state.multiple_list.map((el, i) =>
			<Row key={i} style={{ textAlign: 'center', fontSize: 14 }}>
				<Row>
					<Col span={23}>
						<Row>
							<Col span={8} style={{ paddingTop: 15 }}>
								<Row>
									<Col span={6} style={{ padding: 5 }}>
										<span>Database</span>
									</Col>
									<Col span={18}>
										<Select
											size="medium"
											placeholder="Select database"
											optionFilterProp="children"
											showSearch
											style={{ width: '90%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
											value={el['database_id']}
											onChange={(value) => {
												this.onMultipleDatabaseChanged(value, i)
											}}
										>
											{
												this.state.liste_base_user && this.state.liste_base_user.map(item => {
													var key = generateKey()
													return (
														<Option key={key} value={item.id}>{item['basename']}</Option>
													)
												})
											}
										</Select>
									</Col>
								</Row>
							</Col>

							<Col span={7} style={{ paddingTop: 15 }}>
								<Row>
									<Col span={10} style={{ paddingTop: 5 }}>
										<span>Date Scheduled</span>
									</Col>
									<Col span={14}>
										<DatePicker
											format={dateFormat}
											style={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
											value={el['schedulede_date']}
											onChange={(a, b) => {
												this.onMultipleScheduledChanged(b, i)
											}}
										/>
									</Col>
								</Row>
							</Col>

							<Col span={7} style={{ paddingTop: 15 }}>
								<Row>
									<Col span={10} style={{ paddingTop: 5 }}>
										<span>Type</span>
									</Col>
									<Col span={14}>
										<Select
											size="medium"
											style={{ width: '90%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
											value={el['type']}
											onChange={(value) => {
												this.onMultipleTypeChanged(value, i)
											}}
										>
											<Option key="Newsletters" value="Newsletters">Newsletters</Option>
											<Option key="Recurrings" value="Recurrings">Recurrings</Option>
											<Option key="Trigger" value="Trigger">Trigger</Option>

										</Select>
									</Col>
								</Row>
							</Col>

							<Col span={8} style={{ paddingTop: 10 }}>
								<Row>
									<Col span={6} style={{ paddingTop: 5 }}>
										<span>Available_from</span>
									</Col>
									<Col span={18}>
										<DatePicker
											format={dateFormat}
											style={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
											value={el['available_from']}
											onChange={(a, b) => {
												this.onMultipleAvailableFromChanged(b, i)
											}}
										/>
									</Col>
								</Row>
							</Col>

							<Col span={7} style={{ paddingTop: 10 }}>
								<Row>
									<Col span={10} style={{ paddingTop: 5 }}>
										<span>Available_until</span>
									</Col>
									<Col span={14}>
										<DatePicker
											format={dateFormat}
											style={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
											value={el['available_until']}
											onChange={(a, b) => {
												console.log(b)
												this.onMultipleAvailableUntilChanged(b, i)
											}}
										/>
									</Col>
								</Row>
							</Col>

							<Col span={7} style={{ paddingTop: 10, display: `${this.state.displayHtmlButton}` }}>
								<Row>
									<Col span={10} style={{ paddingTop: 5 }}>
										<span>HTML CODE</span>
									</Col>
									<Col span={14}>
										<button
											style={{ width: '80%', fontSize: 12 }}
											className="btn btn-outline-info"
											onClick={() => {
												this.onHtmlButtonClicked(i)
											}}
										>
											HTML CODE
										</button>
									</Col>
								</Row>
							</Col>

							<Col span={24} style={{ paddingTop: 10 }}>
								<Row>
									<Col span={2} style={{ padding: 5 }}>
										<span>Segment</span>
									</Col>
									<Col span={10}>
										<Select
											mode="multiple"
											optionFilterProp="children"
											showSearch
											size="medium"
											style={{ width: '94%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
											value={el['segment']}
											onChange={(value) => {
												this.onMultipleSegmentChanged(value, i)
											}}
										>
											{
												el['segments_liste'] && el['segments_liste'].map(item => {
													var key = generateKey()
													return (
														<Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
													)
												})
											}
										</Select>
									</Col>
									<Col span={2} style={{ padding: 5 }}>
										<span>Suppression</span>
									</Col>
									<Col span={10}>
										<Select
											mode="multiple"
											optionFilterProp="children"
											showSearch
											size="medium"
											style={{ width: '93%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
											value={el['suppression']}
											onChange={(value) => {
												this.onMultipleSuppressionChanged(value, i)
											}}
										>
											{
												el['suppressions_liste'] && el['suppressions_liste'].map(item => {
													var key = generateKey()
													return (
														<Option key={key} value={parseInt(item.Id)}>{item.Id + ' - ' + item.Name}</Option>
													)
												})
											}
										</Select>
									</Col>
								</Row>
							</Col>

							<Col span={24} style={{ paddingTop: 10 }}>
								<Row>
									<Col span={2} style={{ padding: 5 }}>
										<span>Sender</span>
									</Col>
									<Col span={22}>
										<Input
											size="medium"
											type="text"
											style={{ width: '97%', color: '#192a56', fontWeight: 700 }}
											value={el['sender']}
											onChange={(e) => {
												this.onMultipleSenderChanged(e.target.value, i)
											}}
										/>
									</Col>
								</Row>
							</Col>

							<Col span={24} style={{ paddingTop: 10 }}>
								<Row>
									<Col span={2} style={{ padding: 5 }}>
										<span>Object</span>
									</Col>
									<Col span={22}>
										<Input
											size="medium"
											type="text"
											style={{ width: '97%', color: '#192a56', fontWeight: 700 }}
											value={el['subject']}
											onChange={(e) => {
												this.onMultipleObjectChanged(e.target.value, i)
											}}
										/>
									</Col>
								</Row>
							</Col>

							<Col span={24} style={{ paddingTop: 10 }}>
								<Row>
									<Col span={2} style={{ padding: 5 }}>
										<span>Preheader</span>
									</Col>
									<Col span={22}>
										<Input
											size="medium"
											type="text"
											style={{ width: '97%', color: '#192a56', fontWeight: 700 }}
											value={el['preheader']}
											onChange={(e) => {
												this.onMultiplePreheaderChanged(e.target.value, i)
											}}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col span={1}>
						{
							i === 0 && <Col span={2} style={{ paddingLeft: 0, textAlign: 'center', paddingTop: 38 }}>
								<Tooltip title="Add rows" placement="right">
									<PlusCircleOutlined
										style={{ fontSize: 25 }}
										onClick={() => {
											this.onAddDatabase()
										}}
									/>
								</Tooltip>
							</Col>
						}
						{
							i !== 0 && <Col span={2} style={{ paddingLeft: 0, textAlign: 'center', paddingTop: 38 }}>
								<Tooltip title="Add rows" placement="right">
									<MinusCircleOutlined
										style={{ fontSize: 25 }}
										onClick={() => {
											this.onRemoveDatabase(i)
										}}
									/>
								</Tooltip>
							</Col>
						}
					</Col>
				</Row>
				<Row style={{ width: '90%', margin: '0 auto' }}>
					<Col span={3} style={{ paddingTop: 10 }}>
						<Checkbox
							style={{ marginLeft: 24 }}
							checked={el['pix']}
							onChange={(e) => {
								this.onPixChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Pixels</span>
						</Checkbox>
					</Col>
					<Col span={2} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['img']}
							onChange={(e) => {
								this.onImageChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Image</span>
						</Checkbox>
					</Col>
					<Col span={3} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['compress']}
							onChange={(e) => {
								this.onCompressChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Compress</span>
						</Checkbox>
					</Col>
					<Col span={2} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['link']}
							onChange={(e) => {
								this.onLinkChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Links</span>
						</Checkbox>
					</Col>
					<Col span={3} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['jumping']}
							onChange={(e) => {
								this.onJumpingChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Jumping</span>
						</Checkbox>
					</Col>
					<Col span={2} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['comment']}
							onChange={(e) => {
								this.onCommentChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Comment</span>
						</Checkbox>
					</Col>
					<Col span={3} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['stats']}
							onChange={(e) => {
								this.onStatsChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Stats</span>
						</Checkbox>
					</Col>
					<Col span={2} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['router']}
							onChange={(e) => {
								this.onRouterChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Router</span>
						</Checkbox>
					</Col>
					<Col span={2} style={{ paddingTop: 10 }}>
						<Checkbox
							checked={el['trigger']}
							onChange={(e) => {
								this.onTriggerChanged(e.target.checked, i)
							}}
						>
							<span style={{ fontSize: 14, fontWeight: 400 }}>Trigger</span>
						</Checkbox>
					</Col>
				</Row>
				<hr style={{ color: '#001529' }} />
			</Row>
		)
	}

	onOptimizeMultiple() {
		var old = []
		this.setState({ loading_multiple: true })
		this.state.multiple_list.map(item => {
			var content = {}
			content['database_id'] = item['database_id'] !== null ? item['database_id'] : ''
			content['schedulede_date'] = item['schedulede_date'] !== null ? moment(item['schedulede_date']).format('YYYY-MM-DD').toString() : ''
			content['segment'] = item['segment'] !== null ? item['segment'] : ''
			content['suppresssion'] = item['suppresssion'] !== null ? item['suppresssion'] : ''
			content['available_from'] = item['available_from'] !== null ? moment(item['available_from']).format('YYYY-MM-DD').toString() : ''
			content['available_until'] = item['available_until'] !== null ? moment(item['available_until']).format('YYYY-MM-DD').toString() : ''
			content['sender'] = item['sender'] !== null ? base64_encode(item['sender']) : ''
			content['subject'] = item['subject'] !== null ? base64_encode(item['subject']) : ''
			content['type'] = item['type'] !== null ? item['type'] : ''
			content['preheader'] = item['preheader'] !== null ? base64_encode(item['preheader']) : ''
			content['segments_liste'] = item['segments_liste']
			content['suppressions_liste'] = item['suppressions_liste']
			content['html_code'] = base64_encode(item['html_code'])
			content['pixel'] = item['pix'] === true ? 1 : 0
			content['img'] = item['img'] === true ? 1 : 0
			content['compress'] = item['compress'] === true ? 1 : 0
			content['link'] = item['link'] === true ? 1 : 0
			content['jumping'] = item['jumping'] === true ? 1 : 0
			content['comment'] = item['comment'] === true ? 1 : 0
			content['stats_status'] = item['stats'] === true ? 1 : 0
			content['router_status'] = item['router'] === true ? 1 : 0
			content['trigger'] = item['trigger'] === true ? 1 : 0
			old.push(content)
		})
		Promise.resolve(upload_multiple(this.state.user_id, this.state.apikey, this.state.crea_id, old, this.state.clientid, null)).then((value) => {
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		});
	}

	onOptimizeSpecificClient() {
		this.setState({ loading_multiple: true })
		var old = []
		this.state.multiple_list.map(item => {
			var content = {}
			content['database_id'] = item['database_id'] !== null ? item['database_id'] : ''
			content['schedulede_date'] = item['schedulede_date'] !== null ? moment(item['schedulede_date']).format('YYYY-MM-DD').toString() : ''
			content['segment'] = item['segment'] !== null ? item['segment'] : ''
			content['suppresssion'] = item['suppresssion'] !== null ? item['suppresssion'] : ''
			content['available_from'] = item['available_from'] !== null ? moment(item['available_from']).format('YYYY-MM-DD').toString() : ''
			content['available_until'] = item['available_until'] !== null ? moment(item['available_until']).format('YYYY-MM-DD').toString() : ''
			content['sender'] = item['sender'] !== null ? base64_encode(item['sender']) : ''
			content['subject'] = item['subject'] !== null ? base64_encode(item['subject']) : ''
			content['type'] = item['type'] !== null ? item['type'] : ''
			content['preheader'] = item['preheader'] !== null ? base64_encode(item['preheader']) : ''
			content['segments_liste'] = item['segments_liste']
			content['suppressions_liste'] = item['suppressions_liste']
			old.push(content)
		})
		Promise.resolve(upload_multiple(this.state.user_id, this.state.apikey, this.state.crea_id, old, this.state.clientid, 1)).then((value) => {
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		});

	}

	OnOnlymeChanged(e) {
		this.setState({ onlyme: e, displayLoading: 'block', displayTask: 'none' })
		Promise.resolve(getMainData(this.state.user_id, this.state.apikey, this.state.stats_apikey, this.state.Filter_limit, false, false, false, false, false, false, false, false, true, e))
			.then((value) => {
				this.setState({
					liste_creativities: value['all_crea'],
					displayLoading: 'none',
					displayTask: 'block'
				})
			})
	}

	async OnReuseClicked(e, item) {
		var seg = await this.getListSegments(item['baseid'])
		this.setState({
			show_reuse: true,
			crea_id: item['id'],
			user_id_creativity: item['user_id'],
			crea_username: item['username'],
			crea_base_id: item['baseid'],
			liste_segments: seg
		})
	}

	onReuseCreativity() {
		if ((this.state.crea_segment).length === 0) {
			message.warning('FIELD SEGMENT IS MANDATORY')
			return null
		} else {
			this.setState({ loadingReuse: true })
			Promise.resolve(reuse(this.state.user_id, this.state.apikey, this.state.crea_id, this.state.crea_base_id, this.state.crea_segment)).then(response => {
				setTimeout(() => {
					window.location.reload()
				}, 3000)
			})
		}


	}

	onCloseSegdwhInfo() {
		this.setState({
			show_new_seg_req: false,
			crea_id: null,
			crea_username: null,
			crea_base_id: null,
			dwh_stats_base_id: null,
			dwh_stats_sendoutid: null,
			dwh_tags_id: null,
			dwh_scheduled_on: null,
			dwh_model: null,
			dwh_country_id: null,
			dwh_scheduled_on: null,
			dwh_model: [],
			dwh_volume: null,
			dwh_domain_family: [],
			dwh_sexe: [],
			dwh_age: [],
			dwh_csp: [],
			dwh_score_of_landlords: [],
			dwh_score_of_individual_houses: [],
			dwh_score_of_tax_households: [],
			dwh_poverty_score: [],
			dwh_score_median_income: [],
			dwh_zipcode: null,
			dwh_optin_telemarketing: false,
			dwh_optin_sms: false,
			dwh_stats_base_id: null,
			dwh_stats_sendoutid: null,
			dwh_tags_id: null,
			dwh_country_id: null,
			show_seginfo: false,
			segdwhinfo: [],
			segdwhstatus: null,
			segdwhvolume: null,
			segdwhtimeleft: null,
			segdwhbasename: null,
			segdwhid: null,
			segdwhtagstatuscolor: null,
			segdwhcr: null,
			segdwhor: null,
			displayLoadingSegSwh: 'block',
			displaySegdwhinfo: 'none',
			segdwhrevr: null,
			segdwhage_data: null,
			segdwhageoptions: null,
			segdwhgender_data: null,
			segdwhgenderoptions: null,
			displayValidateButton: 'block',
			displayInvalidateButton: 'block',
			displayRequestNewButton: 'block',
		})
		this.getData()
	}

	renderDwhSegment(item) {
		if (item['dwh_status'] === 'WAITING') {
			message.warn('WAITING FOR SEGMENTS !!!', 2)
			return null
		}
		if (item['dwh_status'] === 'SEGMENT NOT CREATED' || item['dwh_status'] === null) {
			message.warn('SEGMENTS IS NOT CREATED, REQUEST NEW ONE', 2)
			setTimeout(() => {
				this.setState({
					show_new_seg_req: true,
					crea_id: item['id'],
					crea_username: item['username'],
					crea_base_id: item['baseid'],
					dwh_stats_base_id: item['statsID'],
					dwh_stats_sendoutid: item['stats_id'],
					dwh_tags_id: item['tags_id'],
					dwh_scheduled_on: moment(item['scheduledon']),
					dwh_model: item['models'],
					dwh_country_id: item['country']
				})
			}, 2000);
			return null
		}
		else {
			this.setState({ show_seginfo: true })
			Promise.resolve(get_segments_dwh(this.state.user_id, this.state.apikey, item['dwh_id'], item['id'], item['dwh_status'])).then(res => {
				if (res === null) {
					message.warning('Segments request failed')
					this.onCloseSegdwhInfo()
					this.getData()
					return null
				} else {
					if (res['status'] === 'INVALIDATE AFTER NO RESPONSE' || res['status'] === 'SEGMENT NOT CREATED') {
						console.log('debug 1')
						if (res['status'] === 'INVALIDATE AFTER NO RESPONSE') { message.warning('Segments was invalidated after no action', 3) }
						if (res['status'] === 'SEGMENT NOT CREATED') { message.warning('Segments was not created at the Datawarehouse', 3) }
						this.onCloseSegdwhInfo()
						this.getData()
						return null
					} else {
						if (res['status'] === 'PENDING') {
							console.log('debug 3')
							message.info('Segments creation pending !!!', 3)
							
							return null

							
						} else {
							if ("status" in res['data']) {
								message.info('Segments creation pending !!!', 3)
								this.setState({
									show_seginfo: false
								})
								return null
							} else {

								var display_validate = 'block'
								var display_invalidate = 'block'
								var display_request_new = 'block'
								var timeleft = res['timeleft']
								if (item['dwh_status'] === 'INVALIDATE AFTER NO RESPONSE' || item['dwh_status'] === 'INVALIDATE' || item['dwh_status'] === 'VALIDATE') {
									display_validate = 'none'
									display_invalidate = 'none'
									timeleft = ''
								}
								let color = 'blue'
								if (res['status'] !== 'READY') {
									color = 'gold'
								}
								var cr = (res['data']['click_rate'] * 100).toFixed(2) + '%';
								var or = (res['data']['open_rate'] * 100).toFixed(2) + '%';
								var rev = (res['data']['revenue']).toFixed(2) + '€';
								var count_18_25 = res['data']['nb_age_class_18_25']
								var count_26_35 = res['data']['nb_age_class_26_35']
								var count_36_45 = res['data']['nb_age_class_36_45']
								var count_46_55 = res['data']['nb_age_class_46_55']
								var count_56_65 = res['data']['nb_age_class_56_65']
								var count_65 = res['data']['nb_age_class_65_plus']
								var count_homme = res['data']['nb_gender_h']
								var count_femme = res['data']['nb_gender_f']
								var count_unknown = res['data']['nb_gender_unknown']
								var count_F1 = res['data']['nb_data_inno_seg_f1']
								var count_F2 = res['data']['nb_data_inno_seg_f2']
								var count_F3 = res['data']['nb_data_inno_seg_f3']
								var count_F4 = res['data']['nb_data_inno_seg_f4']
								var count_F5 = res['data']['nb_data_inno_seg_f5'] === undefined ? 0 : res['data']['nb_data_inno_seg_f5']
								var count_F6 = res['data']['nb_data_inno_seg_f6'] === undefined ? 0 : res['data']['nb_data_inno_seg_f6']
								var data_age =
									[
										["", ""],
										["18 - 25", count_18_25],
										["26 - 35", count_26_35],
										["36 - 45", count_36_45],
										["46 - 55", count_46_55],
										["56 - 65", count_56_65],
										["65 +", count_65]
									];
								var options_age = {
									title: "",
									is3D: true,
									style: "textalign: center"
								}
								var data_gender = [
									["", ""],
									["Homme", parseInt(count_homme)],
									["Femme", parseInt(count_femme)],
									["Unknown", parseInt(count_unknown)]
								]
								var options_gender = {
									is3D: true
								}

								var data_activity = [
									["", ""],
									["F1", parseInt(count_F1)],
									["F2", parseInt(count_F2)],
									["F3", parseInt(count_F3)],
									["F4", parseInt(count_F4)],
									["F5", parseInt(count_F5)],
									["F6", parseInt(count_F6)]
								]
								var options_activity = {
									title: "",
									is3D: true
								}

								this.setState({
									crea_id: item['id'],
									segdwhinfo: res['data'],
									segdwhstatus: res['status'],
									segdwhvolume: res['data']['volume'],
									segdwhtimeleft: res['timeleft'],
									segdwhbasename: item['name'],
									segdwhid: item['dwh_id'],
									segdwhtagstatuscolor: color,
									segdwhcr: cr,
									segdwhor: or,
									displayLoadingSegSwh: 'none',
									displaySegdwhinfo: 'block',
									segdwhrevr: rev,
									segdwhage_data: data_age,
									segdwhageoptions: options_age,
									segdwhgender_data: data_gender,
									segdwhgenderoptions: options_gender,
									segdwhactif_data: data_activity,
									segdwhactif_options: options_activity,
									displayValidateButton: display_validate,
									displayInvalidateButton: display_invalidate,
									displayRequestNewButton: display_request_new,
									dwh_scheduled_on: item['date'],
									dwh_model: item['models'],
									dwh_tags_id: item['tags_id'],
									dwh_country_id: item['country'],
								})
							}
						}
					}
				}

			})
		}
	}

	onRequestNewSegment() {
		this.setState({ loadingRequestNew: true })
		var tagsname = null
		var country = null
		var currency = null

		this.state.liste_all_country.map(item => {
			if (parseInt(item['id']) === parseInt(this.state.dwh_country_id)) {
				country = item['dwname']
				switch (item['currencyname']) {
					case 'Euro':
						currency = 'EURO'
						break;
					case 'US Dollar':
						currency = 'EURO'
						break;
					case 'Australian Dollar':
						currency = 'AUSTRALIAN_DOLLAR'
						break;
					case 'New Zealand Dollar':
						currency = 'NEW_ZELEAND_DOLLAR'
						break;
					case 'Pounds':
						currency = 'POUNDS'
						break;
					case 'Zloty':
						currency = 'ZLOTY'
						break;
					case 'Swedish Krona':
						currency = 'SWEDISH_KRONA'
						break;
					case 'Norwegian Kroner':
						currency = 'NORWEGIAN_KRONER'
						break;
					// case 'Mexican Peso':
					// 	currency = 'EURO'
					// 	break;				
					default:
						currency = 'EURO'
						break;
				}
			}
		})
		this.state.liste_all_tags.map(item => {
			if (parseInt(this.state.dwh_tags_id) === parseInt(item['id'])) {
				tagsname = item['dwtag']
			}
			return null
		})
		var data = {
			"creaid": this.state.crea_id,
			"tags": tagsname,
			"stats_base_id": this.state.dwh_stats_base_id,
			"stats_campaign_id": this.state.dwh_stats_sendoutid,
			"shoot_date": moment(this.state.dwh_scheduled_on).format(dateFormat),
			"revenue": this.state.dwh_model,
			"dwh_gender": JSON.stringify(this.state.dwh_sexe),
			"dwh_age_range": JSON.stringify(this.state.dwh_age),
			"dwh_csp": JSON.stringify(this.state.dwh_csp),
			"dwh_landlords": JSON.stringify(this.state.dwh_score_of_landlords),
			"dwh_individual_house": JSON.stringify(this.state.dwh_score_of_individual_houses),
			"dwh_tax_house": JSON.stringify(this.state.dwh_score_of_tax_households),
			"dwh_poverty": JSON.stringify(this.state.dwh_poverty_score),
			"dwh_median": JSON.stringify(this.state.dwh_score_median_income),
			"dwh_domain_family": JSON.stringify(this.state.dwh_domain_family),
			"dwh_zipcode": this.state.dwh_zipcode,
			"dwh_optin_telemarketing": this.state.dwh_optin_telemarketing === false ? 0 : 1,
			"dwh_optin_sms": this.state.dwh_optin_sms === false ? 0 : 1,
			"dwh_fed_now": this.state.dwh_fed_now === false ? 0 : 1,

			"volume": this.state.dwh_volume,
			"country": country,
			"currency": currency
		}
		Promise.resolve(request_segment(this.state.user_id, this.state.apikey, data)).then(res => {
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		})
	}

	onInvalidateSegments() {
		this.setState({ loadingInvalidate: true })
		console.log(this.state.crea_id)
		console.log(this.state.segdwhid)
		Promise.resolve(invalidate_segments_dwh(this.state.user_id, this.state.apikey, this.state.segdwhid, this.state.crea_id)).then(res => {
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		})
	}

	onRequestNewSegmentsUsingButton() {
		Promise.resolve(invalidate_segments_dwh(this.state.user_id, this.state.apikey, this.state.segdwhid, this.state.crea_id)).then(res => {
			console.log(('request done'))
		})
		message.warn('SEGMENTS IS INVALIDATED', 2)
		setTimeout(() => {
			this.setState({
				show_new_seg_req: true,
				show_seginfo: false,
			})
		}, 2000);
		return null

	}

	onValidateSegments() {
		this.setState({ loadingValidate: true })
		Promise.resolve(validate_segments_dwh(this.state.user_id, this.state.apikey, this.state.segdwhid, this.state.crea_id)).then(res => {
			// console.log(res.data)
			// if (res.data['status'] === 'Ok') {
			// 	message.success('Segments was validated and will be created at the router as lists', 3)
			// } else {
			// 	message.danger('Segments was not created at the router', 3)
			// }
			setTimeout(() => {
				this.onCloseSegdwhInfo()
			}, 2000)
		})

	}

	handlePageClick = (selectedPage) => {
		this.setState({ currentPage: selectedPage.selected });
	};

	render() {
		const { liste_creativities, currentPage, itemsPerPage } = this.state;
		const startIndex = currentPage * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		// var currentData = liste_creativities.slice(startIndex, endIndex) ? liste_creativities : [] ;
		const currentData = (liste_creativities ?? []).slice(startIndex, endIndex);

		const bg = {
			overlay: {
				backgroundColor: "rgba(0, 0, 0, .5)",
			}
		}

		const renderMenu = (item) => (
			<div style={{ width: 200, background: '#eee' }}>
				<div style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}>
					<button
						className="btn btn-warning"
						style={{ height: '30px', width: '60%', padding: 0, color: "#fff", fontSize: 13, marginBottom: 5 }}
						onClick={() => {
							var new_link = config.REACT_APP_ENDPOINT_CREA_PREVIEW + '=' + base64_encode(item['id'])
							window.open(new_link, '_blank')
						}}
					>
						<Row>
							<Col span={8}><EyeOutlined style={{ color: '#fff', fontSize: 15, marginTop: '0px', verticalAlign: 'baseline' }} /></Col>
							<Col span={16} style={{ textAlign: 'left' }}><span>Preview</span></Col>
						</Row>

					</button>
				</div>

				<div style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}>
					<button
						className="btn btn-primary"
						style={{ height: '30px', width: '60%', padding: 0, color: "#fff", fontSize: 13, marginBottom: 5 }}
						onClick={() => {
							var path = '/app/creativities/details/' + item['id']
							this.OnPreviewCreaDetails(path)
						}}
					>
						<Row>
							<Col span={8}><EditOutlined style={{ color: '#fff', fontSize: 15, marginTop: '0px', verticalAlign: 'baseline' }} /> </Col>
							<Col span={16} style={{ textAlign: 'left' }}><span>Edit</span></Col>
						</Row>

					</button>
				</div>

				<div style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}>
					<button
						className="btn btn-success"
						style={{ height: '30px', width: '60%', padding: 0, color: "#fff", fontSize: 12, marginBottom: 5 }}
						onClick={
							() => {
								this.setState({
									show_reoptimize: true,
									crea_id: item['id'],
									user_id_creativity: item['user_id'],
									crea_base_id: item['baseid'],
									crea_tags_id: item['tags_id'],
									crea_username: item['username']
								})
							}
						}
					>
						<Row>
							<Col span={8}><SyncOutlined style={{ color: '#fff', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} /> </Col>
							<Col span={16} style={{ textAlign: 'left' }}><span>Re-process</span></Col>
						</Row>
					</button>
				</div>

				<div style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}>
					<button
						className="btn btn-secondary"
						style={{ height: '30px', width: '60%', padding: 0, color: "#fff", fontSize: 12, marginBottom: 5 }}
						onClick={(e) => {
							this.OnReuseClicked(e, item)
						}}
					>
						<Row>
							<Col span={8}><RedoOutlined style={{ color: '#fff', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} /> </Col>
							<Col span={16} style={{ textAlign: 'left' }}><span>Reuse</span></Col>
						</Row>
					</button>
				</div>


				<div style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}>
					<button
						className="btn btn-dark"
						style={{ height: '30px', width: '60%', padding: 0, color: "#fff", fontSize: 12, marginBottom: 5 }}
						onClick={
							() => {
								this.setState({
									isClone: true,
									crea_id: item['id'],
								})
							}
						}
					>
						<Row>
							<Col span={8}><CopyOutlined style={{ color: '#fff', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} /> </Col>
							<Col span={16} style={{ textAlign: 'left' }}><span>Clone</span></Col>
						</Row>
					</button>
				</div>
				{/* <div style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}>
					<button
						className="btn btn-info"
						style={{ height: '30px', width: '60%', padding: 0, color: "#fff", fontSize: 12, marginBottom: 5 }}
						onClick={
							() => {
								this.setState({
									show_modal_multiple: true,
									crea_id: item['id'],
									clientid: item['clientid'],
									modal_title: 'MULTIPLE CREATIVITIES OPTIMIZATION WITH A SPECIFIC CLIENTS',
									show_simple_multiple_optimization: 'none',
									show_client_multiple_optimization: 'block',
									displayHtmlButton: 'none'
								})
							}
						}
					>
						<Row>
							<Col span={8}>
								<InfoCircleOutlined style={{ color: '#fff', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} />
							</Col>
							<Col span={16} style={{ textAlign: 'left' }}><span>Multiple</span></Col>
						</Row>
					</button>
				</div> */}
			</div>

		);

		if (this.state.isError === true) {
			return (<Redirect to={{
				pathname: '/app/cache/clear'
			}} />)
		}
		if (this.state.show_detail) { return (<Redirect to={this.state.show_detail_id} />) }
		if (this.state.shownew) { return (<Redirect to="/app/creativities/new" />) }
		if (this.state.isClone === true) { return (<Redirect to={{ pathname: "/app/creativities/new", state: { 'crea_id': this.state.crea_id } }} />) }

		return (
			<div style={{ width: '100%', height: '95vh', overflow: 'hidden', fontFamily: 'Gotham', fontSize: 18, marginTop: '-20px' }}>
				<Row style={{ color: '#fff', fontFamily: 'Lato', padding: 5, background: '#E5E6E7' }}>
					<Col span={2} style={{ padding: 2, textAlign: 'center' }}>
						<Button
							size="large"
							className='btn btn-primary'
							loading={this.state.loadingRefresh}
							style={{ width: '90%', color: '#fff', fontSize: 16 }}
							onClick={this.onRefreshPage.bind(this)}
						>
							Refresh
						</Button>
					</Col>
					<Col span={2} style={{ padding: 2 }}>
						<Button
							size="large"
							className="btn btn-success"
							style={{ width: '90%', fontSize: 16 }}
							onClick={() => {
								this.OnCreateNewCreativities()
							}}
						>
							Creativities +
						</Button>
					</Col>
					<Col span={1.5} style={{ padding: 8, textAlign: 'left' }}>
						<p style={{ color: 'black', fontSize: 18 }}>Filter by: </p>
					</Col>
					<Col span={3} style={{ padding: 2 }}>
						<Select
							size="large"
							style={{ width: '90%', textAlign: 'center' }}
							placeholder="Select filter"
							value={this.state.FilterValue}
							onChange={(value) => { this.onFilterSearch(value) }}
						>
							<Option value="id">Id</Option>
							<Option value="database">Database</Option>
							<Option style={{ display: `${this.state.displayAdmin}` }} value="user">User</Option>
							<Option value="creativity">Creativity</Option>
							<Option value="tags">Tags</Option>
							<Option value="date">Date</Option>
						</Select>
					</Col>
					{/* filter by id */}
					<Col span={6} style={{ padding: 3, display: `${this.state.DisplayFieldsId}` }}>
						<Input
							size="large"
							style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
							placeholder="Id of the creativities"
							value={this.state.FilterId}
							onChange={(e) => { this.setState({ FilterId: e.target.value }) }}
						/>
					</Col>
					{/* filter creativity */}
					<Col span={6} style={{ padding: 3, display: `${this.state.DisplayFieldsCreativities}` }}>
						<Input
							size="large"
							style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
							placeholder="Found by creativities name"
							value={this.state.FilterCrea}
							onChange={(e) => { this.setState({ FilterCrea: e.target.value }) }}
						/>
					</Col>
					{/* filter date */}
					<Col span={6} style={{ padding: 3, display: `${this.state.DisplayFieldsDate}` }}>
						<Row>
							<Col span={11}>
								<DatePicker
									size="large"
									placeholder="Start date"
									style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
									onChange={(i, j) => {
										this.setState({
											FilterStartDate: j
										})
									}}
								/>
							</Col>
							<Col span={1}></Col>
							<Col span={11}>
								<DatePicker
									size="large"
									placeholder="End date"
									style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
									onChange={(i, j) => {
										this.setState({
											FilterEndDate: j
										})
									}}
								/>
							</Col>
						</Row>
					</Col>
					{/* filter by tags */}
					<Col span={6} style={{ padding: 3, display: `${this.state.DisplayFieldsTags}` }}>
						<Select
							size="large"
							placeholder="Select tags"
							showSearch
							style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
							optionFilterProp="children"
							value={this.state.FilterTags}
							onChange={(value) => { this.setState({ FilterTags: value }) }}
						>
							{
								this.state.liste_all_tags && this.state.liste_all_tags.map(item => {
									var key = generateKey()
									return (
										<Option key={key} value={item.id}>{item.tag}</Option>
									)
								})
							}
						</Select>
					</Col>
					{/* filter by users */}
					<Col span={6} style={{ padding: 3, display: `${this.state.DisplayFieldsUser}` }}>
						<Select
							mode="multiple"
							size="large"
							placeholder="Select one or multiple users"
							showSearch
							style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
							optionFilterProp="children"
							value={this.state.FilterUser}
							onChange={(value) => { this.setState({ FilterUser: value }) }}
						>
							{
								this.state.liste_all_user && this.state.liste_all_user.map(item => {
									var key = generateKey()
									return (
										<Option key={key} value={item.id}>{item.username}</Option>
									)
								})
							}
						</Select>
					</Col>
					{/* filter by database */}
					<Col span={6} style={{ padding: 3, display: `${this.state.DisplayFieldsDatabase}` }}>
						<Select
							size="large"
							mode="multiple"
							placeholder="Select database"
							optionFilterProp="children"
							showSearch
							style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
							value={this.state.FilterBase}
							onChange={(value) => { this.setState({ FilterBase: value }) }}
						>
							{
								this.state.liste_base_user && this.state.liste_base_user.map(item => {
									var key = generateKey()
									return (
										<Option key={key} value={item.id}>{item['basename']}</Option>
									)
								})
							}
						</Select>
					</Col>
					<Col span={2} style={{ padding: 3 }}>
						<Select
							size='large'
							style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
							optionFilterProp="children"
							value={this.state.Filter_limit}
							onChange={(value) => { this.setState({ Filter_limit: value }) }}
						>
							<Option key="1" value="50">50</Option>
							<Option key="3" value="100">100</Option>
							<Option key="4" value="150">150</Option>
							<Option key="5" value="200">200</Option>
						</Select>
					</Col>
					<Col span={2} style={{ padding: 4 }}>
						<Button
							size='large'
							loading={this.state.loadingLoadMore}
							className="btn btn-dark"
							style={{ width: '100%', fontSize: 16 }}
							onClick={this.onSearch.bind(this)}
						>
							<span>
								Search
							</span>
						</Button>
					</Col>
					<Col span={3} style={{ padding: 8, textAlign: 'center' }}>
						<Row>
							<Col span={5} style={{ textAlign: 'center' }}>
								<span style={{ color: 'black' }}><strong>Me</strong></span>
							</Col>
							<Col span={10}>
								<Switch
									defaultChecked={this.state.onlyme}
									checked={this.state.onlyme}
									onChange={this.OnOnlymeChanged.bind(this)}
								/>
							</Col>
							<Col span={5} style={{ textAlign: 'center' }}>
								<span style={{ color: 'black' }}><strong>All</strong></span>
							</Col>
						</Row>

					</Col>
				</Row>
				<div style={{ textAlign: 'center', margin: '0 auto', width: '100%', height: '80.9vh', overflow: 'scroll', overflowX: 'hidden', overflowY: 'auto', display: `${this.state.displayLoading}` }}>
					<img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
				</div>
				<div style={{ width: '100%', display: `${this.state.displayTask}` }}>
					<Row style={{ color: '#fff', fontFamily: 'Lato', height: '82vh', overflow: 'scroll', overflowX: 'hidden' }}>
						<table className="table table-striped" style={{ fontSize: 14, fontFamily: 'Arial' }}>
							<thead className='bg-dark' style={{ zIndex: 100, color: '#fff' }}>
								<tr>
									<th style={{ textAlign: 'center', width: '5%' }}>Id</th>
									<th style={{ textAlign: 'center', width: '5%' }}>CreateDate</th>
									<th style={{ textAlign: 'center', width: '5%' }}>Id Sendout</th>
									<th style={{ textAlign: 'center', width: '5%' }}>Id Focus</th>
									<th style={{ textAlign: 'center', width: '5%' }}>Id Router</th>
									<th style={{ textAlign: 'center', width: '5%' }}>Tag</th>
									<th style={{ textAlign: 'center', width: '4%' }}>Id DWH</th>
									<th style={{ textAlign: 'center', width: '10%' }}>Database</th>
									<th style={{ textAlign: 'center', width: '5%' }}>User</th>
									<th style={{ textAlign: 'center', width: '5%' }}>Brand</th>
									{/* <th style={{ textAlign: 'center', width: '10%' }}>Creativities</th> */}
									<th style={{ textAlign: 'center', width: '5%' }}>Status</th>
									<th style={{ textAlign: 'center', width: '5%' }}>Action</th>
								</tr>
							</thead>
							<tbody>
								{
									currentData && currentData.map(item => {
										var key = generateKey()
										var date = new Date(Date.parse(item['date']))
										var day = date.getDate()
										var month = date.getMonth() + 1
										var year = date.getFullYear()
										var hours = date.getHours()
										var minutes = date.getMinutes()
										var second = date.getSeconds()
										var displayClientOptin = 'none'
										var displayReuseBtn = 'none'
										if (month < 10) { month = "0" + month.toString() }
										if (minutes < 10) { minutes = "0" + minutes.toString() }
										if (second < 10) { second = "0" + second.toString() }
										if (day < 10) { day = "0" + day.toString() }
										var date_optimization = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + second
										var displaycode = 'block'
										if (item['status'] === "Optimizing") {
											displaycode = 'none'
										}
										if (item['clientoptin'] === 1) {
											displayClientOptin = 'block'
										}
										if (item['stats_id'] !== null && item['stats_id'] !== "0") {
											displayReuseBtn = 'block'
										}
										var color = '#fff'
										if (parseInt(item['blacklist']) === 1) {
											console.log(('first'))
											color = '#FFC0CB'
										}
										var status = "running"
										var date_dwh = year + '-' + month + '-' + day
										var dwhid = null
										var dwhstatus = null
										var timeleft = null
										var tagcolordwh = "#173F5F"
										switch (item['dwh_status']) {
											case "WAITING":
												tagcolordwh = "blue"
												break;

											case "READY":
												tagcolordwh = "blue"
												break;
											case "PENDING":
												tagcolordwh = "blue"
												break;
											case "VALIDATE":
												tagcolordwh = "cyan"
												break;
											default:
												tagcolordwh = "volcano";
										}
										// 
										if (item['dwh_id'] === null) {
											dwhid = "0"
										} else {
											dwhid = item['dwh_id']
										}
										if (item['dwh_status'] === 'PENDING' || item['dwh_status'] === null) {
											dwhstatus = 'PENDING'
										} else {
											if (item['dwh_status'] === 'READY') {
												dwhstatus = 'READY'
												timeleft = item['dwh_timeleft']
											} else {
												if (item['dwh_status'] === 'VALIDATE') {
													dwhstatus = 'VALIDATE'
												} else {
													if (item['dwh_status'] === 'INVALIDATE AFTER NO RESPONSE') {
														dwhstatus = 'INVALIDATE AFTER NO RESPONSE'
													} else {
														if (item['dwh_status'] === 'INVALIDATE BY USER') {
															dwhstatus = 'INVALIDATE BY USER'
														}
													}
												}
											}
										}
										return (
											<tr key={key} style={{ backgroundColor: `${color}` }}>
												<td style={{ textAlign: 'center' }}>{item.id}</td>
												<td style={{ textAlign: 'center' }}>{date_optimization}</td>
												<td style={{ textAlign: 'center' }}><Tag color="#308787" style={{ textAlign: 'center', fontSize: 14 }}>{item['stats_id']}</Tag></td>
												<td style={{ textAlign: 'center' }}><Tag color="#108ee9" style={{ textAlign: 'center', fontSize: 14 }}>{item['focus_id']}</Tag></td>
												<td style={{ textAlign: 'center' }}><Tag color="#B6238A" style={{ textAlign: 'center', fontSize: 14 }}>{item['router_id']}</Tag></td>
												{/* <td style={{textAlign: 'center', width: '5%'}}><SegmentDWH id={item.dwh_id} status={item.dwh_status} creaid={item.id} timeleft={timeleft}/></td> */}
												<td style={{ textAlign: 'center', wordWrap: 'break-word' }}>
													{
														this.state.liste_all_tags && this.state.liste_all_tags.map(tags => {
															if (parseInt(item['tags_id']) === parseInt(tags['id'])) {
																var key1 = generateKey()
																return (
																	<Tag color="green" key={key1}>{tags['tag']}</Tag>
																)
															}
															return null
														})
													}
												</td>
												<td style={{ textAlign: 'center', width: '5%' }}>
													<Tag
														color={tagcolordwh}
														style={{ cursor: 'pointer' }}
														onClick={
															() => {
																console.log(item)
																this.renderDwhSegment(item)
															}
														}
													>
														{item['dwh_status']}
													</Tag>
												</td>
												<td style={{ textAlign: 'center' }}>{item.name}</td>
												<td style={{ textAlign: 'center' }}>{item.username}</td>
												<td style={{ textAlign: 'center', cursor: 'pointer' }} title={item.creativity}>{item.brand}</td>
												{/* <td style={{ textAlign: 'center' }}>{item.creativity}</td> */}
												<td style={{ textAlign: 'center' }}><Status status={item.status} trigger={item.trigger_status} bat={item.stats_campagn_status} /></td>
												<td style={{ textAlign: 'center' }}>
													<Dropdown overlay={renderMenu(item)} placement="bottom" style={{ margin: '0 auto', width: '100%' }}>
														<MenuUnfoldOutlined style={{ fontSize: 18, color: '#0069D9' }} />
														{/* <img src={dots} alt="action" style={{width: 50, height: 20}}/> */}
													</Dropdown>
													{/* <Row>
														<Col span={4} style={{ textAlign: 'center', display: `${displaycode}` }}>
															<Tooltip title="preview" placement="bottom">
																<button
																	className="btn btn-secondary-warning"
																	style={{ border: '1px solid #E0A800', height: 25, width: 25, padding: 0, marginBottom: 10 }}
																	onClick={() => {
																		var new_link = config.REACT_APP_ENDPOINT_CREA_PREVIEW + '=' + base64_encode(item['id'])
																		window.open(new_link, '_blank')
																	}}
																>
																	<EyeOutlined style={{ color: '#E0A800', fontSize: 15, marginTop: '0px', verticalAlign: 'baseline' }} />
																</button>
															</Tooltip>
														</Col>
														<Col span={4} style={{ textAlign: 'center', display: `${displaycode}` }}>
															<Tooltip title="edit" placement="bottom">
																<button
																	className="btn btn-secondary-primary"
																	style={{ border: '1px solid #1890FF', height: 25, width: 25, padding: 0, marginBottom: 10 }}
																	onClick={() => {
																		var path = '/app/creativities/details/' + item['id']
																		this.OnPreviewCreaDetails(path)
																	}}
																>
																	<EditOutlined style={{ color: '#1890FF', fontSize: 15, marginTop: '0px', verticalAlign: 'baseline' }} />
																</button>
															</Tooltip>
														</Col>
														
														<Col span={4} style={{ textAlign: 'center', display: `${displayReuseBtn}` }}>
															<Tooltip title="Reuse" placement="bottom">
																<button
																	className="btn btn-outline-success"
																	style={{ border: '1px solid #138496', height: 25, width: 25, padding: 0, marginBottom: 10 }}
																	onClick={(e) => {
																		this.OnReuseClicked(e, item).bind(this)
																	}}
																// onClick={
																// 	() => {
																// 		this.setState({
																// 			show_reuse: true,
																// 			crea_id: item['id'],
																// 			user_id_creativity: item['user_id'],
																// 			crea_username: item['username'],
																// 			crea_base_id: item['baseid'],
																// 			liste_segments: seg
																// 		})
																// 	}
																// }
																>
																	<RedoOutlined style={{ color: 'black', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} />
																</button>
															</Tooltip>
														</Col>
														<Col span={4} style={{ textAlign: 'center' }}>
															<Tooltip title="clone" placement="bottom">
																<button
																	className="btn btn-secondary-light"
																	style={{ border: '1px solid #23272B', height: 25, width: 25, padding: 0, marginBottom: 10 }}

																	onClick={
																		() => {
																			this.setState({
																				isClone: true,
																				crea_id: item['id'],
																			})
																		}
																	}
																>
																	<CopyOutlined style={{ color: '#23272B', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} />
																</button>
															</Tooltip>
														</Col>
														<Col span={4} style={{ textAlign: 'center' }}>
															<Tooltip title="Multiple optimization" placement="bottom">
																<button
																	className="btn btn-secondary-light"
																	style={{ border: '1px solid #23272B', height: 25, width: 25, padding: 0, marginBottom: 10 }}
																	onClick={
																		() => {
																			this.setState({
																				show_modal_multiple: true,
																				crea_id: item['id'],
																				clientid: item['clientid'],
																				show_simple_multiple_optimization: 'block',
																				show_client_multiple_optimization: 'none',
																				modal_title: 'MULTIPLE CREATIVITIES OPTIMIZATION',
																				displayHtmlButton: 'block'

																			})
																		}
																	}
																>
																	<BuildOutlined style={{ color: '#23272B', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} />
																</button>
															</Tooltip>
														</Col>
														<Col span={4} style={{ textAlign: 'center', display: `${displayClientOptin}` }}>
															<Tooltip title="Multiple optimization with specific client" placement="bottom">
																<button
																	className="btn btn-outline-warning"
																	style={{ border: '1px solid #E0A800', height: 25, width: 25, padding: 0, marginBottom: 10 }}
																	onClick={
																		() => {
																			this.setState({
																				show_modal_multiple: true,
																				crea_id: item['id'],
																				clientid: item['clientid'],
																				modal_title: 'MULTIPLE CREATIVITIES OPTIMIZATION WITH A SPECIFIC CLIENTS',
																				show_simple_multiple_optimization: 'none',
																				show_client_multiple_optimization: 'block',
																				displayHtmlButton: 'none'
																			})
																		}
																	}
																>
																	<InfoCircleOutlined style={{ color: '#E0A800', fontSize: 15, marginTop: '0px', display: "block", verticalAlign: 'baseline' }} />
																</button>
															</Tooltip>
														</Col>
													</Row> */}
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</Row>
					<div className="pagination-container">
						<ReactPaginate
							previousLabel={'Previous'}
							nextLabel={'Next'}
							breakLabel={'...'}
							// pageCount={Math.ceil(liste_creativities.length / itemsPerPage)}
							pageCount={(Math.ceil((liste_creativities?.length ?? 0) / itemsPerPage))}
							marginPagesDisplayed={3}
							pageRangeDisplayed={6}
							onPageChange={this.handlePageClick}
							containerClassName={'pagination'}
							activeClassName={'active'}
							style={{ textAlign: 'center', float: 'right' }}
						/>
					</div>


				</div>


				<Modal
					id="newreo"
					isOpen={this.state.show_reoptimize}
					onRequestClose={() => { this.setState({ show_reoptimize: false }) }}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>REOPTIMIZE CREATIVITY</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-48px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_reoptimize: false }) }}
						>
							X
						</span>
					</div>
					<div style={{ width: '95%', padding: 0 }}>
						<Row>
							<Col span={5} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_pixels}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_pixels: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_pixels: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Pixels</span>
								</Checkbox>
							</Col>
							<Col span={5} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_img}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_img: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_img: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Image</span>
								</Checkbox>
							</Col>
							<Col span={5} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_compress}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_compress: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_compress: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Compress</span>
								</Checkbox>
							</Col>

							<Col span={4} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_link}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_link: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_link: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Link</span>
								</Checkbox>
							</Col>
							<Col span={5} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_jumping}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_jumping: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_jumping: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Jumping</span>
								</Checkbox>
							</Col>
						</Row>
						<Row>
							<Col span={5} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_comment}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_comment: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_comment: e.target.checked })
										}
									}}
									style={{ marginLeft: 10 }}
								>
									<span style={{ fontSize: 14 }}>Comment</span>
								</Checkbox>
							</Col>

							<Col span={8} style={{ textAlign: 'center' }}>
								<Checkbox
									style={{ marginLeft: '-20px' }}
									checked={this.state.crea_reoptimize_trigger}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({
												crea_reoptimize_trigger: e.target.checked,
											})
										} else {
											this.setState({
												crea_reoptimize_trigger: e.target.checked,
											})
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Trigger</span>
								</Checkbox>
							</Col>
						</Row>
						<br />
						<div
							style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}
						>
							<Button
								loading={this.state.loadingReoptimize}
								className="btn btn-success"
								style={{ width: '50%', height: 40, fontFamily: 'Lato', fontWeight: 600, fontSize: 16 }}
								onClick={this.onReoptimizingRequests.bind(this)}
							>
								REOPTIMIZE
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					id="newmultiple"
					isOpen={this.state.show_modal_multiple}
					onRequestClose={this.onCloseModalMultiple.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>{this.state.modal_title}</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-48px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={this.onCloseModalMultiple.bind(this)}
						>
							X
						</span>
					</div>
					<div style={{ width: '100%', padding: 0, height: '70vh', overflowX: 'hidden', overflowY: 'auto' }}>
						<div style={{ width: '100%', padding: 0, height: '60vh', overflowX: 'hidden', overflowY: 'auto' }}>
							{this.renderMultipleDatabase()}
						</div>
						<br />
						<div>
							<Row>
								<Col span={12} style={{ textAlign: 'center' }}>
									<Button
										className="btn btn-secondary"
										style={{ width: '60%', height: 50, fontSize: 16 }}
										onClick={this.onCloseModalMultiple.bind(this)}
									>
										CANCEL
									</Button>
								</Col>
								<Col span={12} style={{ textAlign: 'center', display: `${this.state.show_simple_multiple_optimization}` }}>
									<Button
										loading={this.state.loading_multiple}
										className="btn btn-success"
										style={{ width: '60%', height: 50, fontSize: 16 }}
										onClick={this.onOptimizeMultiple.bind(this)}
									>
										SUBMIT
									</Button>
								</Col>
								<Col span={12} style={{ textAlign: 'center', display: `${this.state.show_client_multiple_optimization}` }}>
									<Button
										loading={this.state.loading_clients}
										className="btn btn-success"
										style={{ width: '60%', height: 50, fontSize: 16 }}
										onClick={this.onOptimizeSpecificClient.bind(this)}
									>
										SUBMIT
									</Button>
								</Col>
							</Row>
						</div>
					</div>
				</Modal>

				<Modal
					id="newHtml"
					isOpen={this.state.showHtmlModal}
					onRequestClose={this.onCloseModalHtml.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>HTML EDITOR</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-48px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={this.onCloseModalHtml.bind(this)}
						>
							X
						</span>
					</div>
					<div style={{ width: '100%', padding: 0, height: '43vh', overflowX: 'hidden', overflowY: 'auto' }}>
						{/* <AceEditor
							mode="html"
							theme="eclipse"
							placeholder="Paste code here"
							style={{ width: '100%', height: '35vh', border: '1px solid gray', marginTop: '0px' }}
							value={this.state.crea_temp_code}
							onChange={(value) => {
								this.setState({ crea_temp_code: value })
							}}
							setOptions={{
								displayIndentGuides: false,
								enableBasicAutocompletion: true,
								enableLiveAutocompletion: true,
								enableSnippets: true,
								wrap: true,
								fontSize: 13
							}}
						/> */}
						<Editor
							height="35vh"
							defaultLanguage="html"
							theme="vs-dark"
							defaultValue={this.state.crea_temp_code}
							style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
							onChange={(value) => {
								this.setState({ crea_temp_code: value })
							}}
						/>
						<br />
						<Row>
							<Col span={11} style={{ textAlign: 'center' }}>
								<Button
									className="btn btn-secondary"
									style={{ width: "50%", margin: '0 auto', fontSize: 15, height: 50 }}
									onClick={this.onCloseModalHtml.bind(this)}
								>
									CANCEL
								</Button>
							</Col>
							<Col span={2}></Col>
							<Col span={11} style={{ textAlign: 'center' }}>
								<Button
									className="btn btn-primary"
									style={{ width: "50%", margin: '0 auto', fontSize: 15, height: 50, textAlign: 'center' }}
									onClick={this.onValidateHtml.bind(this)}
								>
									SUBMIT
								</Button>
							</Col>
						</Row>
					</div>
				</Modal>

				<Modal
					id="newreuse"
					isOpen={this.state.show_reuse}
					onRequestClose={() => { this.setState({ show_reuse: false }) }}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>REUSE CREATIVITY</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-48px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_reuse: false }) }}
						>
							X
						</span>
					</div>
					<div style={{ width: '95%', padding: 10 }}>
						<div style={{ width: '80%', margin: '0 auto' }}>
							<Row>
								<Col span={6} style={{ padding: 3, textAlign: 'center' }}>
									<span>Database(<strong style={{ color: 'red' }}>*</strong>)</span>
								</Col>
								<Col span={18}>
									<Select
										disabled
										mode="multiple"
										optionFilterProp="children"
										showSearch
										size="medium"
										style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
										value={this.state.crea_base_id}
									>
										{
											this.state.liste_base_user && this.state.liste_base_user.map(item => {
												var key = generateKey()
												return (
													<Option key={key} value={item.id}>{item['basename']}</Option>
												)
											})
										}
									</Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col span={6} style={{ padding: 3, textAlign: 'center' }}>
									<span>Segments(<strong style={{ color: 'red' }}>*</strong>)</span>
								</Col>
								<Col span={18}>
									<Select
										mode="multiple"
										optionFilterProp="children"
										showSearch
										size="medium"
										style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
										value={this.state.crea_segment}
										onChange={(value) => {
											this.setState({
												crea_segment: value
											})
										}}
									>
										{
											this.state.liste_segments && this.state.liste_segments.map(item => {
												var key = generateKey()
												return (
													<Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
												)
											})
										}
									</Select>
								</Col>
							</Row>
							<br />
							<br />
							<Row>
								<Col span={6}></Col>
								<Col span={18} style={{ textAlign: 'center' }}>
									<div style={{ width: '70%', margin: '0 auto' }}>
										<Button
											loading={this.state.loadingReuse}
											className="btn btn-success"
											style={{ width: '75%', height: 50, fontSize: 16 }}
											onClick={this.onReuseCreativity.bind(this)}
										>
											REUSE
										</Button>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Modal>

				<Modal
					id="seginfo"
					isOpen={this.state.show_seginfo}
					onRequestClose={this.onCloseSegdwhInfo.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>SEGMENTS DETAILS</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-38px', fontSize: 25, float: 'right', cursor: 'pointer' }} onClick={this.onCloseSegdwhInfo.bind(this)}
						>
							X
						</span>
					</div>
					<div style={{ width: '100%' }}>
						<div style={{ width: '98%', height: '44vh', overflow: 'none', padding: 5 }}>
							<div style={{ display: `${this.state.displayLoadingSegSwh}`, width: '100%', height: '44vh', textAlign: 'center' }}>
								<img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
							</div>
							<div style={{ display: `${this.state.displaySegdwhinfo}`, width: '100%', height: '100%', textAlign: 'center' }}>
								<Row>
									<Col span={8}>
										<table className='table table-bordered' style={{ height: '100%' }}>
											<tbody>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>List</td>
													<td style={{ verticalAlign: 'middle' }}>{this.state.segdwhid}</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>Basename</td>
													<td style={{ verticalAlign: 'middle' }}>{this.state.segdwhbasename}</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>Volume</td>
													<td style={{ verticalAlign: 'middle' }}>{this.state.segdwhvolume}</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>Estimate Revenue</td>
													<td style={{ verticalAlign: 'middle' }}>
														<Tag color="green">
															{this.state.segdwhrevr}
														</Tag>
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>Estimate CR</td>
													<td style={{ verticalAlign: 'middle' }}>
														<Tag color="geekblue">
															{this.state.segdwhcr}
														</Tag>
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>Estimate OR</td>
													<td style={{ verticalAlign: 'middle' }}>
														<Tag color="purple">
															{this.state.segdwhor}
														</Tag>
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>Status</td>
													<td style={{ verticalAlign: 'middle' }}>
														<Tag color={this.state.segdwhtagstatuscolor}>
															{this.state.segdwhstatus}
														</Tag>
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: 'middle' }}>Time left</td>
													<td style={{ verticalAlign: 'middle' }}>{this.state.segdwhtimeleft}</td>
												</tr>
											</tbody>
										</table>

									</Col>

									<Col span={16}>
										<Row>
											<Col span={12}>
												{/* <div style={{border: '1px solid red', height: '30vh', overflow: 'scroll'}}> */}
												<table className='table table-bordered' style={{ height: '100%', width: '100%' }}>
													<thead>
														<tr style={{ height: 61 }}>
															<th style={{ verticalAlign: 'middle' }}>AGE DISTRIBUTION</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td style={{ padding: '0 !important' }}>
																<Chart
																	chartType="PieChart"
																	data={this.state.segdwhage_data}
																	options={this.state.segdwhageoptions}
																	// width={"100%"}
																	// height={"400px"}
																	style={{ padding: 0 }}
																/>
															</td>
														</tr>
													</tbody>
												</table>
												{/* </div> */}
											</Col>

											<Col span={12}>
												{/* <div style={{border: '1px solid red', height: '30vh', overflow: 'scroll'}}> */}
												<table className='table table-bordered' style={{ height: '100%', width: '100%' }}>
													<thead>
														<tr style={{ height: 61 }}>
															<th style={{ verticalAlign: 'middle' }}>GENDER DISTRIBUTION</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<Chart
																	chartType="PieChart"
																	data={this.state.segdwhgender_data}
																	options={this.state.segdwhgenderoptions}
																// width={"100%"}
																// height={"400px"}
																/>
															</td>
														</tr>
													</tbody>
												</table>
												{/* </div> */}
											</Col>
											<Col span={12}>
												<table className='table table-bordered' style={{ height: '100%', width: '100%' }}>
													<thead>
														<tr style={{ height: 61 }}>
															<th style={{ verticalAlign: 'middle' }}>ACTIVITIES DISTRIBUTION</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<Chart
																	chartType="ColumnChart"
																	data={this.state.segdwhactif_data}
																	options={this.state.segdwhactif_options}
																	// width={"100%"}
																	height={"100%"}
																/>
															</td>
														</tr>
													</tbody>
												</table>
											</Col>
											<Col span={12}>
												<table className='table table-bordered' style={{ height: '100%', width: '100%' }}>
													<thead>
														<tr style={{ height: 61 }}>
															<th style={{ verticalAlign: 'middle' }}>ACTIVITIES INFORMATION</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<div style={{ padding: 0 }}>
																	<Alert message={<div style={{ textAlign: 'left' }}><strong>F1 :</strong><span>Clickers in the last 3 months</span></div>} type="success" style={{ marginBottom: 5 }} />
																	<Alert message={<div style={{ textAlign: 'left' }}><strong>F2 :</strong><span>Openers less than one month (last 30 days)</span></div>} type="info" style={{ marginBottom: 5 }} />
																	<Alert message={<div style={{ textAlign: 'left' }}><strong>F3 :</strong><span>Openers the last 3 months</span></div>} type="warning" style={{ marginBottom: 5 }} />
																	<Alert message={<div style={{ textAlign: 'left' }}><strong>F4 :</strong><span>Active + 3 months (last 36 months)</span></div>} type="error" style={{ marginBottom: 5 }} />
																	<Alert message={<div style={{ textAlign: 'left' }}><strong>F5 :</strong><span>Inactives that haved received at least 1 unopened message (last 36 months)</span></div>} type="success" style={{ marginBottom: 5 }} />
																	<Alert message={<div style={{ textAlign: 'left' }}><strong>F6 :</strong><span>never received a message (last 36 months)</span></div>} type="info" style={{ marginBottom: 5 }} />

																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</Col>
										</Row>
									</Col>
								</Row>
								<br />
								<br />
								<Row>
									<Col span={8} style={{ display: `${this.state.displayInvalidateButton}` }}>
										<Button
											loading={this.state.loadingInvalidate}
											className='btn btn-danger'
											style={{ width: '80%', textAlign: 'center', height: 50 }}
											onClick={this.onInvalidateSegments.bind(this)}
										>
											INVALIDATE
										</Button>
									</Col>
									<Col span={8} style={{ display: `${this.state.displayRequestNewButton}` }}>
										<Button
											loading={this.state.loadingRequestNew}
											className='btn btn-warning'
											style={{ width: '80%', textAlign: 'center', height: 50 }}
											onClick={this.onRequestNewSegmentsUsingButton.bind(this)}
										>
											REQUEST NEW SEGMENTS
										</Button>
									</Col>
									<Col span={8} style={{ display: `${this.state.displayValidateButton}` }}>
										<Button
											loading={this.state.loadingValidate}
											className='btn btn-success'
											style={{ width: '80%', textAlign: 'center', height: 50 }}
											onClick={this.onValidateSegments.bind(this)}
										>
											VALIDATE SEGMENT
										</Button>
									</Col>
								</Row>
								<Row>


								</Row>

								{/* <AceEditor
                                        mode="json"
                                        theme="eclipse"
                                        placeholder="Paste code here"
                                        style={{ width: '100%', border: '1px solid gray', marginTop: '0px', height: '100%' }}
                                        value={JSON.stringify(this.state.segdwhinfo, null, '\t')}
                                        // onChange={(value) => {
                                        //     this.setState({ crea_code: value })
                                        // }}
                                        setOptions={{
                                            displayIndentGuides: false,
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            wrap: true,
                                            fontSize: 13
                                        }}
                                    /> */}
								{/* <Editor
									height="35vh"
									defaultLanguage="html"
									theme="vs-dark"
									defaultValue={this.state.crea_code}
									style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
									onChange={(value) => {
										this.setState({ crea_code: value })
									}}
								/> */}
							</div>
						</div>

					</div>
				</Modal>

				<Modal
					id="newsegreq"
					isOpen={this.state.show_new_seg_req}
					onRequestClose={this.onCloseSegdwhInfo.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>REQUEST NEW SEGMENT</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-38px', fontSize: 25, float: 'right', cursor: 'pointer' }} onClick={this.onCloseSegdwhInfo.bind(this)}
						>
							X
						</span>
					</div>
					<div style={{ width: '100%' }}>
						<div style={{ width: '98%', height: '44vh', overflow: 'none', padding: 10 }}>
							<Row>
								<Col span={11}>
									<DatePicker
										format={dateFormat}
										style={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
										value={this.state.dwh_scheduled_on}
										showTime
										onChange={(a, b) => {
											this.setState({ dwh_scheduled_on: a })
										}}
									/>
								</Col>
							</Row>
							<br />
							<Row>
								<Col span={11}>
									<Input
										placeholder='Maximum volume to request'
										size="large"
										style={{ width: '100%', margin: '0 auto' }}
										type="text"
										value={this.state.dwh_volume}
										onChange={(e) => {
											this.setState({
												dwh_volume: e.target.value
											})
										}}
									/>
								</Col>
								<Col span={2}></Col>
								<Col span={11}>
									<Select
										placeholder="Domain family"
										mode="multiple"
										size="large"
										showSearch
										value={this.state.dwh_domain_family}
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											var hasVal = Object.values(value).includes("all");
											if (hasVal) {
												var all = ["apple", "free", "gmail", "laposte", "sfr", "orange", "outlook", "yahoo", "bouygues", "others"]
												this.setState({
													dwh_domain_family: all
												})
												return null
											} else {
												this.setState({
													dwh_domain_family: value
												})
											}
										}}
									>
										<Option value="all">------all------</Option>
										<Option value="apple">Apple</Option>
										<Option value="free">Free</Option>
										<Option value="gmail">Gmail</Option>
										<Option value="laposte">Laposte</Option>
										<Option value="orange">Orange</Option>
										<Option value="outlook">Outlook</Option>
										<Option value="sfr">Sfr</Option>
										<Option value="yahoo">Yahoo</Option>
										<Option value="bouygues">Bouygues</Option>
										<Option value="others">Others</Option>

									</Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col span={11}>
									<Select
										size="large"
										placeholder="Gender"
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										value={this.state.dwh_sexe}
										// onChange={(value) => {
										// 	this.setState({
										// 		dwh_sexe: value
										// 	})
										// }}
										onChange={(value) => {
											var hasVal = Object.values(value).includes("all");
											if (hasVal) {
												var all = ["M","F","O"]
												this.setState({
													dwh_sexe: all
												})
												return null
											} else {
												this.setState({
													dwh_sexe: value
												})
											}
										}}
									>
										<Option value="all">------all------</Option>
										<Option value="M">Homme</Option>
										<Option value="F">Femme</Option>
										<Option value="O">Unknown</Option>
									</Select>
								</Col>
								<Col span={2}></Col>
								<Col span={11}>
									<Select
										size="large"
										placeholder="Age range"
										mode="multiple"
										value={this.state.dwh_age}
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											var hasVal = Object.values(value).includes("all");
											if (hasVal) {
												var all = ["18","18-24","25-34","35-44","45-54","55-64","65-74","75","O"]
												console.log("*******************")
												console.log(all)
												console.log("*******************")
												this.setState({
													dwh_age: all
												})
												return null
											} else {
												this.setState({
													dwh_age: value
												})
											}
										}}
									>
										<Option value="all">------all------</Option>
										<Option value="18">18</Option>
										<Option value="18-24">18-24</Option>
										<Option value="25-34">25-34</Option>
										<Option value="35-44">35-44</Option>
										<Option value="45-54">45-54</Option>
										<Option value="55-64">55-64</Option>
										<Option value="65-74">65-74</Option>
										<Option value="75">75</Option>
										<Option value="O">Unknown</Option>

									</Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col span={11}>
									<Select
										size="large"
										placeholder="CSP score"
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											this.setState({
												dwh_csp: value
											})
										}}
									>
										<Option value="0">0</Option>
										<Option value="1">1</Option>
										<Option value="2">2</Option>
										<Option value="3">3</Option>
										<Option value="4">4</Option>
										<Option value="5">5</Option>

									</Select>
								</Col>
								<Col span={2}></Col>
								<Col span={11}>
									<Select
										size="large"
										placeholder="Landlords score"
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											this.setState({
												dwh_score_of_landlords: value
											})
										}}
									>
										<Option value="0">0</Option>
										<Option value="1">1</Option>
										<Option value="2">2</Option>
										<Option value="3">3</Option>

									</Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col span={11}>
									<Select
										size="large"
										placeholder="Individual houses score"
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											this.setState({
												dwh_score_of_individual_houses: value
											})
										}}
									>
										<Option value="0">0</Option>
										<Option value="1">1</Option>
										<Option value="2">2</Option>
										<Option value="3">3</Option>
									</Select>
								</Col>
								<Col span={2}></Col>
								<Col span={11}>
									<Select
										size="large"
										placeholder="Tax households score"
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											this.setState({
												dwh_score_of_tax_households: value
											})
										}}
									>
										<Option value="0">0</Option>
										<Option value="1">1</Option>
										<Option value="2">2</Option>
										<Option value="3">3</Option>

									</Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col span={11}>
									<Select
										size="large"
										placeholder="Poverty score"
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											this.setState({
												dwh_poverty_score: value
											})
										}}
									>
										<Option value="0">0</Option>
										<Option value="1">1</Option>
										<Option value="2">2</Option>
										<Option value="3">3</Option>
									</Select>
								</Col>
								<Col span={2}></Col>
								<Col span={11}>
									<Select
										size="large"
										placeholder="Median income score"
										mode="multiple"
										showSearch
										optionFilterProp="children"
										style={{ width: '100%', color: '#192a56' }}
										onChange={(value) => {
											this.setState({
												dwh_score_median_income: value
											})
										}}
									>
										<Option value="0">0</Option>
										<Option value="1">1</Option>
										<Option value="2">2</Option>
										<Option value="3">3</Option>

									</Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col span={11}>
									<TextArea
										placeholder='zipcode liste, use comma (,) as separator'
										style={{ width: '100%', resize: 'none', color: '#192a56' }}
										value={this.state.dwh_zipcode}
										defaultValue={this.state.dwh_zipcode}
										onChange={
											(e) => {
												this.setState({
													dwh_zipcode: e.target.value
												})
											}
										}
									/>
								</Col>
								<Col span={2}></Col>
								<Col span={11}>
									<Row>
										<Col span={10}>
											<span>Optin Telemarketing:</span>
											<br />
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											<Switch
												defaultChecked={this.state.dwh_optin_telemarketing}
												checked={this.state.dwh_optin_telemarketing}
												onChange={(e) => {
													if (e === true) {
														this.setState({
															dwh_optin_telemarketing: e,
														})
													} else {
														this.setState({
															dwh_optin_telemarketing: e,
														})
													}
												}}
											/>
										</Col>
										<Col span={7}>
											<span>Optin SMS:</span>
											<br />
											&nbsp;&nbsp;&nbsp;<Switch
												defaultChecked={this.state.dwh_optin_sms}
												checked={this.state.dwh_optin_sms}
												onChange={(e) => {
													if (e === true) {
														this.setState({
															dwh_optin_sms: e,
														})
													} else {
														this.setState({
															dwh_optin_sms: e,
														})
													}
												}}
											/>
										</Col>
										<Col span={7}>
											<span>Fed now:</span>
											<br />
											&nbsp;&nbsp;&nbsp;<Switch
												defaultChecked={this.state.dwh_fed_now}
												checked={this.state.dwh_fed_now}
												onChange={(e) => {
													if (e === true) {
														this.setState({
															dwh_fed_now: e,
														})
													} else {
														this.setState({
															dwh_fed_now: e,
														})
													}
												}}
											/>
										</Col>
									</Row>

								</Col>
							</Row>
							<br />
							<br />
							<Row>
								<Col span={10} style={{ textAlign: 'center' }}>
									<Button
										className='btn btn-secondary'
										style={{ width: '70%', height: 50 }}
										onClick={this.onCloseSegdwhInfo.bind(this)}
									>
										CANCEL
									</Button>
								</Col>
								<Col span={4}></Col>
								<Col span={10} style={{ textAlign: 'center' }}>
									<Button
										loading={this.state.loadingRequestNew}
										className='btn btn-success'
										style={{ width: '70%', height: 50 }}
										onClick={this.onRequestNewSegment.bind(this)}
									>
										REQUEST NEW SEGMENT
									</Button>
								</Col>

							</Row>
						</div>

					</div>
				</Modal>

			</div>
		)
	}
}


export default Task