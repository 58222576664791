import React, { useState } from "react";
import { Select, InputNumber, Button, Space, Row, Col } from "antd";
import { PlusOutlined, MinusCircleOutlined, ReloadOutlined } from "@ant-design/icons";

const { Option } = Select;

const Score = ({ scoreList, onChange }) => {
    const [scores, setScores] = useState([{ name: null, status: null, value: null }]);
    const [displayReinitialize, setdisplayReinitialize] = useState(true)
    const addRow = () => {
        const updatedScores = [...scores, { name: null, status: null, value: null }];
        setScores(updatedScores);
        onChange(updatedScores);
    };

    const reload = () => {
        setScores([{ name: null, status: null, value: null }])
    };

    const removeRow = (index) => {
        const updatedScores = scores.filter((_, i) => i !== index);
        setScores(updatedScores);
        onChange(updatedScores);
    };

    const handleChange = (index, field, value) => {
        const updatedScores = [...scores];
        updatedScores[index][field] = value;
        setScores(updatedScores);
        onChange(updatedScores);
    };

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={14}>
                    <Row>
                        {scores.map((row, index) => (
                            <Col span={24} key={index}>
                                <Space align="baseline" style={{ display: "flex", marginBottom: "8px" }}>
                                    <Select
                                        showSearch
                                        placeholder="Choisir un score"
                                        style={{ width: 300 }}
                                        onChange={(value) => handleChange(index, "name", value)}
                                        value={row.name}
                                        filterOption={(input, option) =>
                                            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                        mode="combobox"
                                    >
                                        {scoreList.map((score) => (
                                            <Option key={score} value={score}>
                                                {score}
                                            </Option>
                                        ))}
                                    </Select>

                                    <Select
                                        placeholder="Action ..."
                                        style={{ width: 150 }}
                                        onChange={(value) => handleChange(index, "status", value)}
                                        value={row.status}


                                    >
                                        <Option value=">">{">"}</Option>
                                        <Option value="<">{"<"}</Option>
                                        <Option value=">=">{">="}</Option>
                                        <Option value="<=">{"<="}</Option>
                                        <Option value="=">{"="}</Option>
                                    </Select>

                                    <InputNumber
                                        placeholder="Valeur"
                                        onChange={(value) => handleChange(index, "value", value)}
                                        value={row.value}
                                        style={{ width: 80 }}
                                    />

                                    {scores.length > 1 && (
                                        <MinusCircleOutlined
                                            onClick={() => removeRow(index)}
                                            style={{ color: "red", fontSize: "18px", cursor: "pointer" }}
                                        />
                                    )}
                                </Space>
                            </Col>
                        ))}
                    </Row>
                </Col>

                <Col span={4}>
                    <Space>
                        <Button type="dashed" onClick={addRow} icon={<PlusOutlined />}>
                            Add
                        </Button>
                        {
                            displayReinitialize && <Button type="dashed" onClick={reload} icon={<ReloadOutlined />}>
                                Reset
                            </Button>
                        }
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export default Score;
