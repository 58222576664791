import React, { Component } from 'react';
import { Col, Row, Tag, Tabs, Select, Checkbox, Button, Collapse, message, Input, AutoComplete, DatePicker, Dropdown } from 'antd'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import Editor from '@monaco-editor/react';
import emoji from '../../../images/emoji.png'
import { base64_decode, base64_encode, generateKey } from '../../Utilities/Utilities';
import moment from 'moment';
import timezone from '../../Utilities/timezone.json'
import { list_draft, save_draft, scheduled } from '../../Utilities/ExpertSender';
import { list_footer, list_header, list_seedlist, list_segment, list_subscriber, list_suppression } from '../../Utilities/Stats';
import { Redirect } from 'react-router-dom'


const { Option } = Select
const { TextArea } = Input
const dateFormat = "YYYY-MM-DD HH:mm:ss";

class RouterDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //liste utilisty:
            liste_users: [],
            liste_tags: [],
            liste_draft: [],
            liste_header: [],
            liste_footer: [],
            liste_seedlist: [],
            liste_segments: [],
            liste_subscriber: [],
            liste_suppression: [],
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            //displaying
            display_template_routeur: 'block',
            displayThrottlingOption: 'none',
            displayThrottlingDelay: 'none',
            displaySTODelay: 'block',
            //
            throttling_type: "Manual",
            stoDelay: '24h',
            throttling_delay: 1,
            //loading
            loadingSave: false,
            loadingSend: false,
            loadingScheduled: false,
            loadingScheduledIsp: false,
            isScheduled: false,
            //
            crea_base_id: null,
            crea_id: null,
            crea_code: null,
            crea_router_sender: null,
            crea_router_fromemail: null,
            crea_router_replyname: null,
            crea_router_replyemail: null,
            crea_router_object: null,
            crea_router_preheader: null,
            crea_tags_id: null,
            crea_router_message_id: null,
            crea_router_message_type: null,
            crea_router_header_id: null,
            crea_router_footer_id: null,
            crea_router_seedlist_id: null,
            crea_timezone: 'W. Europe Standard Time',
            crea_scheduled_date: null,
            crea_delivery_method: 'SendTimeOptimization',
            scheduleSegment: [],
            scheduleSubscriber: [],
            scheduledSuppression: [],
            crea_dwh_id: null,
            crea_dwh_status: null,
        }
    }

    componentDidMount() {
        var crea = this.props.creativities
        this.ongetListDraft(crea['base_id'], crea['router_message'])
        this.ongetListSegments(crea['base_id'])
        this.getListSuppression(crea['base_id'])
        this.getListSeedlist(crea['base_id'])
        this.getListSubscriber(crea['base_id'])
        var scheduledSub = []
        var defaultData = this.settingDefaultValue(crea, this.props.liste_base_user)
        if (crea['router'] === "1") {
            this.getListHeader(crea['base_id'])
            this.getListFooter(crea['base_id'])
        }
        if (crea['dwh_status'] === "VALIDATE" && crea['dwh_id'] !== null) {
            this.extractSubscriberListsDWH(crea['base_id'], crea['dwh_id'])
        } else {
            scheduledSub = crea['stats_subscriber'] === null || crea['stats_subscriber'] === 'null' || crea['stats_subscriber'] === '[]' || (crea['stats_subscriber']).includes('[') === false ? [] : JSON.parse(crea['stats_subscriber'])
            this.setState({
                scheduleSubscriber: scheduledSub
            })
        }

        this.setState({
            liste_users: this.props.liste_user,
            liste_tags: this.props.liste_tags,
            // display_template_routeur: (crea['ktk_footer_id'] === "0" || crea['ktk_footer_id'] === "null" || crea['ktk_header_id'] === "0" || crea['ktk_header_id'] === "null") ? 'block' : 'none',
            crea_base_id: crea['base_id'],
            crea_id: crea['id'],
            crea_code: base64_decode(crea['optimized']),
            crea_router_sender: crea['sender'] !== null ? base64_decode(crea['sender']) : defaultData['defaultSender'],
            crea_router_fromemail: crea['router_fromemail'] !== null && crea['router_fromemail'] !== "null" && (crea['router_fromemail']).length > 0 ? crea['router_fromemail'] : defaultData['defaultFromEmail'],
            crea_router_replyname: crea['router_replyname'] !== null && crea['router_replyname'] !== "null" && (crea['router_replyname']).length > 0 ?  crea['router_replyname'] : defaultData['defaultReplyName'],
            crea_router_replyemail: crea['router_replyemail'] !== null && crea['router_replyemail'] !== "null" && (crea['router_replyemail']).length > 0 ?  crea['router_replyemail'] : defaultData['defaultReplyEmail'],
            crea_router_header_id: defaultData['defaultHeaderId'] !== null && defaultData['defaultHeaderId'] !== undefined ? parseInt(defaultData['defaultHeaderId']) : null,
            crea_router_footer_id: defaultData['defaultFooterId'] !== null && defaultData['defaultFooterId'] !== undefined ? parseInt(defaultData['defaultFooterId']) : null,
            crea_router_seedlist_id: defaultData['defaultSeedId'] ,

            crea_router_object: crea['object'] !== null && base64_decode(crea['object']) !== "null" ? base64_decode(crea['object']) : null,
            crea_router_preheader: crea['preheader'] !== null && base64_decode(crea['preheader']) !== "null" ? base64_decode(crea['preheader']) : null,
            crea_tags_id: parseInt(crea['tags_id']),
            crea_router_message_id: crea['router_id'] !== null ? parseInt(crea['router_id']) : null,
            crea_router_message_type: crea['router_message'],
            crea_dwh_id: crea['dwh_id'],
            crea_dwh_status: crea['dwh_status'],
            crea_scheduled_date: moment(crea['stats_date']),

            scheduleSegment: crea['stats_segment'] === '[0]' || crea['stats_segment'] === null || crea['stats_segment'] === 'null' || crea['stats_segment'] === '[]' || (crea['stats_segment']).includes('[') === false ? [] : JSON.parse(crea['stats_segment']),
            scheduledSuppression: crea['stats_suppression'] === null || crea['stats_suppression'] === 'null' || crea['stats_suppression'] === '[]' || (crea['stats_suppression']).includes('[') === false ? [] : JSON.parse(crea['stats_suppression']),

            crea_delivery_method: crea['delivery_method'],
            displaySTODelay: crea['delivery_method'] === "Throttling" ? "none" : "block",
            displayThrottlingOption: crea['delivery_method'] === "Throttling" ? "block" : "none",
            throttling_delay: crea['delivery_method'] === "Throttling" ? crea['delivery_value'] : 6,
            stoDelay: crea['delivery_method'] === "SendTimeOptimization" ? crea['delivery_value'] : "24h"
        })
    }

    settingDefaultValue(crea, base_user) {
        var database_default_header_id = null
        var database_default_footer_id = null
        var database_default_seedlist = null
        var default_from_email = null
        var default_reply_email = null
        var default_sender = null
        var default_reply_name = null
        var result = {}
        base_user.map((item) => {
            if (parseInt(item['id']) === parseInt(crea['base_id'])) {
                database_default_header_id = (item['defaultHeaderId'] !== null && item['defaultHeaderId'] !== 'null' && item['defaultHeaderId'] !== '') ? parseInt(item['defaultHeaderId']) : null
                database_default_footer_id = (item['defaultFooterId'] !== null && item['defaultFooterId'] !== 'null' && item['defaultFooterId'] !== '') ? parseInt(item['defaultFooterId']) : null
                database_default_seedlist = (item['defaultSeedId'] !== null && item['defaultSeedId'] !== 'null' && item['defaultSeedId'] !== '') ? (item['defaultSeedId']).split(',') : []
                default_from_email = (item['defaultFromEmail'] !== null && item['defaultFromEmail'] !== 'null' && item['defaultFromEmail'] !== '') ? item['defaultFromEmail'] : null
                default_reply_email = (item['defaultReplyEmail'] !== null && item['defaultReplyEmail'] !== 'null' && item['defaultReplyEmail'] !== '') ? item['defaultReplyEmail'] : null
                default_sender = (item['defaultSender'] !== null && item['defaultSender'] !== 'null' && item['defaultSender'] !== '') ? item['defaultSender'] : null
                default_reply_name = (item['defaultReplyName'] !== null && item['defaultReplyName'] !== 'null' && item['defaultReplyName'] !== '') ? item['defaultReplyName'] : null
            }
            return null
        })
        result['defaultHeaderId'] = database_default_header_id
        result['defaultFooterId'] = database_default_footer_id
        result['defaultFromEmail'] = default_from_email
        result['defaultReplyEmail'] = default_reply_email
        result['defaultSender'] = default_sender
        result['defaultReplyName'] = default_reply_name
        try {
            database_default_seedlist.map((item,i) => {
                database_default_seedlist[i] = parseInt(item)
            })
            result['defaultSeedId'] = database_default_seedlist
        } catch (error) {
            result['defaultSeedId'] = []
        }
        return result
    }

    onThrottlingTypeChange(value) {
        this.setState({ throttling_type: value })
        switch (value) {
            case "Manual":
                this.setState({ displaySTODelay: 'none', displayThrottlingDelay: 'block' })
                break;
            default:
                this.setState({ displayThrottlingDelay: 'none' })
                break;
        }
    }

    onDeliveryMethodChanged(value) {
        this.setState({ crea_delivery_method: value })
        switch (value) {
            case 'Throttling':
                this.setState({ displaySTODelay: 'none', displayThrottlingOption: 'block' })
                break;

            case 'SendTimeOptimization':
                this.setState({ displaySTODelay: 'block', displayThrottlingOption: 'none' })
                break;

            case 'None':
                this.setState({ displaySTODelay: 'none', displayThrottlingOption: 'none' })
                break;
            default:
                break;
        }
    }

    ongetListDraft(base_id, draft_type) {
        Promise.resolve(list_draft(this.state.user_id, this.state.apikey, base_id, draft_type)).then((value) => {
            if (('message') in value) {
                this.setState({ liste_draft: [] })
            } else {
                this.setState({ liste_draft: value })
            }
        });
    }

    ongetListSegments(base_id) {
        Promise.resolve(list_segment(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
            var mydata = value.sort((a, b) => b.id - a.id);
            this.setState({
                liste_segments: mydata
            })
        });
    }

    getListSuppression(base_id) {
        Promise.resolve(list_suppression(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
            var mydata = value.sort((a, b) => b.Id - a.Id);
            this.setState({
                liste_suppression: mydata
            })
        });
    }

    getListSubscriber(base_id) {
        Promise.resolve(list_subscriber(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
            var mydata = value.sort((a, b) => b.id - a.id);
            this.setState({
                liste_subscriber: mydata
            })
        });
    }

    extractSubscriberListsDWH(base_id, dwh_id) {
        Promise.resolve(list_subscriber(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
            var mydata = value.sort((a, b) => b.id - a.id);
            var lists = []
            mydata.map(item => {
                if (item['Name'].includes(dwh_id)) {
                    lists.push(parseInt(item['id']))
                }
            })
            this.setState({
                liste_subscriber: mydata,
                scheduleSubscriber: lists
            })
        });
    }

    getListSeedlist(base_id) {
        Promise.resolve(list_seedlist(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id,)).then((value) => {
            var mydata = value.sort((a, b) => b.id - a.id);
            this.setState({
                liste_seedlist: mydata
            })
        });
    }

    getListHeader(base_id) {
        Promise.resolve(list_header(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id, null)).then((value) => {
            this.setState({ liste_header: value })
        });
    }

    getListFooter(base_id) {
        Promise.resolve(list_footer(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id, null)).then((value) => {
            this.setState({ liste_footer: value })
        });
    }

    onSaveAsDraft(status) {
        switch (status) {
            case 'save':
                this.setState({ loadingSave: true })
                break;
            case 'send':
                this.setState({ loadingSend: true })
                break;
            default:
                break;
        }
        var tagname = null
        this.state.liste_tags.map(item => {
            if (parseInt(this.state.crea_tags_id) === parseInt(item['id'])) {
                tagname = item['tag']
            }
            return null
        })
        var seed = null
        if (status === 'send') {
            seed = this.state.crea_router_seedlist_id
        }

        if (this.state.crea_router_sender === null || this.state.crea_router_fromemail === null) {
            if (this.state.crea_router_sender === null) { message.info('SENDER IS MANDATORY'); }
            if (this.state.crea_router_fromemail === null) { message.info('FROM EMAIL IS MANDATORY'); }
            return null
        } else {
            Promise.resolve(save_draft(this.state.user_id,
                this.state.apikey,
                this.state.stats_apikey,
                this.state.crea_base_id,
                this.state.crea_id,
                this.state.crea_router_message_type,
                tagname, 
                this.state.crea_tags_id,
                this.state.crea_router_object,
                this.state.crea_router_sender,
                this.state.crea_router_preheader,
                this.state.crea_router_fromemail,
                this.state.crea_router_replyname,
                this.state.crea_router_replyemail,
                this.state.crea_router_header_id,
                this.state.crea_router_footer_id,
                this.state.crea_router_message_id,
                this.state.crea_code,
                status,
                seed)).then((value) => {
                    setTimeout(() => {
                        message.success("BAT Sent !!!")
                        this.setState({
                            loadingSave: false,
                            loadingSend: false
                        })
                    }, 2000)
                });
        }
    }

    onScheduled(status) {
        switch(status){
            case "normal":
                this.setState({ loadingScheduled: true })
                break
            case "by_isp":
                this.setState({ loadingScheduledIsp: true })
                break
        }
        var datetime = moment(this.state.crea_scheduled_date).format(dateFormat)
        var currentDate = moment()
        if (moment(datetime).isBefore(currentDate) || this.state.crea_router_message_type !== "Newsletters" || this.state.crea_router_fromemail === null || this.state.crea_router_sender === null || this.state.crea_router_object === null) {
            if (this.state.crea_router_message_type !== "Newsletters") { message.warning("Actually can't schedule Recurrings and Triggers message", 2) }
            if (moment(datetime).isBefore(currentDate)) { message.warning("Newsletter can't be scheduled on the past", 2) }
            if (this.state.crea_router_fromemail === null) { message.warning("FROM EMAIL IS MANDATORY", 2) }
            if (this.state.crea_router_sender === null) { message.warning("SENDER IS MANDATORY", 2) }
            if (this.state.crea_router_object === null) { message.warning("OBJECT IS MANDATORY", 2) }

            this.setState({ loadingScheduled: false, loadingScheduledIsp: false })
            return null
        } else {
            if((this.state.scheduleSegment).length === 0 && (this.state.scheduleSubscriber).length === 0){
                message.warning("Message can't be scheduled without recipients !!!", 3)
                this.setState({ loadingScheduled: false, loadingScheduledIsp: false })
                return null
            }else{
                if (this.state.crea_dwh_id === null && status === "by_isp") {
                    message.warning("This need an export from the datawarehouse", 2)
                    this.setState({ loadingScheduled: false, loadingScheduledIsp: false })
                    return null
                } else {
                    datetime = ((moment(this.state.crea_scheduled_date).format(dateFormat)).toString()).split(' ')
                    var recurring_enddate = null
                    var recurring_days = null
                    var recurring_hour = null
                    var recurring_months = null
                    var recurring_minutes = null
                    var tagname = null
                    var useTemplateRouter = this.state.display_template_routeur === 'block' ? 1 : 0
                    this.state.liste_tags.map(item => {
                        if (parseInt(this.state.crea_tags_id) === parseInt(item['id'])) {
                            tagname = item['tag']
                        }
                        return null
                    })
                    Promise.resolve(scheduled(
                        this.state.user_id, this.state.apikey, this.state.stats_apikey, this.state.crea_base_id, this.state.crea_router_sender, this.state.crea_router_fromemail, this.state.crea_router_replyname,
                        this.state.crea_router_replyemail, this.state.crea_router_object, this.state.crea_router_preheader, this.state.crea_router_header_id,
                        this.state.crea_router_footer_id, this.state.crea_router_message_id, this.state.crea_router_message_type, this.state.crea_code, null,
                        this.state.scheduleSegment, this.state.scheduledSuppression, this.state.crea_router_seedlist_id, datetime[0], datetime[1], this.state.crea_timezone,
                        tagname,
                        this.state.crea_tags_id,
                        this.state.crea_delivery_method,
                        this.state.throttling_delay,
                        this.state.throttling_type,
                        this.state.stoDelay,
                        recurring_enddate,
                        recurring_days,
                        recurring_hour,
                        recurring_months,
                        recurring_minutes,
                        this.state.scheduleSubscriber,
                        this.state.crea_id,
                        useTemplateRouter, status, this.state.crea_dwh_id))
                        .then((value) => {
                            message.success('Message successfully scheduled a the router', 2)
                            setTimeout(() => {
                                this.setState({
                                    loadingSave: false,
                                    loadingSend: false,
                                    loadingScheduled: false,
                                    isScheduled: false,
                                    loadingScheduledIsp: false,
                                    isScheduled: true
                                })
                            }, 1500)
                        });
                }
            }
        }

    }

    render() {
        if (this.state.isScheduled === true) {
            localStorage.setItem('defaulttabcrea', '2')
            var base_id = this.state.crea_base_id
            return (<Redirect to={{
                pathname: '/app/creativities/task',
                state: { base_id }
            }} />)
        }
        const menu = (
            // <Menu style={{ textAlign: 'center', color: '#fff', width: '20%', height: '30vh', opacity: 1,  padding: 0 }}>
            <div style={{ width: 800, height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                <Picker
                    emojisToShowFilter={false}

                    showSearch={false}
                    navPosition="bottom"
                    search="none"
                    style={{ width: '100%', overflow: 'scroll', fontSize: 12, float: 'right' }}
                    onSelect={
                        (emoji) => {
                            var data = this.state.crea_router_object + emoji.native
                            this.setState({ crea_router_object: data })
                        }
                    }
                />
            </div>

            // </Menu>
        );
        return (
            <div style={{ height: '91vh', overflowY: 'auto', overflowX: 'hidden', width: '100%', margin: '0', marginTop: '-8px', background: 'aliceblue' }} >
                
               <Row>
                    <Col span={24}>
                        <Row>
                            <div style={{ height: '40px', backgroundColor: '#23bcbe', width: '100%', padding: 8, textAlign: 'center' }}>
                                <h2 style={{ color: "#fff", fontWeight: 600, textDecoration: 'underline' }}>NEWSLETTERS</h2>
                            </div>
                        </Row>
                        <Row style={{ marginTop: 5 }}>
                            <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Sender</span>
                            </Col>
                            <Col span={10}>
                                <Input
                                    type="text"
                                    size='medium'
                                    value={this.state.crea_router_sender}
                                    style={{ width: '100%', color: '#192a56' }}
                                    onChange={(e) => { this.setState({ crea_router_sender: e.target.value }) }}
                                />
                            </Col>
                            <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>FromEmail</span>
                            </Col>
                            <Col span={8}>
                                <Input
                                    size='medium'
                                    type="text"
                                    value={this.state.crea_router_fromemail}
                                    style={{ width: '100%', color: '#192a56' }}
                                    onChange={(e) => { this.setState({ crea_router_fromemail: e.target.value }) }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>ReplyToName</span>
                            </Col>
                            <Col span={10}>
                                <Input
                                    type="text"
                                    value={this.state.crea_router_replyname}
                                    onChange={(e) => { this.setState({ crea_router_replyname: e.target.value }) }}
                                    style={{ width: '100%', color: '#192a56' }}
                                />
                            </Col>
                            <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>ReplyEmail</span>
                            </Col>
                            <Col span={8}>
                                <Input
                                    type="text"
                                    value={this.state.crea_router_replyemail}
                                    onChange={(e) => { this.setState({ crea_router_replyemail: e.target.value }) }}
                                    style={{ width: '100%', color: '#192a56' }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Object</span>
                            </Col>
                            <Col span={20}>
                                <Input
                                    type="text"
                                    value={this.state.crea_router_object}
                                    onChange={(e) => { this.setState({ crea_router_object: e.target.value }) }}
                                    style={{ width: '100%', color: '#192a56' }}
                                />
                            </Col>
                            <Col span={1}>
                                <Dropdown overlay={menu} placement="bottom">
                                    <div>
                                        <img src={emoji} alt="emoji" style={{ width: 28, height: 28, display: 'block', margin: '0 auto' }} />
                                    </div>
                                </Dropdown>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Preheader</span>
                            </Col>
                            <Col span={21}>
                                <TextArea
                                    type="text"
                                    rows={1}
                                    value={this.state.crea_router_preheader}
                                    onChange={(e) => { this.setState({ crea_router_preheader: e.target.value }) }}
                                    style={{ width: '100%', color: '#192a56' }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Tag</span>
                            </Col>
                            <Col span={3}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.crea_tags_id}
                                    onChange={(value) => { this.setState({ crea_tags_id: value }) }}
                                >
                                    {
                                        this.state.liste_tags && this.state.liste_tags.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={parseInt(item.id)}>{item.tag}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Type</span>
                            </Col>
                            <Col span={3}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.crea_router_message_type}
                                    onChange={(value) => {
                                        this.setState({ crea_router_message_type: value, liste_draft: [] })
                                        this.ongetListDraft(this.state.crea_base_id, value)
                                    }}
                                >
                                    <Option value="Newsletters">Newsletters</Option>
                                    <Option value="Recurrings">Recurrings</Option>
                                    <Option value="Triggers">Triggers</Option>
                                </Select>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Draft</span>
                            </Col>
                            <Col span={13}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56' }}
                                    value={this.state.crea_router_message_id}
                                    onChange={(value) => {
                                        if (value !== null)
                                            this.setState({ crea_router_message_id: value })
                                    }}
                                >
                                    <Option key="464666" value="New">New draft</Option>
                                    {
                                        this.state.liste_draft && this.state.liste_draft.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={item.Id}>{item.Id} - {item.Subject}</Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3,display: `${this.state.display_template_routeur}`}}>
                                <span>Header</span>
                            </Col>
                            <Col span={3} style={{display: `${this.state.display_template_routeur}`}}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.crea_router_header_id}
                                    onChange={(value) => { this.setState({ crea_router_header_id: value }) }}
                                >
                                    {
                                        this.state.liste_header && this.state.liste_header.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={parseInt(item.id)} style={{ color: '#192a56' }}>{item.id}-{item.Name}</Option>
                                            )
                                        })
                                    }

                                </Select>

                            </Col>
                            <Col span={1} style={{ textAlign: 'center', padding: 3, display: `${this.state.display_template_routeur}` }}>
                                <span>Footer</span>
                            </Col>
                            <Col span={3} style={{display: `${this.state.display_template_routeur}`}}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.crea_router_footer_id}
                                    onChange={(value) => { this.setState({ crea_router_footer_id: value }) }}
                                >
                                    {
                                        this.state.liste_footer && this.state.liste_footer.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={parseInt(item.id)}>{item.id}-{item.Name}</Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Col>
                            <Col span={this.state.display_template_routeur === "block" ? 1 : 3} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Seedlist</span>
                            </Col>
                            <Col span={this.state.display_template_routeur === "block" ? 13 : 21}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    mode='multiple'
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56' }}
                                    value={this.state.crea_router_seedlist_id}
                                    onChange={(value) => { this.setState({ crea_router_seedlist_id: value }) }}
                                >
                                    {
                                        this.state.liste_seedlist && this.state.liste_seedlist.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={parseInt(item.id)}>{item.id}-{item.Name}</Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <div style={{ height: '40px', backgroundColor: '#23bcbe', width: '100%', padding: 8, textAlign: 'center' }}>
                                <h2 style={{ color: "#fff", fontWeight: 600, textDecoration: 'underline' }}>RECIPIENTS</h2>
                            </div>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
                                <span>Segments</span>
                            </Col>
                            <Col span={7}>
                                <Select
                                    mode='multiple'
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.scheduleSegment}
                                    onChange={(value) => { this.setState({ scheduleSegment: value }) }}
                                >
                                    {
                                        this.state.liste_segments && this.state.liste_segments.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={2} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
                                <span>Suppression Lists</span>
                            </Col>
                            <Col span={12}>
                                <Select
                                    mode='multiple'
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.scheduledSuppression}
                                    onChange={(value) => { this.setState({ scheduledSuppression: value }) }}
                                >
                                    {
                                        this.state.liste_suppression && this.state.liste_suppression.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={parseInt(item.Id)}>{item.Id + ' - ' + item.Name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
                                <span>Subscriber Lists</span>
                            </Col>
                            <Col span={21}>
                                <Select
                                    mode='multiple'
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center', height: '100px', overflowY: 'scroll' }}
                                    value={this.state.scheduleSubscriber}
                                    onChange={(value) => { this.setState({ scheduleSubscriber: value }) }}
                                >
                                    {
                                        this.state.liste_subscriber && this.state.liste_subscriber.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.Name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        {/* <br /> */}
                        <Row>
                            <div style={{ height: '40px', backgroundColor: '#23bcbe', width: '100%', padding: 8, textAlign: 'center' }}>
                                <h2 style={{ color: "#fff", fontWeight: 600, textDecoration: 'underline' }}>DELIVERY</h2>
                            </div>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
                                <span>Timezone</span>
                            </Col>
                            <Col span={16}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.crea_timezone}
                                    onChange={(value) => { this.setState({ crea_timezone: value }) }}
                                >
                                    {
                                        timezone && timezone.map(item => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={item['Value']}>{item['Text']}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>

                        </Row>
                        <br />
                        <Row>
                            <Col span={3} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
                                <span>Scheduled date</span>
                            </Col>
                            <Col span={6}>
                                <DatePicker
                                    style={{ width: '100%', textAlign: 'center' }}
                                    showTime
                                    dateFormat={dateFormat}
                                    value={this.state.crea_scheduled_date}
                                    disabledDate={current => {
                                        return current && current <= moment().subtract(1, 'days');
                                    }}
                                    onChange={(a, b) => {
                                        this.setState({
                                            crea_scheduled_date: a,
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={2} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
                                <span>Delivery Method</span>
                            </Col>
                            <Col span={4}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '95%', color: '#192a56', textAlign: 'center' }}
                                    value={this.state.crea_delivery_method}
                                    onChange={
                                        (value) => {
                                            this.onDeliveryMethodChanged(value)
                                        }}
                                >
                                    <Option key="1" value="Throttling">Throttling</Option>
                                    <Option key="2" value="SendTimeOptimization">Send Time Optimization</Option>
                                </Select>
                            </Col>
                            <Col span={4} style={{ display: `${this.state.displayThrottlingOption}` }}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56' }}
                                    value={this.state.throttling_type}
                                    onChange={(value) => {
                                        this.onThrottlingTypeChange(value)
                                    }}
                                >
                                    <Option key="31" value="Manual">Specify throttming time manually</Option>
                                </Select>
                            </Col>
                            <Col span={2} style={{ textAlign: 'center', fontSize: 18, display: `${this.state.displayThrottlingOption}` }}>
                                <Input
                                    type="number"
                                    min="1"
                                    max="72"
                                    value={this.state.throttling_delay}
                                    onChange={(e) => { this.setState({ throttling_delay: e.target.value }) }}
                                    style={{ width: '80%', color: '#192a56', textAlign: 'center' }}
                                />
                            </Col>
                            <Col span={4} style={{ textAlign: 'center', fontSize: 18, display: `${this.state.displaySTODelay}` }}>
                                <Select
                                    optionFilterProp="children"
                                    showSearch
                                    size="medium"
                                    style={{ width: '100%', color: '#192a56' }}
                                    value={this.state.stoDelay}
                                    onChange={(value) => {
                                        this.setState({
                                            stoDelay: value
                                        })
                                    }}
                                >
                                    <Option key="24 hours" value="24h">24 hours</Option>
                                    <Option key="7 days" value="7d">7 days</Option>
                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <Row style={{ marginTop: 10 }}>
                            {/* <Col span={6} style={{ textAlign: 'right' }}>
                                <Button
                                    loading={this.state.loadingSave}
                                    className="btn btn-secondary"
                                    style={{ width: '80%', height: 50, fontSize: 18, fontWeight: 600 }}
                                    onClick={() => { this.onSaveAsDraft('save') }}
                                >
                                    SAVE AS DRAFT
                                </Button>
                            </Col> */}
                            <Col span={8} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingSend}
                                    className="btn btn-primary"
                                    style={{ width: '80%', height: 50, fontSize: 18, fontWeight: 600 }}
                                    onClick={() => { this.onSaveAsDraft('send') }}
                                >
                                    SEND BAT
                                </Button>
                            </Col>
                            <Col span={8} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingScheduledIsp}
                                    className="btn btn-warning"
                                    style={{ width: '80%', height: 50, fontSize: 18, fontWeight: 600 }}
                                    onClick={() => { this.onScheduled('by_isp') }}
                                >
                                    SCHEDULE NEWSLETTER BY ISP (DWH)
                                </Button>
                            </Col>
                            <Col span={8} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingScheduled}
                                    className="btn btn-success"
                                    style={{ width: '80%', height: 50, fontSize: 18, fontWeight: 600 }}
                                    onClick={() => { this.onScheduled("normal") }}
                                >
                                    SCHEDULE NEWSLETTER
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
                
            </div >
        );
    }
}

export default RouterDetails;
