import React, { useState, useEffect } from 'react';
import "../../css/Loginv2.css";
import { Button } from 'antd';
import logo from '../../images/icone.png';

import { login_user, check_user } from '../Utilities/User';
import { base64_decode, base64_encode } from '../Utilities/Utilities';
import { Redirect } from 'react-router-dom';

const Login = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [displayError, setDisplayError] = useState('none');
    const [displaySuccess, setDisplaySuccess] = useState('none');
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        if ('caches' in window) {
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName);
                });
                console.log("cache clear");
            });
        }
        checkStatusLogging();
        localStorage.setItem('defaulttabcrea', '3');
    }, []);

    const checkStatusLogging = () => {
        const user_id = localStorage.getItem(base64_encode('user_id'));
        const apikey = localStorage.getItem(base64_encode('apikey'));
        if (user_id !== null && apikey !== null) {
            check_user(base64_decode(user_id), base64_decode(apikey))
                .then((value) => {
                    if (value['status'] === "success") {
                        sessionStorage.setItem(base64_encode('ktksession'), base64_encode('loggin'));
                        setIsLogin(true);
                        setDisplaySuccess('block');
                    }
                });
        }
    };

    const onLogin = () => {
        setLoadingLogin(true);
        login_user(email, password)
            .then((value) => {
                if (value['status'] === 'success') {
                    localStorage.setItem(base64_encode('endpoint'),base64_encode(value['endpoint']))
                    localStorage.setItem(base64_encode('email'),base64_encode(email))
                    localStorage.setItem(base64_encode('apikey'),base64_encode(value['data'][0]['apikey']))
                    localStorage.setItem(base64_encode('username'),base64_encode(value['data'][0]['username']))
                    localStorage.setItem(base64_encode('roles'),base64_encode(value['data'][0]['roles']))
                    localStorage.setItem(base64_encode('apikey'),base64_encode(value['data'][0]['apikey']))
                    localStorage.setItem(base64_encode('user_id'),base64_encode(value['data'][0]['user_id']))
                    localStorage.setItem(base64_encode('stats_apikey'),base64_encode(value['data'][0]['stats_apikey']))
                    localStorage.setItem(base64_encode('avatar'),base64_decode(value['data'][0]['avatar']))
                    sessionStorage.setItem(base64_encode('avatar'),base64_decode(value['data'][0]['avatar']))
            		sessionStorage.setItem(base64_encode('ktksession'), base64_encode('loggin'))
                    setIsLogin(true);
                    setLoadingLogin(false);
                    setDisplayError('none');
                    setDisplaySuccess('block');
                } else {
                    setIsLogin(false);
                    setLoadingLogin(false);
                    setDisplayError('block');
                    setDisplaySuccess('none');
                }
            });
    };

    if (isLogin) {
        return <Redirect to={{ pathname: '/app/creativities/task' }} />;
    }

    return (
        <div className='gx-app-login-wrap'>
            <div className='gx-app-login-container'>
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg">
                            <img src={logo} alt="Neature" />
                        </div>
                    </div>

                    <div style={{ width: '60%' }}>
                        <div style={{ width: '100%', height: '80%', padding: 20, marginTop: 30 }}>
                            <div className="form-group" style={{ textAlign: 'left' }}>
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter email"
                                    style={{ fontSize: 15 }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <br />
                            <div className="form-group" style={{ textAlign: 'left' }}>
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Password"
                                    style={{ fontSize: 15 }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <br />
                            <div className="form-check" style={{ textAlign: 'left' }}>
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label">Remember me</label>
                            </div>
                            <div className="form-check" style={{ textAlign: 'center' }}>
                                <Button
                                    loading={loadingLogin}
                                    className="btn btn-primary"
                                    style={{ textAlign: 'center', width: '60%' }}
                                    onClick={onLogin}
                                >
                                    Log in
                                </Button>
                            </div>
                            <br />
                            <div style={{ display: displayError }}>
                                <div>
                                    <div className="alert alert-danger" role="alert" style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>
                                        <h6>AUTHENTIFICATION ERROR, PLEASE TRY AGAIN !!!</h6>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: displaySuccess, marginTop: '-10px' }}>
                                <div>
                                    <div className="alert alert-success" role="alert" style={{ textAlign: 'center', width: '80%', margin: '0 auto' }}>
                                        Welcome back <strong>{username} &#128512;</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
