import React, { Component } from 'react'
import { Alert, Tooltip, Row, Col, Select, Input, Button, Radio, message, Tag, Tabs, } from 'antd';
import axios from 'axios';
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';
import * as config from '../config/config'

import Editor from '@monaco-editor/react';
import {
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
    TagOutlined,
    LinkOutlined,
    BoldOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    PlusSquareOutlined
} from '@ant-design/icons';
import Modal from 'react-modal'
import '../../css/Phone.css'
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import { SketchPicker } from 'react-color';
import palette from '../../images/palette.jpg'
import emoji from '../../images/emoji.png'
import font from '../../images/Font.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { addToSeedlists, createSeedlists, getcreatedSeedlists, createSubscriberlists, addToSubscriberlists, export_segment, getExportedSegment, export_segment_lists } from '../Utilities/ExpertSender';
import { list_segment } from '../Utilities/Stats';
import { list_user } from '../Utilities/User';
import Toolso from '../Overview/Toolso';
import ImpactChecker from '../Overview/ImpactChecker';

const { TextArea } = Input;
const { Option } = Select
const { TabPane } = Tabs;

class Templates extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liste_base: [],
            liste_segment: [],
            liste_segment_exported: [],
            liste_user: [],
            isAdmin: 'none',
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            endpoint: base64_decode(localStorage.getItem(base64_encode('endpoint'))),
            role: base64_decode(localStorage.getItem(base64_encode('roles'))),
            template_type: 'header',
            liste_header: [],
            liste_footer: [],
            header_code: null,
            show_header: false,
            show_create_header: false,
            liste_keywords: [],
            background: "#fff",
            show_palette: false,
            show_variables: false,
            show_emoji: false,
            show_font: false,
            show_bold: false,
            font_selected: "Verdana",
            show_links: false,
            header_structure: [{ "text": "", "links": [], "style": "font{font-family: Verdana; color: #808080; font-size: 11px; text-align: justify;}", "bold": [] }],
            // liste_link: [{"text": null, "url": null}],
            index_links: 0,
            text_ligne: null,
            keyword_links: '',
            keyword_url: '',
            header_url: [],
            konticrea_params: [],
            keyword_bold: '',
            header_name: '',
            pixels_squadata: false,
            pixels_notify: false,
            pixels_remailme: false,
            pixels_eperflex: false,
            pixels_acxiom: false,
            pixels_kpush: false,
            header_id: null,
            show_remove_header: false,
            loadingUpdate: false,
            loadingDeleteHeader: false,
            show_clone_header: false,
            loadingCloneHeader: false,
            show_assign_header: false,
            base_id: [],
            loadingAssign: false,
            loading_generate: false,
            loading_save_template: false,
            //footer
            footer_name: null,
            loadingUpdateFooter: false,
            show_create_footer: false,
            show_assign_footer: false,
            show_footer: false,
            footer_code: null,
            footer_id: null,
            show_remove_footer: false,
            show_clone_footer: false,
            footer_structure: [{ "text": "", "links": [], "style": "font{font-family: Verdana; color: #808080; font-size: 11px; text-align: justify;}", "bold": [] }],
            loadingCloneFooter: false,
            loadingAssignFooter: false,
            show_variables_footer: false,
            show_bold_footer: false,
            show_links_footer: false,
            show_palette_footer: false,
            show_font_footer: false,
            show_emoji_footer: false,
            //seedlists
            liste_seed_created: [],
            liste_seed_created_filtered: [],

            loadingcreateSeed: false,
            loadingaddtoexisting: false,
            seed_type: null,
            seed_filter: null,
            seed_name: null,
            seed_listid: null,
            seed_username: null,
            seed_email_lists: null,
            sub_encryption: 'md5',
            show_new_seed: false,
            show_modal_adding: false,
            show_modal_adding_sub: false,
            show_modal_adding_existing_sub: false,
            //segments
            segments_id: null,
            loadingExportSegments: false,
            loadingRefreshExport: false,
            defaultactive: localStorage.getItem('defaultDeliveryTab')

        }
    }

    componentDidMount() {
        if (localStorage.getItem('defaultDeliveryTab') === null) {
            this.setState({
                defaultactive: '6'
            })
        } else {
            this.setState({
                defaultactive: (localStorage.getItem('defaultDeliveryTab')).toString()
            })
        }

        if (this.state.role === 'Admin' || this.state.role === 'RD') {
            this.setState({
                isAdmin: 'block'
            })
        } else {
            this.setState({
                defaultactive: '3'
            })
        }
        this.getListUser()
        this.getListTemplate()
        this.getListKonticreaParams()
        this.getListBaseAll()
        this.getSeedListCreated()
        this.getExportedSegments()
    }

    getListUser() {
        Promise.resolve(list_user(this.state.user_id, this.state.apikey)).then(res => {
            console.log(res)
            this.setState({
                liste_user: res
            })
        })
    }

    getSeedListCreated() {
        Promise.resolve(getcreatedSeedlists(this.state.user_id, this.state.apikey)).then(res => {
            this.setState({
                liste_seed_created: res,
                liste_seed_created_filtered: res
            })
        })
    }

    getListBaseAll() {
        if (localStorage.getItem(base64_encode('all_base')) !== null) {
            this.setState({
                liste_base: JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_base'))))
            })
        } else {
            var url = this.state.endpoint + 'api/base/all'
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            axios.post(url, fd)
                .then(res => {
                    localStorage.setItem(base64_encode('all_base'), base64_encode(JSON.stringify(res.data)))
                    this.setState({
                        liste_base: res.data
                    })
                })
        }
    }

    getListTemplate() {
        if (localStorage.getItem(base64_encode('header_konticrea')) === null || localStorage.getItem('footer_konticrea') === null) {
            var url = this.state.endpoint + 'api/creativities/template/list'
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            axios.post(url, fd)
                .then(res => {
                    localStorage.setItem(base64_encode('header_konticrea'), base64_encode(JSON.stringify(res.data['header'])))
                    localStorage.setItem(base64_encode('footer_konticrea'), base64_encode(JSON.stringify(res.data['footer'])))
                    this.setState({
                        liste_header: res.data['header'],
                        liste_footer: res.data['footer']
                    })
                })
        } else {
            this.setState({
                liste_header: JSON.parse(base64_decode(localStorage.getItem(base64_encode('header_konticrea')))),
                liste_footer: JSON.parse(base64_decode(localStorage.getItem(base64_encode('footer_konticrea'))))
            })
        }
    }

    // getListSegmentExported(){
    //     Promise.resolve(export_segment_lists(this.state.user_id, this.state.apikey)).then(res => {
    //         this.setState({liste_segment_exported: res})
    //     })
    // }

    getListKonticreaParams() {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        axios.post(config.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_PARAMS_LIST, fd)
            .then(res => {
                this.setState({
                    konticrea_params: res.data
                })
            })
    }

    onRequestCloseCode() {
        this.setState({
            show_header: false,
            header_code: null
        })
    }

    onRequestCloseCodeFooter() {
        this.setState({
            show_footer: false,
            footer_code: null
        })
    }

    onRequestCloseCreateHeader() {
        this.setState({
            show_create_header: false,
            header_code: null,
            header_name: null,
            header_structure: [{ "text": "", "links": [], "style": "font{font-family: Verdana; color: #808080; font-size: 11px; text-align: justify;}", "bold": [] }]
        })
    }

    onRequestCloseCreateFooter() {
        this.setState({
            show_create_footer: false,
            footer_code: null,
            footer_name: null,
            footer_structure: [{ "text": "", "links": [], "style": "font{font-family: Verdana; color: #808080; font-size: 11px; text-align: justify;}", "bold": [] }],

        })
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    onRequestClosePalette() {
        this.setState({
            show_palette: false
        })
    }

    onRequestClosePaletteFooter() {
        this.setState({
            show_palette_footer: false
        })
    }

    onRequestCloseFont() {
        this.setState({
            show_font: false
        })
    }

    onRequestCloseFontFooter() {
        this.setState({
            show_font_footer: false
        })
    }

    onRequestCloseLinks() {
        this.setState({
            show_links: false
        })
    }

    onRequestCloseLinksFooter() {
        this.setState({
            show_links_footer: false
        })
    }

    onRequestCloseBold() {
        this.setState({
            show_bold: false
        })
    }

    onRequestCloseBoldFooter() {
        this.setState({
            show_bold_footer: false
        })
    }

    onRequestCloseVariable() {
        this.setState({
            show_variables: false
        })
    }

    onRequestCloseVariableFooter() {
        this.setState({
            show_variables_footer: false
        })
    }

    onRequestCloseEmoji() {
        this.setState({
            show_emoji: false
        })
    }

    onRequestCloseEmojiFooter() {
        this.setState({
            show_emoji_footer: false
        })
    }

    onRequestCloseNewSeed() {
        this.setState({
            loadingcreateSeed: false,
            loadingaddtoexisting: false,
            seed_type: null,
            seed_filter: null,
            seed_name: null,
            seed_listid: null,
            seed_username: null,
            seed_email_lists: null,
            sub_encryption: 'md5',
            show_new_seed: false,
            show_modal_adding: false,
            show_modal_adding_sub: false,
            show_modal_adding_existing_sub: false,
        })
    }

    onRequestCloseAddSubscriber() {
        this.setState({
            loadingcreateSeed: false,
            loadingaddtoexisting: false,
            seed_type: null,
            seed_filter: null,
            seed_name: null,
            seed_listid: null,
            seed_username: null,
            seed_email_lists: null,
            sub_encryption: 'md5',
            show_new_seed: false,
            show_modal_adding: false,
            show_modal_adding_sub: false,
            show_modal_adding_existing_sub: false,
        })
    }

    onRequestCloseNewSub() {
        this.setState({
            loadingcreateSeed: false,
            loadingaddtoexisting: false,
            seed_type: null,
            seed_filter: null,
            seed_name: null,
            seed_listid: null,
            seed_username: null,
            seed_email_lists: null,
            sub_encryption: 'md5',
            show_new_seed: false,
            show_modal_adding: false,
            show_modal_adding_sub: false,
            show_modal_adding_existing_sub: false,
        })
    }

    onTextLigneChange(e, index) {
        var old_value = this.state.header_structure
        old_value[index]['text'] = e
        this.setState({
            header_structure: old_value
        })
    }

    onaddLinks() {
        var index = this.state.index_links
        var old_value = this.state.header_structure
        var old_text = old_value[index]['text']
        var new_text = old_text + ' ' + this.state.keyword_links
        old_value[this.state.index_links]['text'] = new_text
        var links_content = {}
        links_content['word'] = this.state.keyword_links
        links_content['url'] = this.state.keyword_url
        old_value[this.state.index_links]['links'].push(links_content)
        this.setState({
            header_structure: old_value,
            show_links: false,
            keyword_links: '',
            keyword_url: '',
            index_links: 0
        })
    }

    onaddLinksFooter() {
        var index = this.state.index_links
        var old_value = this.state.footer_structure
        var old_text = old_value[index]['text']
        var new_text = old_text + ' ' + this.state.keyword_links
        old_value[this.state.index_links]['text'] = new_text
        var links_content = {}
        links_content['word'] = this.state.keyword_links
        links_content['url'] = this.state.keyword_url
        old_value[this.state.index_links]['links'].push(links_content)
        this.setState({
            footer_structure: old_value,
            show_links_footer: false,
            keyword_links: '',
            keyword_url: '',
            index_links: 0
        })
    }

    onAddVariables(value) {
        var index = this.state.index_links
        var old_value = this.state.header_structure
        var old_text = old_value[index]['text']
        old_value[this.state.index_links]['text'] = old_text + ' ' + value
        this.setState({
            header_structure: old_value,
            show_variables: false,
            index_links: 0
        })
    }

    onAddVariablesFooter(value) {
        var index = this.state.index_links
        var old_value = this.state.footer_structure
        var old_text = old_value[index]['text']
        old_value[this.state.index_links]['text'] = old_text + ' ' + value
        this.setState({
            footer_structure: old_value,
            show_variables_footer: false,
            index_links: 0
        })
    }

    onCSSchanged(value, index) {
        var old_value = this.state.header_structure
        old_value[index]['style'] = value
    }

    onaddboldWord() {
        var index = this.state.index_links
        var old_value = this.state.header_structure
        var old_text = old_value[index]['text']
        old_value[index]['bold'].push(this.state.keyword_bold)
        old_value[index]['text'] = old_text + ' ' + this.state.keyword_bold
        this.setState({
            header_structure: old_value,
            show_bold: false,
            keyword_bold: '',
        })
    }

    onaddboldWordFooter() {
        var index = this.state.index_links
        var old_value = this.state.footer_structure
        var old_text = old_value[index]['text']
        old_value[index]['bold'].push(this.state.keyword_bold)
        old_value[index]['text'] = old_text + ' ' + this.state.keyword_bold
        this.setState({
            footer_structure: old_value,
            show_bold_footer: false,
            keyword_bold: '',
        })
    }

    onUrlLigneChange(e) {
        var index = this.state.index_links
        var old_value = this.state.header_structure
        old_value[index]['text'] = old_value + ' ' + e.target.value
        this.setState({
            header_structure: old_value,
        })
    }

    onAddLigne() {
        var old = this.state.header_structure
        old.push({ "text": "", "links": [], "style": "font{font-family: Verdana; color: #808080; font-size: 8pt; text-align: justify;}", "bold": [] })
        this.setState({
            header_structure: old
        })
    }

    onAddLigneFooter() {
        var old = this.state.footer_structure
        old.push({ "text": "", "links": [], "style": "font{font-family: Verdana; color: #808080; font-size: 11px; text-align: justify;}", "bold": [] })
        this.setState({
            footer_structure: old
        })
    }

    onRemoveLigne(index) {
        var old = this.state.header_structure
        old.splice(index, 1)
        this.setState({
            header_structure: old
        })
    }

    onRemoveLigneFooter(index) {
        var old = this.state.footer_structure
        old.splice(index, 1)
        this.setState({
            footer_structure: old
        })
    }

    addEmoji(e) {
        let emoji = e.native
        var index = this.state.index_links
        var old_value = this.state.header_structure
        var old_text = old_value[index]['text']
        old_value[this.state.index_links]['text'] = old_text + ' ' + emoji
        this.setState({
            header_structure: old_value,
            show_emoji: false,
            index_links: 0
        })
    }

    addEmojiFooter(e) {
        let emoji = e.native
        var index = this.state.index_links
        var old_value = this.state.footer_structure
        var old_text = old_value[index]['text']
        old_value[this.state.index_links]['text'] = old_text + ' ' + emoji
        this.setState({
            footer_structure: old_value,
            show_emoji_footer: false,
            index_links: 0
        })
    }

    createHeaderForm() {
        return this.state.header_structure.map((item, index) =>
            <div>
                <Row>
                    <Col span={3} style={{ textAlign: 'center', padding: 0 }}>
                        <span style={{ textDecoration: 'underline' }}>Text</span>
                    </Col>
                    <Col span={15}>
                        <TextArea
                            style={{ width: '100%', resize: 'none' }}
                            value={item['text']}
                            onChange={(e) => {
                                this.onTextLigneChange(e.target.value, index)
                            }}
                        />
                    </Col>
                    <Col span={6} style={{ textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={8} style={{ padding: 0 }}>
                                <Tooltip title="Variables" placement="bottom">
                                    <Button
                                        className="btn btn-info"
                                        onClick={() => {
                                            this.setState({
                                                show_variables: true,
                                                index_links: index
                                            })
                                        }}
                                    >
                                        <TagOutlined style={{ color: '#ffffff', fontSize: 18, marginTop: '0px', display: "block" }} />
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={8} style={{ padding: 0 }}>
                                <Tooltip title="Add links" placement="bottom">
                                    <Button
                                        style={{ fontSize: 16 }}
                                        className="btn btn-success"
                                        onClick={() => {
                                            this.setState({
                                                show_links: true,
                                                index_links: index
                                            })
                                        }}
                                    >
                                        <LinkOutlined style={{ color: '#ffffff', fontSize: 18, marginTop: '0px', display: "block" }} />
                                    </Button>
                                </Tooltip>
                            </Col>

                            <Col span={8} style={{ padding: 0 }}>
                                <Tooltip title="bold font" placement="bottom">
                                    <Button
                                        className="btn btn-secondary"
                                        style={{ fontSize: 16 }}
                                        onClick={() => {
                                            this.setState({
                                                show_bold: true,
                                                index_links: index
                                            })
                                        }}
                                    >
                                        <BoldOutlined style={{ color: '#ffffff', fontSize: 18, marginTop: '0px', display: "block" }} />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={3} style={{ textAlign: 'center', padding: 0 }}>
                        <span style={{ textDecoration: 'underline' }}>Style</span>
                    </Col>
                    <Col span={15}>
                        <Editor
                            height="8vh"
                            defaultLanguage="css"
                            theme="vs-dark"
                            value={item['style']}
                            style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                            onChange={(value) => {
                                this.onCSSchanged(value, index)
                            }}
                        />


                    </Col>
                    <Col span={6} style={{ textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={6} style={{ padding: 10 }}>
                                <Tooltip title="Color palette" placement="bottom">
                                    <img
                                        alt="image6"
                                        src={palette}
                                        style={{ display: 'block', width: 35, height: 35 }}
                                        onClick={() => {
                                            this.setState({
                                                show_palette: true,
                                                index_links: index
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <Tooltip title="Select a font" placement="bottom">
                                    <img
                                        alt="image1"
                                        src={font}
                                        style={{ display: 'block', width: 35, height: 35 }}
                                        onClick={() => {
                                            this.setState({
                                                show_font: true,
                                                index_links: index
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <Tooltip title="Add emoji icon" placement="bottom">
                                    <img
                                        alt="image2"
                                        src={emoji}
                                        style={{ display: 'block', width: 35, height: 35 }}
                                        onClick={() => {
                                            this.setState({
                                                show_emoji: true,
                                                index_links: index
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            {
                                index === 0 && <Col span={6} style={{ padding: 12 }}>
                                    <Tooltip title="Add rows" placement="bottom">
                                        <PlusCircleOutlined
                                            style={{ fontSize: 30 }}
                                            onClick={() => {
                                                this.onAddLigne()
                                            }}
                                        />
                                    </Tooltip>
                                </Col>
                            }
                            {
                                index !== 0 && <Col span={6} style={{ padding: 10 }}>
                                    <Tooltip title="Remove rows" placement="bottom">
                                        <MinusCircleOutlined
                                            style={{ fontSize: 40 }}
                                            onClick={() => {
                                                this.onRemoveLigne(index)
                                            }}
                                        />
                                    </Tooltip>
                                </Col>
                            }
                        </Row>
                        <br />
                    </Col>
                </Row>
            </div>
        )
    }

    onTextLigneFooterChange(e, index) {
        var old_value = this.state.footer_structure
        old_value[index]['text'] = e
        this.setState({
            footer_structure: old_value
        })
    }

    onAddLigneFooter() {
        var old = this.state.footer_structure
        old.push({ "text": "", "links": [], "style": "font{font-family: Verdana; color: #808080; font-size: 8pt; text-align: justify;}", "bold": [] })
        this.setState({
            footer_structure: old
        })
    }

    onRemoveLigneFooter(index) {
        var old = this.state.footer_structure
        old.splice(index, 1)
        this.setState({
            footer_structure: old
        })
    }

    createFooterForm() {
        return this.state.footer_structure.map((item, index) =>
            <div key={index}>
                <Row>
                    <Col span={3} style={{ textAlign: 'center', padding: 0 }}>
                        <span style={{ textDecoration: 'underline' }}>Text</span>
                    </Col>
                    <Col span={15}>
                        <TextArea
                            style={{ width: '100%', resize: 'none' }}
                            value={item['text']}
                            onChange={(e) => {
                                this.onTextLigneFooterChange(e.target.value, index)
                            }}
                        />
                    </Col>
                    <Col span={6} style={{ textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={8} style={{ padding: 0 }}>
                                <Tooltip title="Variables" placement="bottom">
                                    <Button
                                        style={{ fontSize: 16 }}
                                        className="btn btn-info"
                                        onClick={() => {
                                            this.setState({
                                                show_variables_footer: true,
                                                index_links: index
                                            })
                                        }}
                                    >
                                        <TagOutlined style={{ color: '#ffffff', fontSize: 18, marginTop: '0px', display: "block" }} />
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={8} style={{ padding: 0 }}>
                                <Tooltip title="Add links" placement="bottom">
                                    <Button
                                        className="btn btn-success"
                                        style={{ fontSize: 16 }}
                                        onClick={() => {
                                            this.setState({
                                                show_links_footer: true,
                                                index_links: index
                                            })
                                        }}
                                    >
                                        <LinkOutlined style={{ color: '#ffffff', fontSize: 18, marginTop: '0px', display: "block" }} />
                                    </Button>
                                </Tooltip>
                            </Col>

                            <Col span={8} style={{ padding: 0 }}>
                                <Tooltip title="bold font" placement="bottom">
                                    <Button
                                        className="btn btn-secondary"
                                        style={{ fontSize: 16 }}
                                        onClick={() => {
                                            this.setState({
                                                show_bold_footer: true,
                                                index_links: index
                                            })
                                        }}
                                    >
                                        <BoldOutlined style={{ color: '#ffffff', fontSize: 18, marginTop: '0px', display: "block" }} />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={3} style={{ textAlign: 'center', padding: 0 }}>
                        <span style={{ textDecoration: 'underline' }}>Style</span>
                    </Col>
                    <Col span={15}>
                        <Editor
                            height="8vh"
                            defaultLanguage="css"
                            theme="vs-dark"
                            value={item['style']}
                            style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                            onChange={(value) => {
                                this.onCSSchanged(value, index)
                            }}
                        />

                    </Col>
                    <Col span={6} style={{ textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={6} style={{ padding: 10 }}>
                                <Tooltip title="Color palette" placement="bottom">
                                    <img
                                        alt="image3"
                                        src={palette}
                                        style={{ display: 'block', width: 35, height: 35 }}
                                        onClick={() => {
                                            this.setState({
                                                show_palette_footer: true,
                                                index_links: index
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <Tooltip title="Select a font" placement="bottom">
                                    <img
                                        alt="image4"
                                        src={font}
                                        style={{ display: 'block', width: 35, height: 35 }}
                                        onClick={() => {
                                            this.setState({
                                                show_font_footer: true,
                                                index_links: index
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <Tooltip title="Add emoji icon" placement="bottom">
                                    <img
                                        alt="image5"
                                        src={emoji}
                                        style={{ display: 'block', width: 35, height: 35 }}
                                        onClick={() => {
                                            this.setState({
                                                show_emoji_footer: true,
                                                index_links: index
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            {
                                index === 0 && <Col span={6} style={{ padding: 12 }}>
                                    <Tooltip title="Add rows" placement="bottom">
                                        <PlusCircleOutlined
                                            style={{ fontSize: 30 }}
                                            onClick={() => {
                                                this.onAddLigneFooter()
                                            }}
                                        />
                                    </Tooltip>
                                </Col>
                            }
                            {
                                index !== 0 && <Col span={6} style={{ padding: 10 }}>
                                    <Tooltip title="Remove rows" placement="bottom">
                                        <MinusCircleOutlined
                                            style={{ fontSize: 40 }}
                                            onClick={() => {
                                                this.onRemoveLigneFooter(index)
                                            }}
                                        />
                                    </Tooltip>
                                </Col>
                            }
                        </Row>
                        <br />
                    </Col>
                </Row>
            </div>
        )
    }

    onGenerateTemplate() {
        this.setState({ loading_generate: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('type', 'header')
        fd.append('code', JSON.stringify(this.state.header_structure))
        fd.append('name', this.state.header_name)
        fd.append('status', 'generate')
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_GENERATE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                this.setState({
                    header_code: base64_decode(res.data['code']),
                    loading_generate: false
                })
            })
    }

    onSaveTemplate() {
        this.setState({ loading_save_template: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('type', 'header')
        fd.append('code', JSON.stringify(this.state.header_structure))
        fd.append('name', this.state.header_name)
        fd.append('status', 'save')
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_GENERATE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })

    }

    onGenerateTemplateFooter() {
        this.setState({ loading_generate: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('type', 'footer')
        fd.append('code', JSON.stringify(this.state.footer_structure))
        fd.append('name', this.state.footer_name)
        fd.append('status', 'generate')
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_GENERATE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                this.setState({
                    footer_code: base64_decode(res.data['code']),
                    loading_generate: false
                })
            })
    }

    onSaveTemplateFooter() {
        this.setState({ loading_save_template: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('type', 'footer')
        fd.append('code', JSON.stringify(this.state.footer_structure))
        fd.append('name', this.state.footer_name)
        fd.append('status', 'save')
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_GENERATE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                })
            })
    }

    onFormatHtml() {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('html_format', true)
        fd.append('html', base64_encode(this.state.header_code))
        axios.post(process.env.REACT_APP_ENDPOINT_HTML_FORMATTER, fd)
            .then(res => {
                var code = (base64_decode(res.data['code'])).replaceAll('<html>', '')
                code = code.replaceAll('<body>', '')
                code = code.replaceAll('</body>', '')
                code = code.replaceAll('</html>', '')
                code = code.trim()
                this.setState({
                    header_code: code
                })
            })
    }

    onFormatHtmlFooter() {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('html_format', true)
        fd.append('html', base64_encode(this.state.footer_code))
        axios.post(process.env.REACT_APP_ENDPOINT_HTML_FORMATTER, fd)
            .then(res => {
                var code = (base64_decode(res.data['code'])).replaceAll('<html>', '')
                code = code.replaceAll('<body>', '')
                code = code.replaceAll('</body>', '')
                code = code.replaceAll('</html>', '')
                code = code.trim()
                this.setState({
                    footer_code: code
                })
            })
    }

    onUpdateHeader() {
        this.setState({ loadingUpdate: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('html', base64_encode(this.state.header_code))
        fd.append('template', 'header')
        fd.append('name', this.state.header_name)
        fd.append('id', this.state.header_id)
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_HEADER_UPDATE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onUpdateFooter() {
        this.setState({ loadingUpdateFooter: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('html', base64_encode(this.state.footer_code))
        fd.append('template', 'footer')
        fd.append('name', this.state.footer_name)
        fd.append('id', this.state.footer_id)
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_HEADER_UPDATE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onRequestClosRemoveHeader() {
        this.setState({
            show_remove_header: false
        })
    }

    onRequestClosRemoveFooter() {
        this.setState({
            show_remove_footer: false
        })
    }

    onRequestDeleteHeader() {
        this.setState({ loadingDeleteHeader: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('template', 'header')
        fd.append('id', this.state.header_id)
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_DELETE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onRequestDeleteFooter() {
        this.setState({ loadingDeleteFooter: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('template', 'footer')
        fd.append('id', this.state.footer_id)
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_DELETE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onRequestClosCloneHeader() {
        this.setState({
            show_clone_header: false,
        })
    }

    onRequestClosCloneFooter() {
        this.setState({
            show_clone_footer: false,
        })
    }

    onRequesCloneHeader() {
        this.setState({ loadingCloneHeader: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('template', 'header')
        fd.append('id', this.state.header_id)
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_CLONE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onRequesCloneFooter() {
        this.setState({ loadingCloneFooter: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('template', 'footer')
        fd.append('id', this.state.footer_id)
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_CLONE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onRequestClosAssignHeader() {
        this.setState({
            show_assign_header: false,
            base_id: [],
            header_id: null
        })
    }

    onRequestClosAssignFooter() {
        this.setState({
            show_assign_footer: false,
            base_id: [],
            footer_id: null
        })
        return null
    }

    getExportedSegments() {
        Promise.resolve(getExportedSegment(this.state.user_id, this.state.apikey)).then(res => {
            console.log(res)
            this.setState({ liste_segment_exported: res })
        })
    }

    getListSegments(value) {
        Promise.resolve(list_segment(this.state.user_id, this.state.apikey, this.state.stats_apikey, value)).then(res => {
            this.setState({ liste_segment: res })
        })
    }

    onBaseChanged(value) {
        if (value.includes('all')) {
            var data = []
            this.state.liste_base.map(item => {
                data.push(item['id'])
                return null
            })
            this.setState({
                base_id: data
            })
        } else {
            if (value.includes('none')) {
                this.setState({
                    base_id: []
                })
            } else {
                this.setState({
                    base_id: value
                })
            }

        }
        return null
    }

    onBaseSegmentsChanged(value) {
        this.setState({ base_id: value })
        this.getListSegments(value)
    }

    onRequesAssignHeader() {
        this.setState({ loadingAssign: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('template', 'header')
        fd.append('id', this.state.header_id)
        fd.append('base', JSON.stringify(this.state.base_id))
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_ASSIGN, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onRequesAssignFooter() {
        this.setState({ loadingAssignFooter: true })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('template', 'footer')
        fd.append('id', this.state.footer_id)
        fd.append('base', JSON.stringify(this.state.base_id))
        axios.post(process.env.REACT_APP_ENDPOINT_TEMPLATE_KONTICREA_ASSIGN, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('header_konticrea'))
                localStorage.removeItem(base64_encode('footer_konticrea'))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    onCreateNewSeedLists() {
        if (this.state.seed_name === null || this.state.seed_email_lists === null || this.state.base_id === null || this.state.base_id === []) {
            message.warning('Mandatory field missing !!!', 2)
            return null
        } else {
            this.setState({ loadingcreateSeed: true })
            var data = {
                "username": this.state.username,
                "base_id": this.state.base_id,
                "seed_name": this.state.seed_name,
                "emails": this.state.seed_email_lists
            }
            Promise.resolve(createSeedlists(this.state.user_id, this.state.apikey, data)).then(res => {
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            })
        }
    }

    onCreateNewSubscriberLists() {
        if (this.state.seed_name === null || this.state.seed_email_lists === null || this.state.base_id === null || this.state.base_id === []) {
            message.warning('Mandatory field missing !!!', 2)
            return null
        } else {
            this.setState({ loadingcreateSeed: true })
            var data = {
                "username": this.state.username,
                "base_id": this.state.base_id,
                "seed_name": this.state.seed_name,
                "emails": this.state.seed_email_lists,
                "encryption": this.state.sub_encryption
            }
            Promise.resolve(createSubscriberlists(this.state.user_id, this.state.apikey, data)).then(res => {
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            })
        }
    }

    onAddToExistingSeedLists() {
        if (this.state.seed_name === null || this.state.seed_email_lists === null || this.state.base_id === null || this.state.base_id === []) {
            message.warning('Mandatory field missing !!!', 2)
            return null
        } else {
            this.setState({ loadingaddtoexisting: true })
            var data = {
                "username": this.state.username,
                "base_id": this.state.base_id,
                "seed_name": this.state.seed_name,
                "emails": this.state.seed_email_lists,
                "seed_id": this.state.seed_listid,
                "emails": this.state.seed_email_lists
            }
            Promise.resolve(addToSeedlists(this.state.user_id, this.state.apikey, data)).then(res => {
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            })
        }
    }

    onAddToExistingSubscriberLists() {
        if (this.state.seed_name === null || this.state.seed_email_lists === null || this.state.base_id === null || this.state.base_id === []) {
            message.warning('Mandatory field missing !!!', 2)
            return null
        } else {
            this.setState({ loadingaddtoexisting: true })
            var data = {
                "username": this.state.username,
                "base_id": this.state.base_id,
                "seed_name": this.state.seed_name,
                "seed_id": this.state.seed_listid,
                "emails": this.state.seed_email_lists,
                "encryption": this.state.sub_encryption
            }
            Promise.resolve(addToSubscriberlists(this.state.user_id, this.state.apikey, data)).then(res => {
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            })
        }
    }

    onExportsSegment() {
        if (this.state.base_id === null || this.state.segments_id === null) {
            message.warning("Missing mandatory field !!!!", 2)
            return null
        } else {
            this.setState({ loadingExportSegments: true })
            var data = {
                "encryption": this.state.sub_encryption,
                "base_id": this.state.base_id,
                "segment_id": this.state.segments_id,
                "username": this.state.username
            }
            Promise.resolve(export_segment(this.state.user_id, this.state.apikey, data)).then(res => {
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            })
        }
    }

    renderDownloadLinks(item) {
        if (item['status'] === 'Processing') {
            return (
                <Tag>Processing</Tag>
            )
        } else {
            return (
                <a href={item['links']} target='_blank'>
                    <Tag color="cyan">{item['links']}</Tag>
                </a>
            )
        }
    }

    changeTab(activekey) {

    }

    onDatabaseChanged(e) {
        if ((e.target.value).length > 0) {
            let result = []
            this.state.liste_seed_created.map((seed) => {
                if (seed.url.indexOf(e.target.value) > -1) {
                    result.push(seed)
                }
                return null
            })
            this.setState({ liste_seed_created_filtered: result })
        } else {
            var all = this.state.liste_seed_created
            this.setState({
                liste_seed_created_filtered: all
            })
        }
    }

    render() {
        const bg = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, .5)",
            }
        }
        return (
            <div>
                <Tabs
                    tabPosition="top"
                    style={{ background: '#fff', height: '100%', paddingLeft: 20, width: '98%' }}
                    defaultActiveKey={this.state.defaultactive}
                    activeKey={this.state.defaultactive}
                    onChange={(value) => {
                        localStorage.setItem('defaultDeliveryTab', value)
                        this.setState({ defaultactive: value })
                    }}
                >

                    {
                        this.state.isAdmin === 'block' && <TabPane

                            tab={<div style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>DI</span></div>}
                            key="6"
                        >
                            <ImpactChecker />
                        </TabPane>
                    }

                    <TabPane
                        tab={<div onClick={() => {
                            this.setState({
                                template_type: 'header'
                            })
                        }} style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>Headers</span></div>}
                        key="1"

                    >
                        <div style={{ width: '100%', height: '92vh', overflow: 'scroll', overflowX: 'hidden' }}>
                            {this.state.isAdmin === 'block' && <Row style={{ padding: 8 }}>
                                <Col span={3} style={{ textAlign: 'center', display: `${this.state.isAdmin}` }}>
                                    <Tooltip title="New header" placement="bottom">
                                        <Button
                                            className="btn btn-success"
                                            style={{ fontSize: 16 }}
                                            onClick={() => {
                                                this.setState({
                                                    show_create_header: true
                                                })
                                            }}
                                        >
                                            New Header <strong>+</strong>
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={3} style={{ textAlign: 'left', display: `${this.state.isAdmin}` }}>
                                    <Tooltip title="Assign header to database" placement="bottom">
                                        <Button
                                            className="btn btn-primary"
                                            style={{ fontSize: 16 }}
                                            onClick={() => {
                                                this.setState({
                                                    show_assign_header: true
                                                })
                                            }}
                                        >
                                            ASSIGN HEADER
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            }
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr style={{ textAlign: 'center' }}>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Preview</th>
                                        {this.state.isAdmin === 'block' && <th>Action</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.liste_header.map(item => {
                                            var key = generateKey()
                                            return (
                                                <tr key={key}>
                                                    <td style={{ paddingTop: 55, textAlign: 'center', width: '5%' }}>{item.id}</td>
                                                    <td style={{ paddingTop: 55, textAlign: 'center', width: '15%' }}>{item.name}</td>
                                                    <td style={{ width: '60%' }}>
                                                        <iframe title="." srcDoc={base64_decode(item.code)} style={{ width: '100%' }} />
                                                    </td>
                                                    {this.state.isAdmin === 'block' && <td>
                                                        <Row style={{ paddingTop: 55, textAlign: 'center' }}>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <Tooltip title="edit" placement="bottom">
                                                                    <Button
                                                                        className="btn btn-primary"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                show_header: true,
                                                                                header_code: base64_decode(item['code']),
                                                                                header_id: item['id'],
                                                                                header_name: item['name']
                                                                            })
                                                                        }}
                                                                    >
                                                                        <EditOutlined style={{ color: '#ffffff', fontSize: 15, marginTop: '0px', display: "block" }} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <Tooltip title="Remove" placement="bottom">
                                                                    <Button
                                                                        className="btn btn-danger"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                show_remove_header: true,
                                                                                header_id: item['id']
                                                                            })
                                                                        }}
                                                                    >
                                                                        <DeleteOutlined style={{ color: '#ffffff', fontSize: 15, marginTop: '0px', display: "block" }} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <Tooltip title="Clone" placement="bottom">
                                                                    <Button
                                                                        className="btn btn-secondary"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                show_clone_header: true,
                                                                                header_id: item['id']
                                                                            })
                                                                        }}
                                                                    >
                                                                        <CopyOutlined style={{ color: '#ffffff', fontSize: 15, marginTop: '0px', display: "block" }} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </TabPane>


                    <TabPane
                        tab={<div onClick={() => {
                            this.setState({
                                template_type: 'footer'
                            })
                        }} style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>Footers</span></div>}
                        key="2"

                    >
                        <div style={{ width: '100%', height: '92vh', overflow: 'scroll', overflowX: 'hidden' }}>
                            {this.state.isAdmin === 'block' && <Row style={{ padding: 8 }}>
                                <Col span={3} style={{ textAlign: 'center', display: `${this.state.isAdmin}` }}>
                                    <Tooltip title="New Footer" placement="bottom">
                                        <Button
                                            className="btn btn-success"
                                            style={{ fontSize: 16 }}
                                            onClick={() => {
                                                this.setState({
                                                    show_create_footer: true
                                                })
                                            }}
                                        >
                                            New Footer <strong>+</strong>
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={3} style={{ textAlign: 'left', display: `${this.state.isAdmin}` }}>
                                    <Tooltip title="Assign Footer to database" placement="bottom">
                                        <Button
                                            className="btn btn-primary"
                                            style={{ fontSize: 16 }}
                                            onClick={() => {
                                                this.setState({
                                                    show_assign_footer: true
                                                })
                                            }}
                                        >
                                            ASSIGN FOOTER
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            }
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr style={{ textAlign: 'center' }}>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Preview</th>
                                        {this.state.isAdmin === 'block' && <th>Action</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.liste_footer.map(item => {
                                            var key = generateKey()
                                            return (
                                                <tr key={key}>
                                                    <td style={{ paddingTop: 55, textAlign: 'center', width: '5%' }}>{item.id}</td>
                                                    <td style={{ paddingTop: 55, textAlign: 'center', width: '15%' }}>{item.name}</td>
                                                    <td style={{ width: '60%' }}>
                                                        <iframe title="-" srcDoc={base64_decode(item.code)} style={{ width: '100%' }} />
                                                    </td>
                                                    {this.state.isAdmin === 'block' && <td>
                                                        <Row style={{ paddingTop: 55, textAlign: 'center' }}>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <Tooltip title="edit" placement="bottom">
                                                                    <Button
                                                                        style={{ fontSize: 16 }}
                                                                        className="btn btn-primary"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                show_footer: true,
                                                                                footer_code: base64_decode(item['code']),
                                                                                footer_id: item['id'],
                                                                                footer_name: item['name']
                                                                            })
                                                                        }}
                                                                    >
                                                                        <EditOutlined style={{ color: '#ffffff', fontSize: 15, marginTop: '0px', display: "block" }} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <Tooltip title="Remove" placement="bottom">
                                                                    <Button
                                                                        style={{ fontSize: 16 }}
                                                                        className="btn btn-danger"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                show_remove_footer: true,
                                                                                footer_id: item['id']
                                                                            })
                                                                        }}
                                                                    >
                                                                        <DeleteOutlined style={{ color: '#ffffff', fontSize: 15, marginTop: '0px', display: "block" }} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <Tooltip title="Clone" placement="bottom">
                                                                    <Button
                                                                        style={{ fontSize: 16 }}
                                                                        className="btn btn-secondary"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                show_clone_footer: true,
                                                                                footer_id: item['id']
                                                                            })
                                                                        }}
                                                                    >
                                                                        <CopyOutlined style={{ color: '#ffffff', fontSize: 15, marginTop: '0px', display: "block" }} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Col>
                                                        </Row>
                                                    </td>}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </TabPane>


                    <TabPane
                        tab={<div onClick={() => {
                            this.setState({
                                template_type: 'footer'
                            })
                        }} style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>SEEDLISTS</span></div>}
                        key="3"
                    >
                        <div style={{ width: '100%', height: '92vh'}}>
                            <Row style={{ padding: 8 }}>
                                <Col span={8}>
                                    <Row>
                                        <Col span={6} style={{ padding: 5, textAlign: 'center' }}>
                                            <span>Database</span>
                                        </Col>
                                        <Col span={16}>
                                            <Input
                                                type="text"
                                                size="medium"
                                                id="field_style"
                                                onChange={
                                                    this.onDatabaseChanged.bind(this)
                                                }
                                                style={{ width: '100%', color: '#192a56' }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={3}>
                                    <Button
                                        className='btn btn-success'
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            this.setState({
                                                show_new_seed: true
                                            })
                                        }}
                                    >
                                        NEW SEEDLIST
                                    </Button>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={3}>
                                    <Button
                                        className='btn btn-warning'
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            this.setState({
                                                show_modal_adding_sub: true
                                            })
                                        }}
                                    >
                                        NEW SUBSCRIBER LISTS
                                    </Button>
                                </Col>
                            </Row>
                            <div style={{ width: "100%", height: "85vh", overflow: 'scroll' }}>
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}>Id</th>
                                            <th style={{ textAlign: 'center' }}>Creation date</th>
                                            <th style={{ textAlign: 'center' }}>Type</th>
                                            <th style={{ textAlign: 'center' }}>Database</th>
                                            <th style={{ textAlign: 'center' }}>Name</th>
                                            <th style={{ textAlign: 'center' }}>ListId</th>
                                            <th style={{ textAlign: 'center' }}>User</th>
                                            <th style={{ textAlign: 'center' }}>Status</th>
                                            <th style={{ textAlign: 'center' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.liste_seed_created_filtered && this.state.liste_seed_created_filtered.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <tr key={key}>
                                                        <td style={{ textAlign: 'center' }}>{item['id']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['creation_date']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['type']}</td>

                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                this.state.liste_base && this.state.liste_base.map(elem => {
                                                                    if (parseInt(elem['id']) === parseInt(item['base_id'])) {
                                                                        return (
                                                                            <p>{elem['basename']}</p>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>{item['name']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['listid']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['user']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['action']}</td>
                                                        <td>
                                                            <Row>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Tooltip title="Update" placement="bottom">
                                                                        <Button
                                                                            className='btn btn-outline-success'
                                                                            onClick={() => {
                                                                                if (item['type'] === 'SeedList') {
                                                                                    this.setState({
                                                                                        show_modal_adding: true,
                                                                                        seed_name: item['name'],
                                                                                        base_id: item['base_id'],
                                                                                        seed_username: item['user'],
                                                                                        seed_listid: item['listid'],
                                                                                        seed_type: item['type']
                                                                                    })
                                                                                } else {
                                                                                    this.setState({
                                                                                        show_modal_adding_existing_sub: true,
                                                                                        seed_name: item['name'],
                                                                                        base_id: item['base_id'],
                                                                                        seed_username: item['user'],
                                                                                        seed_listid: item['listid'],
                                                                                        seed_type: item['type']
                                                                                    })
                                                                                }

                                                                            }}
                                                                        >
                                                                            <PlusSquareOutlined style={{ fontSize: 15 }} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </TabPane>
                    {
                        this.state.isAdmin === 'block' &&
                        <TabPane
                            tab={<div style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>SEGMENTS EXPORTS</span></div>}
                            key="4"
                        >
                            <div style={{ width: '100%', height: '92vh', overflow: 'scroll', overflowX: 'hidden' }}>
                                <Row style={{ padding: 8 }}>
                                    <Col span={6}>
                                        <Row>
                                            <Col span={6} style={{ padding: 10, textAlign: 'center' }}>
                                                <span>Database</span>
                                            </Col>
                                            <Col span={16}>
                                                <Select
                                                    size="large"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                                    value={this.state.base_id}
                                                    onChange={this.onBaseSegmentsChanged.bind(this)}
                                                >
                                                    {
                                                        this.state.liste_base !== null && this.state.liste_base.map((item) => {
                                                            var key = generateKey()
                                                            return (
                                                                <Option key={key} value={item.id}>{item.basename}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4}>
                                        <Row>
                                            <Col span={8} style={{ padding: 10, textAlign: 'center' }}>
                                                <span>Encryption</span>
                                            </Col>
                                            <Col span={16}>
                                                <Select
                                                    size="large"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '80%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                                    value={this.state.sub_encryption}
                                                    onChange={(value) => { this.setState({ sub_encryption: value }) }}

                                                >
                                                    <Option value="md5">md5</Option>
                                                    <Option value="sha256">sha256</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row>
                                            <Col span={6} style={{ padding: 10, textAlign: 'center' }}>
                                                <span>Segment</span>
                                            </Col>
                                            <Col span={16}>
                                                <Select
                                                    size="large"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                                    value={this.state.segments_id}
                                                    onChange={(value) => { this.setState({ segments_id: value }) }}
                                                >
                                                    {
                                                        this.state.liste_segment && this.state.liste_segment.map(item => {
                                                            var key = generateKey()
                                                            return (
                                                                <Option key={key} value={item['id']}>{item['id']} - {item['name']}</Option>
                                                            )

                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4}>
                                        <Button
                                            loading={this.state.loadingExportSegments}
                                            className='btn btn-success'
                                            style={{ width: '80%', height: 40 }}
                                            onClick={this.onExportsSegment.bind(this)}
                                        >
                                            EXPORTS
                                        </Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button
                                            loading={this.state.loadingRefreshExport}
                                            className='btn btn-primary'
                                            style={{ width: '80%', height: 40 }}
                                            onClick={() => {
                                                this.setState({ loadingRefreshExport: true })
                                                setTimeout(() => {
                                                    window.location.reload()
                                                }, 2000)
                                            }}
                                        >
                                            REFRESH
                                        </Button>
                                    </Col>
                                </Row>
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}>Id</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>Creation date</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>User</th>
                                            <th style={{ textAlign: 'center', width: '20%' }}>Database</th>
                                            <th style={{ textAlign: 'center', width: '5%' }}>Encoding</th>
                                            <th style={{ textAlign: 'center', width: '8%' }}>Segment ID</th>
                                            <th style={{ textAlign: 'center', width: '8%' }}>Request ID</th>
                                            <th style={{ textAlign: 'center' }}>Download Links</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.liste_segment_exported && this.state.liste_segment_exported.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <tr key={key}>
                                                        <td style={{ textAlign: 'center' }}>{item['id']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['creation_date']}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                this.state.liste_user && this.state.liste_user.map(elem => {
                                                                    var key1 = generateKey()
                                                                    if (parseInt(elem['id']) === parseInt(item['user_id'])) {
                                                                        return (
                                                                            <p key={key1}>{elem['username']}</p>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                this.state.liste_base && this.state.liste_base.map(elem => {
                                                                    if (parseInt(elem['id']) === parseInt(item['base_id'])) {
                                                                        return (
                                                                            <p>{elem['basename']}</p>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>{item['encryption']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['segment_id']}</td>
                                                        <td style={{ textAlign: 'center' }}>{item['id_export']}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                this.renderDownloadLinks(item)
                                                            }
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </TabPane>
                    }
                    {
                        this.state.isAdmin === 'block' &&
                        <TabPane
                            tab={<div style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>TOOLSO</span></div>}
                            key="5"
                        >
                            <Toolso />
                        </TabPane>
                    }
                </Tabs>

                {/* header modal */}
                <Modal
                    id="show_template"
                    isOpen={this.state.show_header}
                    onRequestClose={this.onRequestCloseCode.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>EDIT</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseCode.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <Row style={{ padding: 10 }}>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <span>Name: </span>
                        </Col>
                        <Col span={10}>
                            <Input
                                type="text"
                                size="medium"
                                id="field_style"
                                value={this.state.header_name}
                                defaultValue={this.state.header_name}
                                onChange={
                                    (e) => {
                                        this.setState({
                                            header_name: e.target.value
                                        })
                                    }
                                }
                                style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                            />
                        </Col>
                    </Row>
                    <div style={{ width: '100%', height: '56vh' }}>
                        <Editor
                            height="56vh"
                            defaultLanguage="html"
                            theme="vs-dark"
                            value={this.state.header_code}
                            style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                            onChange={(value) => {
                                this.setState({ header_code: value })
                            }}
                        />
                        <div style={{ margin: '0 auto', width: '100%', textAlign: 'center', padding: 10 }}>
                            <Row>
                                <Col span={8}>
                                    <Button
                                        className="btn btn-secondary"
                                        style={{ padding: 0, width: '80%', height: 40, fontSize: 16 }}
                                        onClick={this.onFormatHtml.bind(this)}
                                    >
                                        FORMAT HTML
                                    </Button>
                                </Col>
                                <Col span={8}>
                                    <CopyToClipboard text={this.state.header_code}>
                                        <Button
                                            className="btn btn-primary"
                                            style={{ padding: 0, width: '80%', height: 40, fontSize: 16 }}
                                            onClick={() => {
                                                message.info('Code copied to clipboard', 1)
                                            }}
                                        >
                                            COPY TO CLIPBOARD
                                        </Button>
                                    </CopyToClipboard>
                                </Col>
                                <Col span={8}>
                                    <Button
                                        loading={this.state.loadingUpdate}
                                        className="btn btn-success"
                                        style={{ padding: 0, width: '80%', height: 40, fontSize: 16 }}
                                        onClick={this.onUpdateHeader.bind(this)}
                                    >
                                        UPDATE
                                    </Button>
                                </Col>


                            </Row>
                        </div>
                    </div>
                </Modal>

                <Modal
                    id="newheader"
                    isOpen={this.state.show_create_header}
                    onRequestClose={this.onRequestCloseCreateHeader.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>CREATE</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseCreateHeader.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', marginLeft: '-20px', height: '40.3vh' }}>
                        <Row>
                            <Col span={14}>
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <h3>HTML STRUCTURE</h3>
                                </div>
                                <Row>
                                    <Col span={3} style={{ textAlign: 'center', padding: 0 }}>
                                        <span style={{ textDecoration: 'underline', fontSize: 14 }}>Name</span>
                                    </Col>
                                    <Col span={15}>
                                        <Input
                                            size="medium"
                                            style={{ width: '100%', resize: 'none' }}
                                            value={this.state.header_name}
                                            onChange={(e) => {
                                                this.setState({
                                                    header_name: e.target.value
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <div>
                                    {
                                        this.createHeaderForm()
                                    }
                                </div>
                                <br />
                                <div style={{ textAlign: 'center', width: '75%' }}>
                                    <Row>
                                        <Col span={12}>
                                            <Button
                                                loading={this.state.loading_generate}
                                                className="btn btn-primary"
                                                style={{ width: '60%', fontSize: 16, height: 45 }}
                                                onClick={this.onGenerateTemplate.bind(this)}
                                            >
                                                GENERATE CODE
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                loading={this.state.loading_save_template}
                                                className="btn btn-success"
                                                style={{ width: '60%', fontSize: 16, height: 45 }}
                                                onClick={this.onSaveTemplate.bind(this)}
                                            >
                                                SAVE TEMPLATE
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={10}>
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <h3>PREVIEW</h3>
                                </div>
                                <ReactSrcDocIframe
                                    className="embed-responsive-item" srcDoc={this.state.header_code}
                                    style={{ width: '100%', height: '45vh', marginTop: '-1px', marginLeft: '0px', overflow: 'scroll' }}
                                />
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <h3>HTML CODE</h3>
                                </div>
                                <Editor
                                    height="28vh"
                                    defaultLanguage="html"
                                    theme="vs-dark"
                                    value={this.state.header_code}
                                    style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                                    onChange={(value) => {
                                        this.setState({ header_code: value })
                                    }}
                                />
                            </Col>
                        </Row>

                    </div>
                </Modal>

                <Modal
                    id="palette"
                    isOpen={this.state.show_palette}
                    onRequestClose={this.onRequestClosePalette.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <SketchPicker
                        style={{ width: 200 }}
                        color={this.state.background}
                        onChangeComplete={this.handleChangeComplete}
                    />
                    <hr />
                    <div>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestClosePalette.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.background}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={() => {
                                            message.success('Color copied to clipboard', 1)
                                        }}
                                    >
                                        COPY
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="font"
                    isOpen={this.state.show_font}
                    onRequestClose={this.onRequestCloseFont.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div>
                        <Row>
                            <Col span={8}>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>Font-family:</h3>
                                </div>
                                <div>
                                    <Radio.Group
                                        buttonStyle="solid"
                                        value={this.state.font_selected}
                                        defaultValue={this.state.font_selected}
                                        style={{ padding: 8 }}
                                        onChange={(e) => {
                                            this.setState({
                                                font_selected: e.target.value
                                            })
                                        }}
                                    >
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Georgia">Georgia</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Palatino Linotype">Palatino Linotype</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Book Antiqua">Book Antiqua</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Times New Roman">Times New Roman</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Arial">Arial</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Helvetica">Helvetica</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Arial Black">Arial Black</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Impact">Impact</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Lucida Sans Unicode">Lucida Sans Unicode</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Tahoma">Tahoma</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Verdana">Verdana</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Courier New">Courier New</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Lucida Console">Lucida Console</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="initial">initial</Radio.Button><br />

                                    </Radio.Group>
                                </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={14}>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>Result:</h3>
                                </div>
                                <ReactSrcDocIframe
                                    className="embed-responsive-item" srcDoc={'<div style="font-family: ' + this.state.font_selected + '"><h1>Font family display result ...</h1><p>Try different fonts by clicking the radiobuttons</p></div>'}
                                    style={{ width: '100%', height: '56vh', marginTop: '-1px', marginLeft: '0px', overflow: 'scroll', border: '1px dashed grey' }}
                                />


                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestCloseFont.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.font_selected}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={() => {
                                            message.success('Font copied to clipboard', 1)
                                            this.setState({
                                                show_font: false
                                            })
                                        }}
                                    >
                                        COPY
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="links"
                    isOpen={this.state.show_links}
                    onRequestClose={this.onRequestCloseLinks.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div>
                        <Row>
                            <Col span={3}>
                                <span>Word:</span>
                            </Col>
                            <Col span={21}>
                                <Input
                                    type="text"
                                    size="medium"
                                    style={{ width: '100%' }}
                                    value={this.state.keyword_links}
                                    onChange={(e) => {
                                        this.setState({
                                            keyword_links: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3}>
                                <span>Url:</span>
                            </Col>
                            <Col span={21}>
                                <Input
                                    type="text"
                                    size="medium"
                                    style={{ width: '100%' }}
                                    value={this.state.keyword_url}
                                    onChange={(e) => {
                                        this.setState({
                                            keyword_url: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3}>
                                <span>Variables:</span>
                            </Col>
                            <Col span={21}>
                                {
                                    this.state.konticrea_params.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Tooltip title={item['description']} placement="bottom">
                                                <Tag
                                                    key={key}
                                                    id="tag"
                                                    color="blue"
                                                    style={{ marginBottom: 0, textAlign: 'center', fontSize: 16, marginLeft: 5 }}
                                                    onClick={(e) => {
                                                        this.setState({ keyword_url: e.target.textContent });
                                                    }}
                                                >
                                                    {item['name']}
                                                </Tag>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestCloseLinks.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.background}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={this.onaddLinks.bind(this)}
                                    >
                                        ADD
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="links"
                    isOpen={this.state.show_bold}
                    onRequestClose={this.onRequestCloseBold.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div>
                        <Row>
                            <Col span={3}>
                                <span>Word:</span>
                            </Col>
                            <Col span={21}>
                                <Input
                                    type="text"
                                    size="medium"
                                    style={{ width: '100%' }}
                                    value={this.state.keyword_bold}
                                    onChange={(e) => {
                                        this.setState({
                                            keyword_bold: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3}>
                                <span>Variables:</span>
                            </Col>
                            <Col span={21}>
                                {
                                    this.state.konticrea_params.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Tooltip title={item['description']} placement="bottom">
                                                <Tag
                                                    key={key}
                                                    id="tag"
                                                    color="blue"
                                                    style={{ marginBottom: 0, textAlign: 'center', fontSize: 16, marginLeft: 5 }}
                                                    onClick={(e) => {
                                                        this.setState({ keyword_bold: e.target.textContent });
                                                    }}
                                                >
                                                    {item['name']}
                                                </Tag>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestCloseBold.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.background}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={this.onaddboldWord.bind(this)}
                                    >
                                        ADD
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="variables"
                    isOpen={this.state.show_variables}
                    onRequestClose={this.onRequestCloseVariable.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ marginTop: '-20px', marginLeft: '-9px', width: 218, textAlign: 'center', margin: '0 auto' }}>
                        {/* <Row>
                            <Col span={21} style={{border: '1px solid red'}}> */}
                        {
                            this.state.konticrea_params.map(item => {
                                var key = generateKey()
                                return (
                                    <Tooltip title={item['description']} placement="bottom">
                                        <Tag
                                            key={key}
                                            id="tag"
                                            color="blue"
                                            style={{ marginBottom: 10, textAlign: 'center', fontSize: 16, marginLeft: 2, width: 190 }}
                                            onClick={(e) => {
                                                this.onAddVariables(e.target.textContent)
                                            }}
                                        >
                                            {item['name']}
                                        </Tag>
                                        {/* <hr/> */}
                                    </Tooltip>
                                )
                            })
                        }
                        {/* </Col>
                        </Row> */}
                    </div>
                </Modal>

                <Modal
                    id="emoji"
                    isOpen={this.state.show_emoji}
                    onRequestClose={this.onRequestCloseEmoji.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ marginTop: '-20px', marginLeft: '-20px', width: '108.4%', textAlign: 'center' }}>
                        <Picker
                            onSelect={this.addEmoji.bind(this)}
                            style={{ position: 'relative', width: '100%', overflow: 'scroll', overflowX: 'hidden', fontSize: 10 }}
                        />
                    </div>
                </Modal>

                <Modal
                    id="remove_template"
                    isOpen={this.state.show_remove_header}
                    onRequestClose={this.onRequestClosRemoveHeader.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>DELETE TEMPLATE</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestClosRemoveHeader.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <h2>Are you sure to delete this header?</h2>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestClosRemoveHeader.bind(this)}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingDeleteHeader}
                                    className="btn btn-danger"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestDeleteHeader.bind(this)}

                                >
                                    DELETE
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="remove_template"
                    isOpen={this.state.show_clone_header}
                    onRequestClose={this.onRequestClosCloneHeader.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>CLONE TEMPLATE</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestClosCloneHeader.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <h2>Are you sure to clone this header?</h2>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestClosRemoveHeader.bind(this)}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingCloneHeader}
                                    className="btn btn-success"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequesCloneHeader.bind(this)}
                                >
                                    CONFIRM
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="assign_template"
                    isOpen={this.state.show_assign_header}
                    onRequestClose={this.onRequestClosAssignHeader.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>ASSIGN DATABASE HEADER</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestClosAssignHeader.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ padding: 15, height: '30vh' }}>
                        <Row>
                            <Col span={6} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Header</span>
                            </Col>
                            <Col span={18}>
                                <Select
                                    size="medium"
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.header_id}
                                    onChange={
                                        (value) => {
                                            this.setState({
                                                header_id: value
                                            })
                                        }
                                    }
                                >
                                    {
                                        this.state.liste_header !== null && this.state.liste_header.map((item) => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Select database</span>
                            </Col>
                            <Col span={18}>
                                <div style={{ overflow: 'scroll', overflowX: 'hidden', height: 180 }}>
                                    <Select
                                        mode="multiple"
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', height: 80, color: '#192a56', fontWeight: 700 }}
                                        defaultValue={this.state.base_id}
                                        value={this.state.base_id}
                                        onChange={this.onBaseChanged.bind(this)}
                                    >
                                        <Option key={'1234'} value={'all'}>------------------------------------------SELECT ALL---------------------------</Option>
                                        <Option key={'12345'} value={'none'}>------------------------------------------REMOVE ALL---------------------------</Option>
                                        {
                                            this.state.liste_base !== null && this.state.liste_base.map((item) => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item.id}>{item.basename}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestClosAssignHeader.bind(this)}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingAssign}
                                    className="btn btn-success"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequesAssignHeader.bind(this)}
                                >
                                    CONFIRM
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                {/* footer modal */}
                <Modal
                    id="show_template"
                    isOpen={this.state.show_footer}
                    onRequestClose={this.onRequestCloseCodeFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>EDIT</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseCodeFooter.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <Row style={{ padding: 10 }}>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <span>Name: </span>
                        </Col>
                        <Col span={10}>
                            <Input
                                type="text"
                                size="medium"
                                id="field_style"
                                value={this.state.footer_name}
                                defaultValue={this.state.footer_name}
                                onChange={
                                    (e) => {
                                        this.setState({
                                            footer_name: e.target.value
                                        })
                                    }
                                }
                                style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                            />
                        </Col>
                    </Row>
                    <div style={{ width: '100%', height: '56vh' }}>
                        <Editor
                            defaultLanguage="html"
                            theme="vs-dark"
                            value={this.state.footer_code}
                            style={{ width: '100%', height: '100%', border: '1px solid gray', marginTop: '0px' }}
                            onChange={(value) => {
                                this.setState({ footer_code: value })
                            }}
                        />
                        <div style={{ margin: '0 auto', width: '100%', textAlign: 'center', padding: 10 }}>
                            <Row>
                                <Col span={8}>
                                    <Button
                                        className="btn btn-secondary"
                                        style={{ padding: 0, width: '80%', height: 40, fontSize: 16 }}
                                        onClick={this.onFormatHtmlFooter.bind(this)}
                                    >
                                        FORMAT HTML
                                    </Button>
                                </Col>
                                <Col span={8}>
                                    <CopyToClipboard text={this.state.footer_code}>
                                        <Button
                                            className="btn btn-primary"
                                            style={{ padding: 0, width: '80%', height: 40, fontSize: 16 }}
                                            onClick={() => {
                                                message.info('Code copied to clipboard', 1)
                                            }}
                                        >
                                            COPY TO CLIPBOARD
                                        </Button>
                                    </CopyToClipboard>
                                </Col>
                                <Col span={8}>
                                    <Button
                                        loading={this.state.loadingUpdateFooter}
                                        className="btn btn-success"
                                        style={{ padding: 0, width: '80%', height: 40, fontSize: 16 }}
                                        onClick={this.onUpdateFooter.bind(this)}
                                    >
                                        UPDATE
                                    </Button>
                                </Col>


                            </Row>
                        </div>
                    </div>
                </Modal>

                <Modal
                    id="newheader"
                    isOpen={this.state.show_create_footer}
                    onRequestClose={this.onRequestCloseCreateFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>CREATE</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseCreateFooter.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '102%', marginLeft: '-20px', height: '40.3vh' }}>
                        <Row>
                            <Col span={14}>
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <h3>HTML STRUCTURE</h3>
                                </div>
                                <Row>
                                    <Col span={3} style={{ textAlign: 'center', padding: 0 }}>
                                        <span style={{ textDecoration: 'underline' }}>Name</span>
                                    </Col>
                                    <Col span={15}>
                                        <Input
                                            size="medium"
                                            style={{ width: '100%', resize: 'none' }}
                                            value={this.state.footer_name}
                                            onChange={(e) => {
                                                this.setState({
                                                    footer_name: e.target.value
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <div>
                                    {
                                        this.createFooterForm()
                                    }
                                </div>
                                <br />
                                {/* <div style={{textAlign: 'center',width: '70%', margin: '0 auto'}}>
                                    <Button
                                        className="btn btn-success"
                                        style={{width: '50%', fontSize: 16}}
                                        onClick={this.onGenerateTemplateFooter.bind(this)}
                                    >
                                        GENERATE CODE
                                    </Button>
                                </div> */}
                                <div style={{ textAlign: 'center', width: '75%' }}>
                                    <Row>
                                        <Col span={12}>
                                            <Button
                                                loading={this.state.loading_generate}
                                                className="btn btn-primary"
                                                style={{ width: '60%', fontSize: 16, height: 45 }}
                                                onClick={this.onGenerateTemplateFooter.bind(this)}
                                            >
                                                GENERATE CODE
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                loading={this.state.loading_save_template}
                                                className="btn btn-success"
                                                style={{ width: '60%', fontSize: 16, height: 45 }}
                                                onClick={this.onSaveTemplateFooter.bind(this)}
                                            >
                                                SAVE TEMPLATE
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={10}>
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <h3>PREVIEW</h3>
                                </div>
                                <ReactSrcDocIframe
                                    className="embed-responsive-item" srcDoc={this.state.footer_code}
                                    style={{ width: '100%', height: '45vh', marginTop: '-1px', marginLeft: '0px', overflow: 'scroll' }}
                                />
                                <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                    <h3>HTML CODE</h3>
                                </div>

                                <Editor
                                    height="28vh"
                                    defaultLanguage="html"
                                    theme="vs-dark"
                                    value={this.state.footer_code}
                                    style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                                    onChange={(value) => {
                                        this.setState({ footer_code: value })
                                    }}
                                />
                            </Col>
                        </Row>

                    </div>
                </Modal>

                <Modal
                    id="remove_template"
                    isOpen={this.state.show_remove_footer}
                    onRequestClose={this.onRequestClosRemoveFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>DELETE TEMPLATE</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestClosRemoveFooter.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <h2>Are you sure to delete this footer?</h2>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestClosRemoveFooter.bind(this)}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingDeleteFooter}
                                    className="btn btn-danger"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestDeleteFooter.bind(this)}

                                >
                                    DELETE
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="remove_template"
                    isOpen={this.state.show_clone_footer}
                    onRequestClose={this.onRequestClosCloneFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>CLONE TEMPLATE</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestClosCloneFooter.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <h2>Are you sure to clone this footer?</h2>
                    </div>
                    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestClosCloneFooter.bind(this)}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingCloneFooter}
                                    className="btn btn-success"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequesCloneFooter.bind(this)}
                                >
                                    CONFIRM
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="assign_template"
                    isOpen={this.state.show_assign_footer}
                    onRequestClose={this.onRequestClosAssignFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', width: '100%', height: 50 }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', height: 50 }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>ASSIGN DATABASE FOOTER</h3>
                        </div>

                        <span
                            style={{ color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer' }} onClick={this.onRequestClosAssignFooter.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ padding: 15, height: '30vh' }}>
                        <Row>
                            <Col span={6} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Footer</span>
                            </Col>
                            <Col span={18}>
                                <Select
                                    size="medium"
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.footer_id}
                                    onChange={
                                        (value) => {
                                            this.setState({
                                                footer_id: value
                                            })
                                        }
                                    }
                                >
                                    {
                                        this.state.liste_footer !== null && this.state.liste_footer.map((item) => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center', padding: 3 }}>
                                <span>Select database</span>
                            </Col>
                            <Col span={18}>
                                <div style={{ overflow: 'scroll', overflowX: 'hidden', height: 180 }}>
                                    <Select
                                        mode="multiple"
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', height: 80, color: '#192a56', fontWeight: 700 }}
                                        defaultValue={this.state.base_id}
                                        value={this.state.base_id}
                                        onChange={this.onBaseChanged.bind(this)}
                                    >
                                        <Option key={'1234'} value={'all'}>------------------------------------------SELECT ALL---------------------------</Option>
                                        <Option key={'12345'} value={'none'}>------------------------------------------REMOVE ALL---------------------------</Option>
                                        {
                                            this.state.liste_base !== null && this.state.liste_base.map((item) => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item.id}>{item.basename}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequestClosAssignFooter.bind(this)}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loadingAssignFooter}
                                    className="btn btn-success"
                                    style={{ width: '85%', height: 50, fontSize: 16 }}
                                    onClick={this.onRequesAssignFooter.bind(this)}
                                >
                                    CONFIRM
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="variables"
                    isOpen={this.state.show_variables_footer}
                    onRequestClose={this.onRequestCloseVariableFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ marginTop: '-20px', marginLeft: '-9px', width: 218, textAlign: 'center', margin: '0 auto' }}>
                        {/* <Row>
                            <Col span={21} style={{border: '1px solid red'}}> */}
                        {
                            this.state.konticrea_params.map(item => {
                                var key = generateKey()
                                return (
                                    <Tooltip title={item['description']} placement="bottom">
                                        <Tag
                                            key={key}
                                            id="tag"
                                            color="blue"
                                            style={{ marginBottom: 10, textAlign: 'center', fontSize: 16, marginLeft: 2, width: 190 }}
                                            onClick={(e) => {
                                                this.onAddVariablesFooter(e.target.textContent)
                                            }}
                                        >
                                            {item['name']}
                                        </Tag>
                                        {/* <hr/> */}
                                    </Tooltip>
                                )
                            })
                        }
                        {/* </Col>
                        </Row> */}
                    </div>
                </Modal>

                <Modal
                    id="links"
                    isOpen={this.state.show_bold_footer}
                    onRequestClose={this.onRequestCloseBoldFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div>
                        <Row>
                            <Col span={3}>
                                <span>Word:</span>
                            </Col>
                            <Col span={21}>
                                <Input
                                    type="text"
                                    size="medium"
                                    style={{ width: '100%' }}
                                    value={this.state.keyword_bold}
                                    onChange={(e) => {
                                        this.setState({
                                            keyword_bold: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3}>
                                <span>Variables:</span>
                            </Col>
                            <Col span={21}>
                                {
                                    this.state.konticrea_params.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Tooltip title={item['description']} placement="bottom">
                                                <Tag
                                                    key={key}
                                                    id="tag"
                                                    color="blue"
                                                    style={{ marginBottom: 0, textAlign: 'center', fontSize: 16, marginLeft: 5 }}
                                                    onClick={(e) => {
                                                        this.setState({ keyword_bold: e.target.textContent });
                                                    }}
                                                >
                                                    {item['name']}
                                                </Tag>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestCloseBoldFooter.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.background}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={this.onaddboldWordFooter.bind(this)}
                                    >
                                        ADD
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="links"
                    isOpen={this.state.show_links_footer}
                    onRequestClose={this.onRequestCloseLinksFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div>
                        <Row>
                            <Col span={3}>
                                <span>Word:</span>
                            </Col>
                            <Col span={21}>
                                <Input
                                    type="text"
                                    size="medium"
                                    style={{ width: '100%' }}
                                    value={this.state.keyword_links}
                                    onChange={(e) => {
                                        this.setState({
                                            keyword_links: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3}>
                                <span>Url:</span>
                            </Col>
                            <Col span={21}>
                                <Input
                                    type="text"
                                    size="medium"
                                    style={{ width: '100%' }}
                                    value={this.state.keyword_url}
                                    onChange={(e) => {
                                        this.setState({
                                            keyword_url: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={3}>
                                <span>Variables:</span>
                            </Col>
                            <Col span={21}>
                                {
                                    this.state.konticrea_params.map(item => {
                                        var key = generateKey()
                                        return (
                                            <Tooltip title={item['description']} placement="bottom">
                                                <Tag
                                                    key={key}
                                                    id="tag"
                                                    color="blue"
                                                    style={{ marginBottom: 0, textAlign: 'center', fontSize: 16, marginLeft: 5 }}
                                                    onClick={(e) => {
                                                        this.setState({ keyword_url: e.target.textContent });
                                                    }}
                                                >
                                                    {item['name']}
                                                </Tag>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestCloseLinksFooter.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.background}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={this.onaddLinksFooter.bind(this)}
                                    >
                                        ADD
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="palette"
                    isOpen={this.state.show_palette_footer}
                    onRequestClose={this.onRequestClosePaletteFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <SketchPicker
                        style={{ width: 200 }}
                        color={this.state.background}
                        onChangeComplete={this.handleChangeComplete}
                    />
                    <hr />
                    <div>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestClosePaletteFooter.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.background}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={() => {
                                            message.success('Color copied to clipboard', 1)
                                        }}
                                    >
                                        COPY
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="font"
                    isOpen={this.state.show_font_footer}
                    onRequestClose={this.onRequestCloseFontFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div>
                        <Row>
                            <Col span={8}>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>Font-family:</h3>
                                </div>
                                <div>
                                    <Radio.Group
                                        buttonStyle="solid"
                                        value={this.state.font_selected}
                                        defaultValue={this.state.font_selected}
                                        style={{ padding: 8 }}
                                        onChange={(e) => {
                                            this.setState({
                                                font_selected: e.target.value
                                            })
                                        }}
                                    >
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Georgia">Georgia</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Palatino Linotype">Palatino Linotype</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Book Antiqua">Book Antiqua</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Times New Roman">Times New Roman</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Arial">Arial</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Helvetica">Helvetica</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Arial Black">Arial Black</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Impact">Impact</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Lucida Sans Unicode">Lucida Sans Unicode</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Tahoma">Tahoma</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Verdana">Verdana</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Courier New">Courier New</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="Lucida Console">Lucida Console</Radio.Button><br />
                                        <Radio.Button style={{ width: 250, textAlign: 'center', marginBottom: 5 }} value="initial">initial</Radio.Button><br />

                                    </Radio.Group>
                                </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={14}>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>Result:</h3>
                                </div>
                                <ReactSrcDocIframe
                                    className="embed-responsive-item" srcDoc={'<div style="font-family: ' + this.state.font_selected + '"><h1>Font family display result ...</h1><p>Try different fonts by clicking the radiobuttons</p></div>'}
                                    style={{ width: '100%', height: '56vh', marginTop: '-1px', marginLeft: '0px', overflow: 'scroll', border: '1px dashed grey' }}
                                />


                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    className="btn btn-secondary"
                                    style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                    onClick={this.onRequestCloseFontFooter.bind(this)}
                                >
                                    Canceled
                                </Button>
                            </Col>

                            <Col span={12} style={{ textAlign: 'center' }}>
                                <CopyToClipboard text={this.state.font_selected}>
                                    <Button
                                        className="btn btn-success"
                                        style={{ width: "80%", margin: '0 auto', fontSize: 16 }}
                                        onClick={() => {
                                            message.success('Font copied to clipboard', 1)
                                            this.setState({
                                                show_font_footer: false
                                            })
                                        }}
                                    >
                                        COPY
                                    </Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="emoji"
                    isOpen={this.state.show_emoji_footer}
                    onRequestClose={this.onRequestCloseEmojiFooter.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Picker
                            onSelect={this.addEmojiFooter.bind(this)}
                            style={{ position: 'relative', width: '100%', overflow: 'scroll', overflowX: 'hidden', fontSize: 10 }}
                        />
                    </div>
                </Modal>

                <Modal
                    id="newseed"
                    isOpen={this.state.show_new_seed}
                    onRequestClose={this.onRequestCloseNewSeed.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>CREATE NEW SEEDLISTS</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseNewSeed.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', padding: 8 }}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 8 }}>
                                        <span>Database</span>
                                    </Col>
                                    <Col span={16}>
                                        <Select
                                            mode='multiple'
                                            size="large"
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                            value={this.state.base_id}
                                            onChange={this.onBaseChanged.bind(this)}
                                        >
                                            {
                                                this.state.liste_base !== null && this.state.liste_base.map((item) => {
                                                    var key = generateKey()
                                                    return (
                                                        <Option key={key} value={item.id}>{item.basename}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                        <span>List Name</span>
                                    </Col>
                                    <Col span={16}>
                                        <Input
                                            size="large"
                                            style={{ width: '100%', margin: '0 auto', fontSize: 16 }}
                                            type="text"
                                            value={this.state.seed_name}
                                            onChange={(e) => { this.setState({ seed_name: e.target.value }) }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", paddingTop: 35 }}>
                                        <span>Email lists</span>
                                    </Col>
                                    <Col span={16}>
                                        <TextArea
                                            style={{ width: '100%', resize: 'none', height: 100 }}
                                            value={this.state.seed_email_lists}
                                            onChange={(e) => {
                                                this.setState({
                                                    seed_email_lists: e.target.value
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16}>
                                        <Alert message="Add the emails separated by comma (,)" type="warning" style={{ width: '100%' }} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={12} style={{ textAlign: 'center' }}>
                                                <Button
                                                    className='btn btn-secondary'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onRequestCloseNewSeed.bind(this)}
                                                >
                                                    CANCEL
                                                </Button>
                                            </Col>
                                            <Col span={12} style={{ textAlign: 'left' }}>
                                                <Button
                                                    loading={this.state.loadingcreateSeed}
                                                    className='btn btn-success'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onCreateNewSeedLists.bind(this)}
                                                >
                                                    CREATE
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}></Col>

                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="addseed"
                    isOpen={this.state.show_modal_adding}
                    onRequestClose={this.onRequestCloseNewSeed.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>ADD TO EXISTING SEEDLIST</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseNewSeed.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', padding: 8 }}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", paddingTop: 35 }}>
                                        <span>Email lists</span>
                                    </Col>
                                    <Col span={16}>
                                        <TextArea
                                            style={{ width: '100%', resize: 'none', height: 100 }}
                                            value={this.state.seed_email_lists}
                                            onChange={(e) => {
                                                this.setState({
                                                    seed_email_lists: e.target.value
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16}>
                                        <Alert message="Add the emails separated by comma (,)" type="warning" style={{ width: '100%' }} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={12} style={{ textAlign: 'center' }}>
                                                <Button
                                                    className='btn btn-secondary'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onRequestCloseNewSeed.bind(this)}
                                                >
                                                    CANCEL
                                                </Button>
                                            </Col>
                                            <Col span={12} style={{ textAlign: 'left' }}>
                                                <Button
                                                    loading={this.state.loadingaddtoexisting}
                                                    className='btn btn-success'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onAddToExistingSeedLists.bind(this)}
                                                >
                                                    CREATE
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}></Col>

                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="newsub"
                    isOpen={this.state.show_modal_adding_sub}
                    onRequestClose={this.onRequestCloseNewSub.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>CREATE NEW SUBSCRIBER LISTS</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseNewSub.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', padding: 8 }}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 8 }}>
                                        <span>Database</span>
                                    </Col>
                                    <Col span={16}>
                                        <Select
                                            size="large"
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                            value={this.state.base_id}
                                            onChange={this.onBaseChanged.bind(this)}
                                        >
                                            {
                                                this.state.liste_base !== null && this.state.liste_base.map((item) => {
                                                    var key = generateKey()
                                                    return (
                                                        <Option key={key} value={item.id}>{item.basename}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                        <span>List Name</span>
                                    </Col>
                                    <Col span={16}>
                                        <Input
                                            size="large"
                                            style={{ width: '100%', margin: '0 auto', fontSize: 16 }}
                                            type="text"
                                            value={this.state.seed_name}
                                            onChange={(e) => { this.setState({ seed_name: e.target.value }) }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                        <span>Encryption</span>
                                    </Col>
                                    <Col span={16}>
                                        <Select
                                            size="large"
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: '40%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                            value={this.state.sub_encryption}
                                            onChange={(value) => { this.setState({ sub_encryption: value }) }}

                                        >
                                            <Option value="md5">md5</Option>
                                            <Option value="sha256">sha256</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", paddingTop: 35 }}>
                                        <span>Email lists</span>
                                    </Col>
                                    <Col span={16}>
                                        <TextArea
                                            style={{ width: '100%', resize: 'none', height: 200 }}
                                            value={this.state.seed_email_lists}
                                            onChange={(e) => {
                                                this.setState({
                                                    seed_email_lists: e.target.value
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16}>
                                        <Alert message="Add the emails separated by comma (,)" type="warning" style={{ width: '100%' }} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={12} style={{ textAlign: 'center' }}>
                                                <Button
                                                    className='btn btn-secondary'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onRequestCloseNewSub.bind(this)}
                                                >
                                                    CANCEL
                                                </Button>
                                            </Col>
                                            <Col span={12} style={{ textAlign: 'left' }}>
                                                <Button
                                                    loading={this.state.loadingcreateSeed}
                                                    className='btn btn-success'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onCreateNewSubscriberLists.bind(this)}
                                                >
                                                    CREATE
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}></Col>

                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="addsub"
                    isOpen={this.state.show_modal_adding_existing_sub}
                    onRequestClose={this.onRequestCloseAddSubscriber.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>ADD TO EXISTING SUBSCRIBER LIST</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={this.onRequestCloseAddSubscriber.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', padding: 8 }}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", paddingTop: 15 }}>
                                        <span>Encryption</span>
                                    </Col>
                                    <Col span={16}>
                                        <Select
                                            size="large"
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: '40%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                            value={this.state.sub_encryption}
                                            onChange={(value) => { this.setState({ sub_encryption: value }) }}

                                        >
                                            <Option value="md5">md5</Option>
                                            <Option value="sha256">sha256</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", paddingTop: 75 }}>
                                        <span>Email lists</span>
                                    </Col>
                                    <Col span={16}>
                                        <TextArea
                                            style={{ width: '100%', resize: 'none', height: 200 }}
                                            value={this.state.seed_email_lists}
                                            onChange={(e) => {
                                                this.setState({
                                                    seed_email_lists: e.target.value
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16}>
                                        <Alert message="Add the emails separated by comma (,)" type="warning" style={{ width: '100%' }} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={6} style={{ textAlign: "center", padding: 5 }}>
                                    </Col>
                                    <Col span={16} style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col span={12} style={{ textAlign: 'center' }}>
                                                <Button
                                                    className='btn btn-secondary'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onRequestCloseAddSubscriber.bind(this)}
                                                >
                                                    CANCEL
                                                </Button>
                                            </Col>
                                            <Col span={12} style={{ textAlign: 'left' }}>
                                                <Button
                                                    loading={this.state.loadingaddtoexisting}
                                                    className='btn btn-success'
                                                    style={{ width: '60%', height: 45 }}
                                                    onClick={this.onAddToExistingSubscriberLists.bind(this)}
                                                >
                                                    CREATE
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}></Col>

                        </Row>
                    </div>
                </Modal>

            </div>
        )
    }
}

export default Templates
